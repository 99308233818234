// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

var isInWebview = (function() {
    if(window.isNativeApp) {
      return window.isNativeApp
    } else {
      return undefined
    }
  });

var postMessage = (function(message) {
  if(window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(message)
  };
});

function run(f) {
  var pt = Curry._1(isInWebview, undefined);
  if (pt !== undefined) {
    return Curry._1(f, pt);
  }
  
}

function Make(S) {
  var onMessage = function (f) {
    var handler = function (ev) {
      if (Caml_obj.caml_equal(ev.data, S.eventName)) {
        return Curry._1(f, undefined);
      }
      
    };
    window.addEventListener("message", handler);
    return function (param) {
      window.removeEventListener("message", handler);
      
    };
  };
  return {
          onMessage: onMessage
        };
}

function onMessage(f) {
  var handler = function (ev) {
    if (Caml_obj.caml_equal(ev.data, "tsaitung-record-focus")) {
      return Curry._1(f, undefined);
    }
    
  };
  window.addEventListener("message", handler);
  return function (param) {
    window.removeEventListener("message", handler);
    
  };
}

var Records = {
  onMessage: onMessage
};

function onMessage$1(f) {
  var handler = function (ev) {
    if (Caml_obj.caml_equal(ev.data, "tsaitung-session-focus")) {
      return Curry._1(f, undefined);
    }
    
  };
  window.addEventListener("message", handler);
  return function (param) {
    window.removeEventListener("message", handler);
    
  };
}

var Session = {
  onMessage: onMessage$1
};

function onMessage$2(f) {
  var handler = function (ev) {
    if (Caml_obj.caml_equal(ev.data, "tsaitung-inbox-new")) {
      return Curry._1(f, undefined);
    }
    
  };
  window.addEventListener("message", handler);
  return function (param) {
    window.removeEventListener("message", handler);
    
  };
}

var Inbox = {
  onMessage: onMessage$2
};

var Events = {
  Make: Make,
  Records: Records,
  Session: Session,
  Inbox: Inbox
};

var type_ = "route";

var type_full = "route-full";

function makeRoutePutMessage(type_Opt, url) {
  var type_$1 = type_Opt !== undefined ? type_Opt : type_;
  return JSON.stringify(Json_encode.object_({
                  hd: [
                    "type",
                    type_$1
                  ],
                  tl: {
                    hd: [
                      "method",
                      "push"
                    ],
                    tl: {
                      hd: [
                        "url",
                        url
                      ],
                      tl: /* [] */0
                    }
                  }
                }));
}

var redirect = (function(url) {
    if(document) {
    document.location = url
    }

    });

function push(path) {
  var match = Curry._1(isInWebview, undefined);
  if (match === undefined) {
    return RescriptReactRouter.push(path);
  }
  var origin = window.location.origin;
  var path$1 = path.startsWith(origin) ? path : (
      path.startsWith("/") ? origin + path : origin + ("/" + path)
    );
  return postMessage(makeRoutePutMessage(undefined, path$1));
}

function pushFull(path) {
  var match = Curry._1(isInWebview, undefined);
  if (match !== undefined) {
    return postMessage(makeRoutePutMessage(type_full, path));
  } else {
    return redirect(path);
  }
}

var Route = {
  type_: type_,
  type_full: type_full,
  makeRoutePutMessage: makeRoutePutMessage,
  redirect: redirect,
  push: push,
  pushFull: pushFull
};

var type_$1 = "webview-close";

function postMessage$1(param) {
  return postMessage(JSON.stringify(Json_encode.object_({
                      hd: [
                        "type",
                        type_$1
                      ],
                      tl: /* [] */0
                    })));
}

var CloseWebView = {
  type_: type_$1,
  postMessage: postMessage$1
};

var type_$2 = "select-store";

function makeMessage(sid) {
  return JSON.stringify(Json_encode.object_({
                  hd: [
                    "type",
                    type_$2
                  ],
                  tl: {
                    hd: [
                      "storeId",
                      sid
                    ],
                    tl: /* [] */0
                  }
                }));
}

function postMessage$2(sid) {
  var match = Curry._1(isInWebview, undefined);
  if (match !== undefined) {
    return postMessage(makeMessage(sid));
  }
  
}

var SelectStore = {
  type_: type_$2,
  makeMessage: makeMessage,
  postMessage: postMessage$2
};

exports.isInWebview = isInWebview;
exports.postMessage = postMessage;
exports.run = run;
exports.Events = Events;
exports.Route = Route;
exports.CloseWebView = CloseWebView;
exports.SelectStore = SelectStore;
/* RescriptReactRouter Not a pure module */
