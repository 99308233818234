// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Set = require("rescript/lib/js/set.js");
var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ID$YourProjectName = require("./ID.bs.js");
var Account_Type$YourProjectName = require("../components/Account/Account_Type.bs.js");

function tFromJs(js) {
  return {
          name: js.name,
          id: js.id,
          conversion: js.conversion,
          is_weighted: js.is_weighted
        };
}

function tToJs(t) {
  return {
          name: t.name,
          id: ID$YourProjectName.fromUnit(t.id),
          conversion: t.conversion,
          is_weighted: t.is_weighted
        };
}

function sepUnitName(unitName) {
  var arr = unitName.match(/^(.*)(\(.*\))/);
  if (arr !== null) {
    return [
            Caml_array.get(arr, 1),
            Caml_array.get(arr, 2)
          ];
  } else {
    return [
            unitName,
            undefined
          ];
  }
}

var Unit = {
  tFromJs: tFromJs,
  tToJs: tToJs,
  sepUnitName: sepUnitName
};

var _map = {"Prime":"特","Standard":"-","Unknown":"?"};

var _revMap = {"特":"Prime","-":"Standard","?":"Unknown"};

function itemGradeToJs(param) {
  return _map[param];
}

function itemGradeFromJs(param) {
  return _revMap[param];
}

var Price = {};

function itemGradeCompare(g1, g2) {
  if (g1 === g2) {
    return 0;
  } else if (g1 === "Standard") {
    if (g2 === "Unknown") {
      return 1;
    } else {
      return -1;
    }
  } else if (g1 === "Prime") {
    return 1;
  } else {
    return -1;
  }
}

function itemGradeFromJs_(a) {
  return Belt_Option.getWithDefault(itemGradeFromJs(a), "Unknown");
}

function itemGradeToJs_(a) {
  if (a === "Standard") {
    return "";
  } else {
    return itemGradeToJs(a);
  }
}

function itemTypeCompare(t1, t2) {
  if (t1 === t2) {
    return 0;
  } else if (t1) {
    return -1;
  } else {
    return 1;
  }
}

function tToJs$1(param) {
  return {
          id: param.id,
          name: param.name,
          photo_url: param.photo_url,
          grade: param.grade,
          available_units: param.available_units,
          price: param.price,
          is_active: param.is_active,
          is_regular: param.is_regular,
          is_visible: param.is_visible,
          min_order: param.min_order,
          min_order_quantity: param.min_order_quantity,
          include_tax: param.include_tax
        };
}

function tFromJs$1(param) {
  return {
          id: param.id,
          name: param.name,
          photo_url: param.photo_url,
          grade: param.grade,
          available_units: param.available_units,
          price: param.price,
          is_active: param.is_active,
          is_regular: param.is_regular,
          is_visible: param.is_visible,
          min_order: param.min_order,
          min_order_quantity: param.min_order_quantity,
          include_tax: param.include_tax
        };
}

var SupplyItem = {
  itemGradeCompare: itemGradeCompare,
  itemGradeFromJs_: itemGradeFromJs_,
  itemGradeToJs_: itemGradeToJs_,
  itemTypeCompare: itemTypeCompare,
  tToJs: tToJs$1,
  tFromJs: tFromJs$1
};

function tToJs$2(param) {
  return {
          id: param.id,
          name: param.name
        };
}

function tFromJs$2(param) {
  return {
          id: param.id,
          name: param.name
        };
}

var Category = {
  tToJs: tToJs$2,
  tFromJs: tFromJs$2
};

function budgetPerUnitToJs(param) {
  return {
          budget: param.budget,
          unit: param.unit
        };
}

function budgetPerUnitFromJs(param) {
  return {
          budget: param.budget,
          unit: param.unit
        };
}

function key(t) {
  return t.item.id + itemGradeToJs(t.item.grade);
}

var StoreItem = {
  budgetPerUnitToJs: budgetPerUnitToJs,
  budgetPerUnitFromJs: budgetPerUnitFromJs,
  key: key
};

var Store = {};

function compare(s1, s2) {
  if (Caml_obj.caml_equal(s1, s2)) {
    return 0;
  }
  var a = $$String.compare(s1.name, s2.name);
  if (a !== 0) {
    return a;
  } else {
    return $$String.compare(s1.id, s2.id);
  }
}

var StoreSet = $$Set.Make({
      compare: compare
    });

function awToJs(param) {
  return {
          kg: param.kg,
          weight: param.weight,
          quantity: param.quantity
        };
}

function awFromJs(param) {
  return {
          kg: param.kg,
          weight: param.weight,
          quantity: param.quantity
        };
}

function t_get(values, field) {
  switch (field) {
    case /* Id */0 :
        return values.id;
    case /* Name */1 :
        return values.name;
    case /* Grade */2 :
        return values.grade;
    case /* Locked */3 :
        return values.locked;
    case /* Regular */4 :
        return values.regular;
    case /* Unit */5 :
        return values.unit;
    case /* Replenish_info */6 :
        return values.replenish_info;
    case /* Quantity */7 :
        return values.quantity;
    case /* Aw */8 :
        return values.aw;
    
  }
}

function t_set(values, field, value) {
  switch (field) {
    case /* Id */0 :
        return {
                id: value,
                name: values.name,
                grade: values.grade,
                locked: values.locked,
                regular: values.regular,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Name */1 :
        return {
                id: values.id,
                name: value,
                grade: values.grade,
                locked: values.locked,
                regular: values.regular,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Grade */2 :
        return {
                id: values.id,
                name: values.name,
                grade: value,
                locked: values.locked,
                regular: values.regular,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Locked */3 :
        return {
                id: values.id,
                name: values.name,
                grade: values.grade,
                locked: value,
                regular: values.regular,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Regular */4 :
        return {
                id: values.id,
                name: values.name,
                grade: values.grade,
                locked: values.locked,
                regular: value,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Unit */5 :
        return {
                id: values.id,
                name: values.name,
                grade: values.grade,
                locked: values.locked,
                regular: values.regular,
                unit: value,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Replenish_info */6 :
        return {
                id: values.id,
                name: values.name,
                grade: values.grade,
                locked: values.locked,
                regular: values.regular,
                unit: values.unit,
                replenish_info: value,
                quantity: values.quantity,
                aw: values.aw
              };
    case /* Quantity */7 :
        return {
                id: values.id,
                name: values.name,
                grade: values.grade,
                locked: values.locked,
                regular: values.regular,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: value,
                aw: values.aw
              };
    case /* Aw */8 :
        return {
                id: values.id,
                name: values.name,
                grade: values.grade,
                locked: values.locked,
                regular: values.regular,
                unit: values.unit,
                replenish_info: values.replenish_info,
                quantity: values.quantity,
                aw: value
              };
    
  }
}

function t_set$1(f, v, o) {
  return t_set(o, f, v);
}

function t_get$1(f, o) {
  return t_get(o, f);
}

function t_update(f, u, o) {
  return t_set(o, f, Curry._1(u, t_get(o, f)));
}

var OrderItem = {
  awToJs: awToJs,
  awFromJs: awFromJs,
  t_set: t_set$1,
  t_get: t_get$1,
  t_update: t_update
};

function priceTotalToFloat(i) {
  return i._0;
}

var _map$1 = {"Additional":"Additional","Normal":"Normal"};

function orderTypeToJs(param) {
  return param;
}

function orderTypeFromJs(param) {
  return _map$1[param];
}

var _map$2 = {"Address":"Address","Arrival_time":"Arrival_time","Return_items":"Return_items"};

function logisticsTypeToJs(param) {
  return param;
}

function logisticsTypeFromJs(param) {
  return _map$2[param];
}

var logistics_default_return_items = [];

var logistics_default = {
  address: undefined,
  arrival_time: undefined,
  return_items: logistics_default_return_items
};

var Info = {};

var Order = {
  priceTotalToFloat: priceTotalToFloat,
  orderTypeToJs: orderTypeToJs,
  orderTypeFromJs: orderTypeFromJs,
  logisticsTypeToJs: logisticsTypeToJs,
  logisticsTypeFromJs: logisticsTypeFromJs,
  logistics_default: logistics_default,
  Info: Info
};

var Account = Account_Type$YourProjectName;

var ResponseFail = {};

exports.Unit = Unit;
exports.itemGradeToJs = itemGradeToJs;
exports.itemGradeFromJs = itemGradeFromJs;
exports.Price = Price;
exports.SupplyItem = SupplyItem;
exports.Category = Category;
exports.StoreItem = StoreItem;
exports.Store = Store;
exports.StoreSet = StoreSet;
exports.OrderItem = OrderItem;
exports.Order = Order;
exports.Account = Account;
exports.ResponseFail = ResponseFail;
/* StoreSet Not a pure module */
