// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ContextProvider$YourProjectName = require("./ContextProvider.bs.js");

var context = React.createContext([
      false,
      (function (param) {
          
        })
    ]);

var include = ContextProvider$YourProjectName.M({
      contextPtr: context
    });

var Provider = include;

exports.context = context;
exports.Provider = Provider;
/* context Not a pure module */
