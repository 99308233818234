// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Core = require("@material-ui/core");
var MaterialUi_Typography = require("rescript-material-ui/src/MaterialUi_Typography.bs.js");
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var UserAgreeTerms$YourProjectName = require("../Login/UserAgreeTerms.bs.js");
var Onboarding__Utils$YourProjectName = require("./Onboarding__Utils.bs.js");
var Custom__CheckBlock$YourProjectName = require("../../utils/Custom/Custom__CheckBlock.bs.js");
var Custom__InputFilled$YourProjectName = require("../../utils/Custom/Custom__InputFilled.bs.js");

function convertValidity(_id, vs) {
  var match = vs.valueMissing;
  if (match) {
    return {
            TAG: /* Left */0,
            _0: "Required"
          };
  } else if (vs.valid) {
    return {
            TAG: /* Right */1,
            _0: ""
          };
  } else {
    return {
            TAG: /* Left */0,
            _0: ""
          };
  }
}

var Rules = {
  convertValidity: convertValidity
};

var InputFilled = Custom__InputFilled$YourProjectName.M(Rules);

function Onboarding__Page2(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var toNext = Props.toNext;
  var match = React.useState(function () {
        return false;
      });
  var setOpenAgreeTerms = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAgreeTerms = match$1[1];
  var agreeTerms = match$1[0];
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Onboarding__Utils$YourProjectName.PageContainer.make, {
                  toNext: toNext,
                  title: "createAccount",
                  children: (function (param) {
                      var toNextWithGuard = param.toNextWithGuard;
                      var supressError = !param.hasSubmit;
                      return React.createElement("form", {
                                  name: "createAccount"
                                }, React.createElement("div", {
                                      className: "loginFills"
                                    }, React.createElement(InputFilled.make, {
                                          label: "storeName",
                                          value: state.storeName,
                                          onChange: (function (v) {
                                              return Curry._1(setState, (function (st) {
                                                            return {
                                                                    account: st.account,
                                                                    password: st.password,
                                                                    confirmedPassword: st.confirmedPassword,
                                                                    storeName: v,
                                                                    contact: st.contact,
                                                                    jobTitle: st.jobTitle
                                                                  };
                                                          }));
                                            }),
                                          id_: "onboarding-store-name",
                                          required: true,
                                          supressError: supressError
                                        }), React.createElement(InputFilled.make, {
                                          label: "contact",
                                          value: state.contact,
                                          onChange: (function (v) {
                                              return Curry._1(setState, (function (st) {
                                                            return {
                                                                    account: st.account,
                                                                    password: st.password,
                                                                    confirmedPassword: st.confirmedPassword,
                                                                    storeName: st.storeName,
                                                                    contact: v,
                                                                    jobTitle: st.jobTitle
                                                                  };
                                                          }));
                                            }),
                                          id_: "onboarding-contact",
                                          required: true,
                                          supressError: supressError
                                        }), React.createElement(InputFilled.make, {
                                          label: "title",
                                          value: state.jobTitle,
                                          onChange: (function (v) {
                                              return Curry._1(setState, (function (st) {
                                                            return {
                                                                    account: st.account,
                                                                    password: st.password,
                                                                    confirmedPassword: st.confirmedPassword,
                                                                    storeName: st.storeName,
                                                                    contact: st.contact,
                                                                    jobTitle: v
                                                                  };
                                                          }));
                                            }),
                                          id_: "onboarding-job-title",
                                          required: true,
                                          supressError: supressError
                                        }), React.createElement(Custom__CheckBlock$YourProjectName.make, {
                                          checklist: {
                                            hd: {
                                              checked: agreeTerms,
                                              id: "agree-terms",
                                              label: React.createElement(Core.Typography, {
                                                    children: null,
                                                    component: MaterialUi_Typography.Component.string("div"),
                                                    variant: "body2"
                                                  }, React.createElement(LangString$YourProjectName.make, {
                                                        strKey: "agree"
                                                      }), React.createElement("span", {
                                                        id: "agreeTerms-open-button",
                                                        onClick: (function (e) {
                                                            e.preventDefault();
                                                            return Curry._1(setOpenAgreeTerms, (function (param) {
                                                                          return true;
                                                                        }));
                                                          })
                                                      }, React.createElement("u", undefined, React.createElement(LangString$YourProjectName.make, {
                                                                strKey: "terms"
                                                              }))))
                                            },
                                            tl: /* [] */0
                                          },
                                          onChecked: (function (param, v) {
                                              return Curry._1(setAgreeTerms, (function (param) {
                                                            return v;
                                                          }));
                                            }),
                                          id_: "check-agreeTerms-open-button"
                                        })), React.createElement("div", {
                                      className: "loginBottom"
                                    }, React.createElement(Core.Button, {
                                          onClick: (function (param) {
                                              return Curry._1(toNextWithGuard, undefined);
                                            }),
                                          children: React.createElement(LangString$YourProjectName.make, {
                                                strKey: "goplaceOrder"
                                              }),
                                          classes: {
                                            root: "nextButton"
                                          },
                                          color: "secondary",
                                          disabled: !agreeTerms,
                                          fullWidth: true,
                                          variant: "contained"
                                        })));
                    })
                }), React.createElement(UserAgreeTerms$YourProjectName.make, {
                  open_: match[0],
                  onClickNext: (function (param) {
                      return Curry._1(setOpenAgreeTerms, (function (param) {
                                    return false;
                                  }));
                    })
                }));
}

var Utils;

var make = Onboarding__Page2;

exports.Utils = Utils;
exports.Rules = Rules;
exports.InputFilled = InputFilled;
exports.make = make;
/* InputFilled Not a pure module */
