// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Util$YourProjectName = require("../Util.bs.js");
var ContextProvider$YourProjectName = require("../../components/Contexts/ContextProvider.bs.js");

var context = React.createContext({
      onValid: (function (param, param$1) {
          
        }),
      hasSubmit: false,
      toNextWithGuard: (function (param) {
          
        })
    });

var include = ContextProvider$YourProjectName.M({
      contextPtr: context
    });

var makeProps = include.makeProps;

var make = include.make;

var Provider = {
  makeProps: makeProps,
  make: make
};

var Context = {
  context: context,
  Provider: Provider
};

function Custom__FormValidateWrapper(Props) {
  var toNext = Props.toNext;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setHasSubmit = match[1];
  var match$1 = React.useState(function () {
        return {};
      });
  var setValidResults = match$1[1];
  var validResults = match$1[0];
  var guardToNext = function (param) {
    Curry._1(setHasSubmit, (function (param) {
            return true;
          }));
    var allPass = Js_dict.values(validResults).every(function (v) {
          return v === true;
        });
    if (allPass) {
      return Curry._1(toNext, undefined);
    }
    
  };
  var onValid = function (_id, v) {
    return Curry._1(setValidResults, (function (dict) {
                  dict[_id] = Util$YourProjectName.Either.isRight(v);
                  return Util$YourProjectName.$$Object.shallow(dict);
                }));
  };
  var value_hasSubmit = match[0];
  var value = {
    onValid: onValid,
    hasSubmit: value_hasSubmit,
    toNextWithGuard: guardToNext
  };
  return React.createElement(make, Curry._3(makeProps, value, Curry._1(children, value), undefined));
}

var make$1 = Custom__FormValidateWrapper;

exports.Context = Context;
exports.make = make$1;
/* context Not a pure module */
