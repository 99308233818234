// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");

var initBasic = {
  account: "",
  password: "",
  confirmedPassword: undefined,
  storeName: "",
  contact: "",
  jobTitle: ""
};

var initCompanyInfo = {
  business_accounting_number: "",
  company_name: "",
  responsible_name: "",
  company_phone_number: /* Phone */{
    _0: ""
  }
};

var initPaymentInfo = {
  payment_type: /* Wire */0,
  agree_payment_term: true,
  special_payment_term: undefined
};

var initFinancialContactInfo = {
  name: "",
  phone_number: /* Phone */{
    _0: ""
  },
  email: /* Email */{
    _0: ""
  },
  line_id: undefined
};

var initElectronicEmail = /* FromFaContact */0;

var initBillingType = {
  electronicEmail: initElectronicEmail,
  paperAddress: undefined
};

var initInvoiceInfo = {
  invoice_type: undefined,
  billing_type: initBillingType,
  invoice_notes: ""
};

var initCanLeavePackage = {
  TAG: /* Can */0,
  _0: {
    leave_package_location: "",
    image: undefined,
    final_arrive_hour: /* O13 */0
  }
};

var initLogistcsInfo_store_address = /* Address */{
  _0: ""
};

var initLogistcsInfo_store_phone = /* Phone */{
  _0: ""
};

var initLogistcsInfo_contact_phone = /* Phone */{
  _0: ""
};

var initLogistcsInfo = {
  store_address: initLogistcsInfo_store_address,
  store_phone: initLogistcsInfo_store_phone,
  area: "",
  city: "",
  contact: "",
  contact_phone: initLogistcsInfo_contact_phone,
  can_leave_package: initCanLeavePackage,
  other_notes: ""
};

var initAccountInfo = {
  companyInfo: initCompanyInfo,
  paymentInfo: initPaymentInfo,
  finacialContactInfo: initFinancialContactInfo,
  invoiceInfo: initInvoiceInfo,
  logisticsInfo: initLogistcsInfo
};

var initState = {
  basicInfo: initBasic,
  accountInfo: undefined
};

function reducer(state, action) {
  var updateAcc = function (f) {
    return {
            TAG: /* Update */0,
            _0: {
              basicInfo: state.basicInfo,
              accountInfo: Rebase.$$Option.some(Curry._1(f, Rebase.$$Option.getOr(initAccountInfo, state.accountInfo)))
            }
          };
  };
  if (typeof action === "number") {
    var match = state.basicInfo;
    var contact = match.contact;
    var storeName = match.storeName;
    return updateAcc(function (acc) {
                var init = acc.companyInfo;
                return {
                        companyInfo: {
                          business_accounting_number: init.business_accounting_number,
                          company_name: storeName,
                          responsible_name: contact,
                          company_phone_number: init.company_phone_number
                        },
                        paymentInfo: acc.paymentInfo,
                        finacialContactInfo: acc.finacialContactInfo,
                        invoiceInfo: acc.invoiceInfo,
                        logisticsInfo: acc.logisticsInfo
                      };
              });
  }
  switch (action.TAG | 0) {
    case /* UpdateCompany */0 :
        var f = action._0;
        return updateAcc(function (acc) {
                    return {
                            companyInfo: Curry._1(f, acc.companyInfo),
                            paymentInfo: acc.paymentInfo,
                            finacialContactInfo: acc.finacialContactInfo,
                            invoiceInfo: acc.invoiceInfo,
                            logisticsInfo: acc.logisticsInfo
                          };
                  });
    case /* UpdatePayment */1 :
        var f$1 = action._0;
        return updateAcc(function (acc) {
                    return {
                            companyInfo: acc.companyInfo,
                            paymentInfo: Curry._1(f$1, acc.paymentInfo),
                            finacialContactInfo: acc.finacialContactInfo,
                            invoiceInfo: acc.invoiceInfo,
                            logisticsInfo: acc.logisticsInfo
                          };
                  });
    case /* UpdateFinacialContact */2 :
        var f$2 = action._0;
        return updateAcc(function (acc) {
                    return {
                            companyInfo: acc.companyInfo,
                            paymentInfo: acc.paymentInfo,
                            finacialContactInfo: Curry._1(f$2, acc.finacialContactInfo),
                            invoiceInfo: acc.invoiceInfo,
                            logisticsInfo: acc.logisticsInfo
                          };
                  });
    case /* UpdateInvoice */3 :
        var f$3 = action._0;
        return updateAcc(function (acc) {
                    return {
                            companyInfo: acc.companyInfo,
                            paymentInfo: acc.paymentInfo,
                            finacialContactInfo: acc.finacialContactInfo,
                            invoiceInfo: Curry._1(f$3, acc.invoiceInfo),
                            logisticsInfo: acc.logisticsInfo
                          };
                  });
    case /* UpdateLogistics */4 :
        var f$4 = action._0;
        return updateAcc(function (acc) {
                    return {
                            companyInfo: acc.companyInfo,
                            paymentInfo: acc.paymentInfo,
                            finacialContactInfo: acc.finacialContactInfo,
                            invoiceInfo: acc.invoiceInfo,
                            logisticsInfo: Curry._1(f$4, acc.logisticsInfo)
                          };
                  });
    case /* UpdateAccountInfo */5 :
        return updateAcc(action._0);
    case /* UpdateBasicInfo */6 :
        return {
                TAG: /* Update */0,
                _0: {
                  basicInfo: action._0,
                  accountInfo: state.accountInfo
                }
              };
    case /* ToNext */7 :
        var f$5 = action._0;
        return {
                TAG: /* SideEffects */2,
                _0: (function (param) {
                    Curry._1(f$5, Rebase.$$Option.getOr(initAccountInfo, param.state.accountInfo));
                    
                  })
              };
    case /* ImportBasicLogistics */8 :
        var bool = action._1;
        var basicInfo = action._0;
        return updateAcc(function (acc) {
                    var match = acc.logisticsInfo;
                    var contact_phone = match.contact_phone;
                    var contact = match.contact;
                    var init = acc.logisticsInfo;
                    return {
                            companyInfo: acc.companyInfo,
                            paymentInfo: acc.paymentInfo,
                            finacialContactInfo: acc.finacialContactInfo,
                            invoiceInfo: acc.invoiceInfo,
                            logisticsInfo: {
                              store_address: init.store_address,
                              store_phone: init.store_phone,
                              area: init.area,
                              city: init.city,
                              contact: bool || contact === "" ? basicInfo.contact : contact,
                              contact_phone: bool || Caml_obj.caml_equal(contact_phone, /* Phone */{
                                    _0: ""
                                  }) ? /* Phone */({
                                    _0: basicInfo.account
                                  }) : contact_phone,
                              can_leave_package: init.can_leave_package,
                              other_notes: init.other_notes
                            }
                          };
                  });
    
  }
}

var initCheckInfo = {
  bank: "",
  check_number: ""
};

var initSpecialPaymentTerm = {
  closing_date: false,
  payment_date: false,
  other_notes: ""
};

var initPaperAddress;

var initLeavePackageDesc = {
  leave_package_location: "",
  image: undefined,
  final_arrive_hour: /* O13 */0
};

var initCannotLeavePackage = {
  TAG: /* Cannot */1,
  _0: {
    TAG: /* O9 */0,
    _0: /* Hour */0
  },
  _1: /* O13 */0
};

exports.initBasic = initBasic;
exports.initCompanyInfo = initCompanyInfo;
exports.initCheckInfo = initCheckInfo;
exports.initSpecialPaymentTerm = initSpecialPaymentTerm;
exports.initPaymentInfo = initPaymentInfo;
exports.initFinancialContactInfo = initFinancialContactInfo;
exports.initElectronicEmail = initElectronicEmail;
exports.initPaperAddress = initPaperAddress;
exports.initBillingType = initBillingType;
exports.initInvoiceInfo = initInvoiceInfo;
exports.initLeavePackageDesc = initLeavePackageDesc;
exports.initCanLeavePackage = initCanLeavePackage;
exports.initCannotLeavePackage = initCannotLeavePackage;
exports.initLogistcsInfo = initLogistcsInfo;
exports.initAccountInfo = initAccountInfo;
exports.initState = initState;
exports.reducer = reducer;
/* No side effect */
