// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Core = require("@material-ui/core");
var MaterialUi_Typography = require("rescript-material-ui/src/MaterialUi_Typography.bs.js");
var Styles = require("@material-ui/core/styles");
var ErrorOutline = require("@material-ui/icons/ErrorOutline").default;
var HighlightOff = require("@material-ui/icons/HighlightOff").default;

function colorErr(theme) {
  return theme.palette.error.main;
}

function colorSec(theme) {
  return theme.palette.secondary.main;
}

function colorPri(theme) {
  return theme.palette.primary.main;
}

var useStyles = Styles.makeStyles(function (theme) {
      return {
              exitIcon: {
                color: "#bbb",
                position: "absolute",
                right: "0px",
                top: "0px"
              },
              alertIcon: {
                fontSize: "3rem"
              },
              content_simple: {
                display: "flex",
                position: "relative",
                alignItems: "center",
                flexDirection: "column"
              },
              container_top: {
                paddingTop: "80px",
                alignItems: "flex-start"
              },
              buttomText: {
                marginBottom: "4px",
                padding: "0px 24px",
                textAlign: "center"
              },
              button_redBg: {
                backgroundColor: theme.palette.error.main,
                borderColor: theme.palette.error.main,
                color: "white"
              },
              button_whiteBg: {
                backgroundColor: "white",
                borderColor: theme.palette.error.main,
                color: theme.palette.error.main
              },
              action: {
                display: "flex",
                justifyContent: "space-around"
              },
              caption: {
                fontWeight: "300"
              },
              ul: {
                display: "flex",
                marginBottom: "0px",
                alignItems: "center",
                flexDirection: "column"
              },
              title: {
                backgroundColor: theme.palette.error.main,
                color: "white",
                display: "flex",
                padding: "8px",
                alignItems: "center",
                justifyContent: "center"
              },
              content: {
                padding: "8px 20px"
              },
              paper: {
                border: "1px solid " + theme.palette.error.main,
                borderRadius: "0px"
              },
              container: {
                alignItems: "flex-start"
              },
              colorPri: {
                color: theme.palette.primary.main
              },
              colorSec: {
                color: theme.palette.secondary.main
              },
              colorErr: {
                color: theme.palette.error.main
              },
              colorBorderPri: {
                borderColor: theme.palette.primary.main
              },
              colorBorderSec: {
                borderColor: theme.palette.secondary.main
              },
              colorBorderErr: {
                borderColor: theme.palette.error.main
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

var Error_Outlined = {};

var Cancel = {};

function Custom_Dialog$Warning(Props) {
  var onClose = Props.onClose;
  var open_ = Props.open_;
  var title = Props.title;
  var content1 = Props.content1;
  var content2 = Props.content2;
  var buttons = Props.buttons;
  var bottom = Props.bottom;
  var styles = Curry._1(useStyles, undefined);
  return React.createElement(Core.Dialog, {
              children: null,
              classes: {
                container: styles.container
              },
              onClose: onClose,
              open: open_
            }, React.createElement(Core.DialogTitle, {
                  children: null,
                  classes: {
                    root: styles.title
                  },
                  disableTypography: true
                }, React.createElement(ErrorOutline, {
                      style: {
                        left: "24px",
                        position: "absolute"
                      },
                      className: ""
                    }), title), React.createElement(Core.DialogContent, {
                  children: null,
                  classes: {
                    root: styles.content
                  }
                }, React.createElement(Core.Typography, {
                      children: Curry._1(content1, styles),
                      component: MaterialUi_Typography.Component.string("div"),
                      variant: "body2"
                    }), Belt_Option.mapWithDefault(content2, null, (function (c2) {
                        return React.createElement(Core.Typography, {
                                    children: Curry._1(c2, styles),
                                    classes: {
                                      root: styles.caption
                                    },
                                    component: MaterialUi_Typography.Component.string("div"),
                                    variant: "caption"
                                  });
                      }))), React.createElement(Core.DialogActions, {
                  children: null,
                  classes: {
                    root: styles.action
                  },
                  disableSpacing: true
                }, Curry._1(buttons[0], styles), Curry._1(buttons[1], styles)), Belt_Option.mapWithDefault(bottom, null, (function (b) {
                    return React.createElement("div", {
                                className: styles.buttomText
                              }, React.createElement(Core.Typography, {
                                    children: Curry._1(b, styles),
                                    classes: {
                                      root: styles.caption
                                    },
                                    variant: "caption"
                                  }));
                  })));
}

var Warning = {
  make: Custom_Dialog$Warning
};

function Custom_Dialog$WarningSimple(Props) {
  var colorOpt = Props.color;
  var onClose = Props.onClose;
  var open_ = Props.open_;
  var title = Props.title;
  var content = Props.content;
  var onExit = Props.onExit;
  var icon = Props.icon;
  var classNameOpt = Props.className;
  var color = colorOpt !== undefined ? colorOpt : /* Error */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var styles = Curry._1(useStyles, undefined);
  var tmp;
  switch (color) {
    case /* Error */0 :
        tmp = styles.colorErr;
        break;
    case /* Primary */1 :
        tmp = styles.colorPri;
        break;
    case /* Secondary */2 :
        tmp = styles.colorSec;
        break;
    
  }
  var colorType = " " + tmp;
  var tmp$1;
  switch (color) {
    case /* Error */0 :
        tmp$1 = styles.colorBorderErr;
        break;
    case /* Primary */1 :
        tmp$1 = styles.colorBorderPri;
        break;
    case /* Secondary */2 :
        tmp$1 = styles.colorBorderSec;
        break;
    
  }
  return React.createElement(Core.Dialog, {
              children: React.createElement(Core.DialogContent, {
                    children: null,
                    classes: {
                      root: styles.content_simple
                    }
                  }, Rebase.$$Option.mapOr((function (i) {
                          return Curry._1(i, styles.alertIcon + (" " + colorType));
                        }), React.createElement(ErrorOutline, {
                            className: styles.alertIcon + (" " + colorType)
                          }), icon), React.createElement(Core.Typography, {
                        children: title,
                        variant: "h6"
                      }), React.createElement(Core.Typography, {
                        children: Curry._1(content, styles),
                        classes: {
                          root: styles.caption
                        },
                        component: MaterialUi_Typography.Component.string("div"),
                        variant: "caption"
                      }), React.createElement(Core.IconButton, {
                        onClick: onExit,
                        children: React.createElement(HighlightOff, {}),
                        classes: {
                          root: styles.exitIcon
                        }
                      })),
              classes: {
                container: styles.container_top + (" " + className),
                paper: styles.paper + (" " + tmp$1)
              },
              onClose: onClose,
              open: open_
            });
}

var WarningSimple = {
  make: Custom_Dialog$WarningSimple
};

function color(theme) {
  return theme.palette.primary.main;
}

var useStyles$1 = Styles.makeStyles(function (theme) {
      return {
              box: {
                color: theme.palette.primary.main,
                padding: "0px"
              },
              content: {
                display: "flex",
                padding: "8px",
                alignItems: "center",
                flexDirection: "column"
              },
              button: {
                border: "unset",
                color: "white",
                marginTop: "8px"
              },
              title: {
                backgroundColor: theme.palette.primary.main,
                color: "white",
                display: "flex",
                marginBottom: "16px",
                padding: "8px 16px",
                alignItems: "center",
                flexDirection: "column"
              },
              paper: {
                margin: "24px"
              }
            };
    });

var StylesWithConfirm = {
  useStyles: useStyles$1
};

function Custom_Dialog$RemindWithConfirm(Props) {
  var open_ = Props.open_;
  var onClose = Props.onClose;
  var onCheck = Props.onCheck;
  var onSubmit = Props.onSubmit;
  var title = Props.title;
  var contents = Props.contents;
  var checkTitle = Props.checkTitle;
  var buttonTitle = Props.buttonTitle;
  var buttonDisabledOpt = Props.buttonDisabled;
  var buttonDisabled = buttonDisabledOpt !== undefined ? buttonDisabledOpt : false;
  var styles = Curry._1(useStyles$1, undefined);
  return React.createElement(Core.Dialog, {
              children: null,
              classes: {
                paper: styles.paper
              },
              onClose: onClose,
              open: open_
            }, React.createElement(Core.DialogTitle, {
                  children: title,
                  classes: {
                    root: styles.title
                  }
                }), React.createElement(Core.DialogContent, {
                  children: contents.map(function (str, i) {
                        if (Caml_obj.caml_equal(str, "")) {
                          return React.createElement("br", {
                                      key: String(i)
                                    });
                        } else {
                          return React.createElement(Core.Typography, {
                                      align: "center",
                                      children: str,
                                      component: MaterialUi_Typography.Component.string("div"),
                                      variant: "body2",
                                      key: String(i)
                                    });
                        }
                      }),
                  classes: {
                    root: styles.content
                  }
                }), React.createElement(Core.DialogActions, {
                  children: null,
                  classes: {
                    root: styles.content
                  }
                }, React.createElement("div", undefined, React.createElement(Core.Checkbox, {
                          classes: {
                            root: styles.box
                          },
                          color: "primary",
                          disableRipple: true,
                          onChange: (function (e) {
                              return Curry._1(onCheck, e.target.checked);
                            })
                        }), React.createElement(Core.Typography, {
                          children: checkTitle,
                          variant: "caption"
                        })), React.createElement(Core.Button, {
                      onClick: onSubmit,
                      children: buttonTitle,
                      classes: {
                        root: styles.button
                      },
                      color: "primary",
                      disabled: buttonDisabled,
                      variant: "contained"
                    })));
}

var RemindWithConfirm = {
  make: Custom_Dialog$RemindWithConfirm
};

exports.colorErr = colorErr;
exports.colorSec = colorSec;
exports.colorPri = colorPri;
exports.Styles = Styles$1;
exports.Error_Outlined = Error_Outlined;
exports.Cancel = Cancel;
exports.Warning = Warning;
exports.WarningSimple = WarningSimple;
exports.color = color;
exports.StylesWithConfirm = StylesWithConfirm;
exports.RemindWithConfirm = RemindWithConfirm;
/* useStyles Not a pure module */
