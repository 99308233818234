import React from "react";

let Terms = () => {
  return [
      <>
        <h4>1. 引言</h4>
        <p> 1.1  歡迎使用菜蟲農食平台 (下稱「本網站」)。使用本網站或開設菜蟲農食帳戶 (下稱「帳戶」) 前請詳細閱讀以下服務條款，以瞭解您對於菜蟲農食股份有限公司、其關係企業及子公司 (個別及統稱為「菜蟲農食」、「我們」或「我們的」) 的法律權利與義務。我們提供的服務（下稱「本服務」）包括 (a) 本網站、(b) 本網站及透過本網站提供使用之菜蟲農食用戶端軟體所提供的服務，以及 (c) 透過本網站或其相關服務所提供的所有資訊、連結網頁、功能、資料、文字、影像、相片、圖形、音樂、聲音、影片、訊息、標籤、內容、程式設計、軟體、應用程式服務 (包括但不限於任何行動應用服務) (下稱「內容」)。本服務的任何新增或增強功能亦應受本服務條款的約束。這些服務條款規範您對於我們菜蟲農食所提供之服務的使用行為。</p>
        <p>1.2  在成為本網站的使用者之前，您必須閱讀並接受本服務條款所包含、及連結至本服務條款之所有條款，且您必須同意隱私權政策（連結至本服務條款）中有關於處理您的個人資料之規定。</p>
        <p>1.3  菜蟲農食保留隨時因應法規要求或符合誠信原則而變更、修改、暫停或中止本網站或服務一部或全部的權利。菜蟲農食可能會以試用版發行某些服務或其功能，這些服務或功能可能無法正確運作或如同最終發行版本般運作。菜蟲農食亦可因應法規要求或符合誠信原則而對某些功能設定限制，或限制您存取部分或全部本網站或服務之權限。</p>
        <p>1.4  菜蟲農食保留拒絕提供您存取本網站或服務之權限或允許您因任何原因開設帳戶之權利。</p>
        <p>如您使用菜蟲農食服務或開設帳戶，表示您不可撤回地接受與同意本協議之條款，包括本協議提及/或本協議超連結所提供的額外條款及政策。 <br/>
        如果您不同意這些條款，請勿使用我們的服務或存取本網站。本服務僅提供與具備工商登記之法人。 如果並非工商登記之公司行號代表人，您必須取得公司或商業許可方可開設帳戶，且該公司或商業必須同意本協議的條款。如果您未滿 18 歲或根據您所屬國家適用之法律規定得同意本協議之法定年齡（下稱「法定年齡」），您必須取得父母或法定監護人的許可方可開設帳戶，且該父母或法定監護人必須同意本協議的條款。如果您不確定是否已達到法定年齡，或不瞭解本條款所述內容，您應先尋求父母或法定監護人的協助及同意，方可開設帳戶。如果您是欲開設帳戶之未成年人的父母或法定監護人，您必須代表該名未成年人接受本協議的條款，並且承擔對本帳戶或公司服務之一切使用的責任，無論該帳戶目前已開設或稍後才會建立。</p>

      <h4> 2. 隱私權</h4>
        <p>
          2.1   菜蟲農食非常重視您的隱私權。為更有效地保護您的權利，我們特別在隱私權政策中詳細說明我們的隱私權作法。請審閱該隱私權政策，以瞭解菜蟲農食如何收集並使用有關您的帳戶及/或您使用服務的資訊。如您使用本服務或在本網站提供資訊，即表示您：<br/>
          (a)	同意讓菜蟲農食根據隱私權政策所述方式收集、使用、揭露及/或處理您的內容和個人資料；<br/>
          (b)	同意並了解使用者資訊的財產上權利由您與菜蟲農食共同擁有；且<br/>
          (c)	未經菜蟲農食事前書面同意，您不得直接或間接向第三人揭露您的使用者資訊或允許任何第三人接觸或使用您的使用者資訊。
        </p>
        <p>2.2   使用本服務而持有其他使用者個人資料之使用者（下稱「接受方」）同意將(i) 遵守所有與該個人資料相關之個人資料保護法規；(ii) 允許被接受方收集個人資料之使用者（下稱「揭露方」）得自接受方之資料庫移除他/她的資料；以及(iii) 允許揭露方檢視其被收集方收集之資料為何。在上述(ii)及(iii)之情形，遵守或依適用法規辦理。</p>
      </>,
      <>
        <h4>3. 有限授權</h4>
          <p>
            3.1  菜蟲農食授予您遵照本服務條款之條款與條件存取及使用本服務的有限且可撤回之權利。本網站中所顯示之所有專屬內容、商標、服務標章、品牌名稱、標誌及其他智慧財產（下稱「智慧財產」），均屬菜蟲農食及本網站標示之第三方所有者 (如適用) 的財產。我們並未直接或間接授予存取本網站的任一方使用或重製任何智慧財產的權利或授權，存取本網站的任一方均不得要求與其相關之任何權利、所有權或權益。使用或存取本服務即表示您同意遵守保護本服務、網站及其內容之著作權、商標、服務標章及所有其他適用的法律。您同意不得複製、散布、再發行、傳輸、公開展示、公開表演、修改、調整、租賃或販售本服務、網站或其內容之任何部分，亦不得據此創作衍生作品。如未事先獲得我們的書面同意，您亦不得用鏡像或框架方式將本網站的任何部分或完整內容放置於任何其他伺服器上或作為任何其他網站的一部分展示。此外，您同意如未事先獲得我們的書面同意（在搜尋網站使用標準的搜尋引擎技術引導網路使用者至本網站時，視為已取得我們的同意），您不會使用任何機器人程式、蜘蛛程式或任何其他自動裝置或手動程序來監視或複製我們的內容。
          </p>
          <p>
            3.2  我們歡迎您從您的網站連結至本網站，但前提是您的網站並未暗示獲得菜蟲農食的任何背書或與其有任何關聯。您瞭解菜蟲農食可隨時因應法規要求或符合誠信原則中止提供部分或全部的本服務。
          </p>
        <h4>4. 軟體</h4>
          <p>
            我們作為服務之一部分提供給您的任何軟體，均須受本服務條款之規範約束。菜蟲農食保留菜蟲農食在此未明確授予的所有權利。任何本服務連結或引用的第三方指令碼或程式碼，均是由擁有該指令碼或程式碼的第三方 (而非菜蟲農食) 授權給您使用。
          </p>
        <h4>5. 帳戶與安全</h4>
          <p>5.1  本服務的部分功能必須註冊帳戶方可使用，註冊帳戶時您需要選取一組獨特的使用者識別碼 (下稱「使用者 ID」) 和密碼，並提供一些個人資訊。如果您選擇的使用者 ID 被菜蟲農食單方認定是令人反感或不適當的，菜蟲農食有權暫停或終止您的帳戶。您可以使用您的帳戶來存取我們已啟用其存取權限或與我們有聯盟或合作關係的其他產品、網站或服務。菜蟲農食未審核任何第三方內容、功能、安全、服務、隱私權政策或這些產品、網站或服務的其他規範。如果您這麼做，則這些產品、網站或服務的服務條款，包括其各自的隱私權政策 (若與我們的服務條款及/或隱私權政策有所差異)，亦適用於您對這些產品、網站或服務之使用。</p>
          <p>5.2  您同意 (a) 對您的密碼保密且只在登入時使用您的使用者 ID 與密碼，(b) 確保在每次網站使用階段結束時登出您的帳戶，(c) 在您的帳戶、使用者 ID 及/或密碼遭受未經授權的使用時立即通知菜蟲農食，以及(d) 確保你的帳號資訊正確且即時。您必須對在您的使用者 ID 和帳戶下所發生的所有活動負完全的責任，即使您並非該活動或使用的執行者。對於任何因密碼遭到非授權使用而產生的損失或損害，依第29.15條之規定負擔。</p>
          <p>5.3  您同意菜蟲農食可因應法規要求或符合誠信原則另行通知您立即終止您的帳戶和您的使用者ID，並將與您的帳戶和使用者ID有關的任何內容自本網站上移除，撤銷任何對您的補助，取消與您的帳戶和您的使用者ID有關的交易，暫停任何銷售流程或退款，及/或其他任何菜蟲農食認為必要的措施。菜蟲農食亦將向您說明採取這類措施的理由，包括但不限於菜蟲農食認定存在或合理懷疑有下列任何情形之一：(a)長時間不活動（指您未登入您的帳戶）；(b)違反本服務條款的約定；(c)有相當事證足認使用者有詐欺、騷擾、誹謗、威脅、侮辱或其他非法行為；(d)擁有多個使用者帳戶；(e)支付款項經法院裁定或檢察官命令扣押；(f)使用者提交虛偽之身分認證資料，經查證屬實；(g)為了商業轉售目的而於本網站購買商品；(h)異常或過量地向同一賣家或有關聯的賣家採購商品；(i)濫用折扣碼(包括但不限於出售折扣碼予第三人、異常或過量地於本網站使用折扣碼)；或(j)有害於其他使用者、第三方或菜蟲農食商業利益的行為(例如虛偽買賣、濫用免運或折扣等)。若您將帳戶用於詐欺、騷擾、誹謗、威脅、侮辱或其他非法之用途，我們可在未通知您的情況下向執法機關呈報。</p>
          <p>5.4  消費者得依雙方約定之方式隨時終止契約。如果使用者想要終止其帳戶，可以書面方式 (電子郵件請寄至：hello@tsaitung.com)  通知菜蟲農食。即使帳戶已終止，使用仍應對任何未完成的交易 (無論是在終止之前或之後展開的交易)、產品運送、支付產品款項或類似事宜負責，且使用者必須在其根據本服務條款迅速且有效地履行及完成所有未完成交易後，聯絡菜蟲農食。對於根據本條款而採取之行動所產生的任何損害，菜蟲農食概不負責。使用者放棄任何及所有對菜蟲農食所採取之任何這類行動的索賠。</p>
          <p>5.5  您得使用本服務及/或開設帳戶，如您位於我們目前許可的國家之一。</p>
      </>,
      <>
        <h4>6. 使用條款</h4>
          <p>6.1  本網站和服務的使用授權在終止前皆有效。如發生本服務條款所載之終止事由或是您未遵循本服務條款之任何條款或條件的情況時，此授權將會終止。在這些情況下，菜蟲農食可另行通知您逕行終止授權。</p>
          <p>6.2  您同意不會：
            (a)	上傳、張貼、傳送或以任何其他形式提供任何違法、傷害、威脅、侮辱、騷擾、危言聳聽、令人痛苦、扭曲、誹謗、粗俗、淫穢、詆毀、侵犯他人隱私、憎恨、種族歧視或在道德上或其他方面令人反感的內容；
            (b)	違反任何法律（包括但不限於進出口限制相關法規）、第三方權利或我們的「禁止和限制商品政策」；
            (c)    上傳、張貼、傳送或以任何其他形式提供未成年人獨處或由其主導的內容，或以任何方式使用本服務傷害未成年人；
            (d)	使用本服務冒充任何人或實體，或虛報您與任何人或實體的關係；
            (e)	偽造標題或操弄識別方式，以混淆透過本服務傳送之任何內容的來源；
            (f)	從本網站上移除任何所有權標示；
            (g)	在未經菜蟲農食明確許可的情況下，致使、准許或授權修改、創作衍生著作或翻譯本服務；
            (h)	為任何第三方之利益或非本條款授予許可的任何方式使用本服務；
            (i)	將本服務使用於詐欺用途；
            (j)	註冊及/或使用多個使用者帳戶，與任何違反本服務條款文義或規範精神之活動有關；
            (k)	以模擬器、仿真器、機器人或其他類似硬體或軟體使用菜蟲農食平台、註冊使用者帳戶或登入使用者帳戶；
            (l)	操控任何商品價格或干擾其他使用者的刊登行為；
            (m)	採取任何會破壞反饋或排名系統的行動；
            (n)	試圖解碼、反向工程、拆解或駭入本服務 (或其任何部分)，或試圖破解菜蟲農食針對本服務及/或菜蟲農食所傳輸、處理或儲存之資料所採取的任何加密技術或安全措施；
            (o)	採集或收集有關其他帳戶所有人的任何資訊，包括但不限於任何個人資料或資訊；
            (p)	上傳、以電子郵件寄送、張貼、傳送或以其他形式提供任何您依據任何法律或契約或信任關係(例如由於僱傭關係和依據保密合約所得知或披露之內部資訊、專屬及機密資料) 無權提供之內容；
            (q)	上傳、以電子郵件寄送、張貼、傳送或以任何其他形式提供侵害任一方之任何專利、商標、營業祕密、著作權或其他專屬權利的任何內容；
            (r)	上傳、以電子郵件寄送、張貼、傳送或以其他形式提供任何未經請求或未經授權的廣告、促銷資料、垃圾郵件、廣告信件、連鎖信、金字塔騙局或其他任何未經授權形式的推銷內容；
            (s)	上傳、以電子郵件寄送、張貼、傳送或以其他形式提供任何含有意圖直接或間接干擾、操縱、中斷、破壞或限制任何電腦軟體、硬體、資料或通訊設備功能或完整性之電腦病毒、蠕蟲、木馬或任何其他電腦代碼、常式、檔案或程式的資料；
            (t)	破壞正常的對話流程、造成螢幕快速捲動致使本服務其他使用者無法打字，或對其他使用者參加即時交流的能力造成負面影響；
            (u)	干擾、操縱或破壞本服務或與本服務連線之伺服器或網路或任何其他使用者對本服務之使用或享受，或不遵守本網站連線網路之任何規定、程序、政策或規範；
            (v)	採取或參與任何可能直接或間接造成本服務或與本服務連線之伺服器或網路損害、癱瘓、負載過重或效能降低的行動或行為；
            (w)	使用本服務以蓄意或非蓄意地違反任何適用的當地、州、國家或國際法律、規定、守則、指令、準則、政策或規範，包括但不限於任何與反洗錢或反恐怖主義相關的法律和規定 (無論是否具有法律效力)；
            (x)	以違反或規避由美國財政部外國資產管制辦公室、聯合國安全理事會、歐盟或其財政機關所主管或執行之處罰或禁運的方式使用本服務；
            (y)	使用本服務侵犯他人隱私、跟蹤或以其他方式騷擾他人；
            (z)	侵犯菜蟲農食的權利，包括智慧財產權和相關仿冒行為；
            (aa)  以前述禁止之行為和活動使用本服務以收集或儲存其他使用者之個人資料；及/或
            (bb)  刊登侵犯第三方著作權、商標或其他智慧財產權的項目，或以侵害他人智慧財產權的方式來使用本服務。
          </p>
          <p>
            6.3  您瞭解所有內容，無論其為公開張貼或私下傳送，均為該內容提供者之責任。亦即，您應對您透過本網站上傳、張貼、以電子郵件寄送、傳送或以其他方式提供之所有內容(包括但不限於內容之任何錯誤或遺漏)負完全責任，而非菜蟲農食。您瞭解使用本網站時，您可能會接觸到具攻擊性、不雅或令人不悅之內容。
          </p>
          <p>6.4  您理解菜蟲農食和其受託人有權 (但無義務)因應法規要求或符合誠信原則預先篩選、拒絕、刪除、移除或移動任何透過本網站提供的內容（包括但不限於您透過本網站所提供之內容或資訊），例如菜蟲農食和其受託人有權移除任何 (i) 違反本服務條款的內容；(ii) 遭其他使用者投訴的內容；(iii) 我們收到其侵害智慧財產權之通知或其他要求移除的法律指示之內容；或 (iv) 會造成他人反感的內容。此外，我們亦可能為了保護本服務或我們的使用者，或為了實行本條款與條件之規定，而封鎖進出本服務的通訊傳遞 (包括但不限於狀態更新、貼文、訊息及/或聊天室)。您同意您了解且已評估使用任何內容的所有相關風險，包括但不限於對於這類內容之正確性、完整度或實用性的倚賴
          </p>
          <p>
            6.5  您承認、允許並同意，若依法律要求，或根據法院命令或對菜蟲農食有管轄權之任何政府或監督主管機關之處分，或基於善意及誠信原則認有合理之必要性時，菜蟲農食得存取、保存及揭露您的帳戶資訊和內容，以：(a) 遵守法律程序；(b) 執行本服務條款；(c) 回應任何侵害第三方權利的內容之申訴；(d) 回應您的客戶服務請求；或者 (e) 保護菜蟲農食、其使用者及公眾的權利、財產或人身安全。
          </p>
        <h4>7.   違反我們的服務條款</h4>
          <p>7.1  違反此政策可能導致一系列處分動作，包括但不限於下列任何或全部項目：<br/>
            -     	刪除刊登商品<br/>
            -     	限制帳戶權限<br/>
            -     	中止帳戶及隨後終止<br/>
            -     	刑事訴訟<br/>
            -     	民事求償，包括但不限於請求損害賠償及/或聲請保全處分<br/>
          </p>
          <p>7.2  如果您發現本網站的任何使用者違反本服務條款，請聯絡 hello@tsaitung.com 。</p>
        <h4>8.   侵害智慧財產權之通知</h4>
          <p>8.1  使用者為獨立的個體或事業，與菜蟲農食無任何關聯。菜蟲農食非使用者的代理人或代表人，亦不持有或擁有任何本網站上刊登的商品。</p>
          <p>8.2  如果您為特定智慧財產權的所有人（下稱「IPR所有人」）或取得IPR所有人合法授權的代理人（下稱「IPR代理人」），且您認為您或您的委託人的作品 (在本服務中) 遭到複製、展示或散布之情形已構成侵害智慧財產權已遭受侵害之行為，請以電子郵件通知我們：hello@tsaitung.com，另請提供下列所需資料以支持您的主張。請給予我們時間處理資料，菜蟲農食將於可行範圍內盡速回覆您的通知。</p>
          <p>8.3 依本條所為的侵權通知必須以菜蟲農食指定的格式（菜蟲農食得隨時更新）提供，並確實附上至少下列所有資料：(a)IPR所有人或IPR代理人代理人（下合稱「通知者」）的實體或電子簽名；(b) 主張遭到侵害之智慧財產權的種類和本質的描述，以及該權利的證明；(c) 包含所指侵權內容的具體刊登商品；（d) 可讓菜蟲農食聯絡到通知者的資訊，例如通知者的實際地址、電話號碼和電子郵件地址；(e) 通知者基於誠信提出侵權通知，且所通知之智慧財產權使用方式經IPR所有人之授權或合法之聲明；(f) 通知者聲明此通知所載之資訊均真實無誤，如有不實，通知者願賠償菜蟲農食因通知者所提供資料造成的任何損失，且通知者具有合法適當權限或已被授權為此侵權通知。</p>
      </>,
      <>
        <h4>9.   購買與付款</h4>
          <p>9.1 菜蟲農食或其合作之金流服務商在其運營的國家支援一種或多種下列付款方式：<br/>
          (i)              支票<br/>
          買方可郵寄支票至菜蟲農食辦公室，如未有另外約定，買方需指定每月15日為支票之兌現日付清前月之款項。<br/>

          (ii)             銀行轉帳<br/>
          買方可透過自動提款機或網路銀行轉帳 (下稱「銀行轉帳」) 將款項匯入我方指定的菜蟲農食帳戶 (如第 11 條所定義)。如未有另外約定，買方需於每月15日付清前月之款項。</p>
          <p>9.2 買方只能在付款前變更付款方式。</p>

          <p>9.3 買方支付完成前，應依菜蟲農食提供的再確認機制確認支付指示是否正確。因不可歸責於消費者的事由而發生支付錯誤時，菜蟲農食或其合作之金流服務商將協助消費者更正並提供其他必要之協助。因可歸責於菜蟲農食或其合作之金流服務商的事由而發生支付錯誤時，菜蟲農食或其合作之金流服務商將於知悉時立即更正，並同時以電子郵件、簡訊或App推播等方式通知消費者。因可歸責於消費者的事由而發生支付錯誤時，例如輸入錯誤之金額或輸入錯誤之收款方，經消費者通知後，菜蟲農食或其合作之金流服務商將立即協助處理。我們有權查核買方是否具備使用特定付款方式之充分授權，並可在授權獲得確認之前暫停交易，或在未能獲得確認的情況下取消相關交易。買方完成每筆款項支付後，菜蟲農食將以電子郵件、簡訊或App推播等方式通知買方支付明細，並提供買方的即時顯示支付明細供買家查詢。</p>
        <h4>10.  爭議</h4>
          <p>10.1 在交易發生問題時，買方與賣方同意先互相溝通解決爭議(菜蟲農食應盡合理的商業上努力便利溝通進行)。如果爭議無法透過買賣雙方溝通解決，使用者可尋求其當地有管轄權的爭議處理機關的協助，以解決交易中發生的任何糾紛。</p>
          <p>10.2 買方及賣方均同意並擔保，不會因關於本網站上成立的交易或與此類交易有關的任何爭議，對菜蟲農食或其關係企業提起訴訟或以其他方式主張索賠（除非菜蟲農食或其關係企業是與該主張有關的商品的賣方）。</p>
          <p>10.3 使用者若享有菜蟲農食履約保證，可透過書面方式請求菜蟲農食協助解決交易中發生的問題。菜蟲農食得符合誠信原則決定採取所有必要步驟，以協助使用者解決糾紛，但菜蟲農食並不因此而對賣方與買方承擔任何責任。如需詳細資訊，請參閱菜蟲農食的退款與退貨政策。</p>
          <p>10.4 明確而言，本條所提供的服務僅提供予享有菜蟲農食履約保證的買方。使用其他付款方式進行購買的買方，則應直接與賣方聯繫。</p>
        <h4>11.  免責聲明</h4>
          <p>11.1 本服務係依現況現狀提供，菜蟲農食對於本服務不作任何明示、暗示或法定的擔保、主張或聲明，包括但不限於對於品質、效能、無侵權、適售性或特定用途適用性之擔保，或因交易過程、按慣例或行業常規而衍生之擔保。在不受限上述條款之前提下，且在法律容許的最大限度內，菜蟲農食不保證提供本服務、本網站或其中所含功能，或該服務、網站或功能可被存取、不會中斷、及時提供、安全可靠、正確、完整或無錯誤；且如有瑕疵 (如有) 會被更正；不保證本網站及/或本網站伺服器完全沒有任何病毒、時鐘、計時器、計數器、蠕蟲、軟體鎖、致死裝置、木馬、路由、陷阱門、定時炸彈或任何其他有害之程式碼、指令、程式或元件。</p>
          <p>11.2 您承認在法律容許的最大限度內，您個人應承擔使用本網站及/或本服務或其效能之全部相關風險。</p>
          <p>11.3 菜蟲農食並未控制，且在法律容許的最大限度內，也不為下列事項提供保證或接受任何責任：(A)經由本服務提供之商品之適用於特定目的、存在、品質、安全性或合法性；或(B)賣方販賣商品的能力或買方付款的能力。如爭議涉及一位或多位使用者，該使用者同意在他們之間直接解決此爭議，且在法律容許的最大限度內，免除菜蟲農食及其關係企業任何及所有此爭議所導致或與其相關之主張、要求及損害。</p>
        <h4>12.  賠償責任</h4>
          <p>12.1 菜蟲農食對於因使用或無法使用本網站或服務，所造成之任何間接、附帶、特殊或衍生性之損害，於法定限度內負擔賠償責任。</p>
          <p>12.2 儘管有前述約定，如仍有爭議，菜蟲農食將依被有管轄權之法院判決確定有責任的限度內對你或第三人負擔賠償責任。</p>
          <p>12.3 本服務協議並未限制或排除任何因菜蟲農食過失所導致之個人身體損傷、詐欺或任何其他菜蟲農食依法不得限制或排除之責任。</p>
        <h4>13.  您的聲明與擔保</h4>
          <p>
            您聲明並保證：<br/>
            (a) 您代表具備工商登記的法人，擁有簽訂本服務條款並遵守其條款的法律權限、權利及能力；並且<br/>
            (b) 您僅會基於合法目的使用本服務，並會遵守本服務條款及所有適用法律、規定、守則、指令、準則、政策及規範。<br/>
          </p>
        <h4>14.  詐騙或可疑活動</h4>
          <p>若菜蟲農食依其單方考量相信您涉及任何潛在的詐騙或可疑活動及/或交易，得採取包括但不限於以下的各種措施以保障菜蟲農食、買方、賣方、其他第三人或您不承擔交易取消、交易糾紛、請求、費用、罰緩、罰金或任何其他責任：<br/>
            (a) 我們得中止、暫停、或限制您使用您的帳戶或本服務，及/或暫停處理您的交易；<br/>
            (b) 我們得暫停您適用菜蟲農食履約保證的資格；<br/>
            (c) 我們得依有權管轄機關之裁判或命令保留、處置或移轉您的帳戶款項；<br/>
            (d) 我們得於現在或將來拒絕提供您本服務；<br/>
            (e)我們得為合理保護菜蟲農食或第三人免於承擔風險或責任而暫時保留您的款項。
          </p>
      </>,
      <>
      <h4>15.  一般規定</h4>
        <p>15.1 菜蟲農食保留在本協議未明文授予的所有權利。</p>
        <p>15.2  菜蟲農食得隨時透過在本網站上公布修訂版服務條款的方式修改本服務條款之內容，並同時以電子郵件、簡訊或App推播等方式通知您。未進行前述公告及通知之變更無效。當您在修訂版公布後繼續使用本網站，即視為您接受這個修訂版服務條款。</p>
        <p>15.3  您不得轉讓、再授權或轉移此處授予您的任何權利，或複委任任何義務。</p>
        <p>15.4 本服務條款中的任何約定不得解讀為您與菜蟲農食之間的合夥、合資關係或主要代理人關係，亦未授權您得代表菜蟲農食產生任何費用或責任。</p>
        <p>15.5 菜蟲農食在任何時候如未能要求履行本條款的任何約定，除非已以書面方式放棄該權利，否則不影響其日後要求履行此約定的權利。</p>
        <p>15.6 除菜蟲農食的關係企業與子公司 (及菜蟲農食及其關係企業及子公司各別的承繼人和受讓人) 外，本服務條款僅為您及我們的利益而訂立，而非為任何其他個人或法律實體之利益而訂立。</p>
        <p>15.7 本服務條款中所載的條款，以及本服務條款中所包含或引用的任何協議及政策，均構成雙方就本服務及本網站全部的協議與共識，除雙方另有書面明確約定外，並取代任何雙方先前就相同事項之協議與共識。雙方茲同時排除所有默示約定。在雙方訂定本服務協議所約定之條款時，並未根據本服務協議明文約定以外之由任何人提供的聲明、保證、擔保、共識、承諾或確認。任一方不可撤回地且無條件地，放棄所有若非本條款之約定而可能發生之主張、權利及賠償，本服務協議不得根據任何先前的協議、任何同時存在的口頭協議，或任何一致的附加條款予以否定、解釋或補充。</p>
        <p>15.8 您同意遵守所有與反貪腐相關的法律、條文、規定及準則，包含但不限於英國賄賂法案（UK Bribery Act）、美國海外貪腐防治條例（US Foreign Corrupt Practices Act）以及新加坡防治貪腐法案（Singapore Prevention of Corruption Act），並確認您已採取且應採取所有必須之政策及程序以確保遵守前述規範。</p>
        <p>15.9 若您對本服務條款，或對本服務條款或本網站所衍生之任何問題有疑問或疑慮，請聯絡我們：hello@tsaitung.com</p>
        <p>15.10 菜蟲農食與消費者得以電子文件為表示方法，依本服務條款交換之電子文件，如其內容可完整呈現且可於日後取出供查驗者，其效力與實體書面文件相同。但依法令或行政機關之公告排除適用者，不在此限。本服務條款內容所指書面方式，除另有特別約定外，均包含實體書面及電子郵件。</p>
        <p>15.11 本服務僅接受消費者以新台幣支付款項，不接受新台幣以外之其他貨幣。</p>
        <p>15.12 為保障消費者資料安全、提供安心的交易平台，菜蟲農食與通過國際PCI DSS資訊安全認證的金融機構合作處理付款金流作業，並持續進行內部資訊安全防護自主管理。菜蟲農食及消費者應各自確保其資訊系統之安全，防止非法入侵、取得、竄改、毀損業務紀錄或消費者之個人資料。若有任何第三人破解菜蟲農食資訊系統保護措施或利用資訊系統漏洞之爭議，將由菜蟲農食就該事實不存在負舉證責任。第三人入侵菜蟲農食資訊系統對消費者所造成之損害，由菜蟲農食負擔。</p>
        <p>15.13 消費者應於知悉其帳號密碼被冒用後立即通知菜蟲農食。菜蟲農食應於知悉消費者之帳號密碼被冒用時，立即通知消費者並暫停該帳號所指示之支付行為，並暫停接受該帳號後續之支付指示。消費者辦理通報帳號密碼被冒用手續後發生之損失，概由菜蟲農食負擔；使用者辦理帳號密碼被冒用手續前發生之損失，全部由消費者自行負擔，但以下列情形為限：
        (a)   	消費者未妥善保管使用者 ID 及/或密碼；
        (b)   	消費者自行將使用者 ID 及/或密碼提供予他人；
        (c)   	消費者未使用菜蟲農食所提供的帳戶安全機制；或
        (d)   	其他因消費者之故意或重大過失所致之事由。</p>
      </>,
      <>
        <p>15.14 本公司資訊如下：<br/>
        名稱：菜蟲農食股份有限公司<br/>
        負責人：李昱德<br/>
        網址：https://www.tsaitung.com/<br/>
        地址：台北市松山區基隆路一段178號二樓<br/>
        客服信箱：hello@tsaitung.com</p>
        <p>我已閱讀此份協儀，並同意以上所載的所有約定及其日後之任何修訂內容。當按下下方的「註冊」、「連結 Google」、「連結 LINE」或「連結 FACEBOOK」按鈕，即表示我瞭解我正在進行數位簽名，其與手寫簽名具有同等效力。</p>
        <p>最新修改日期：2019年10月5日</p>
      </>
    ]
}

export default Terms
