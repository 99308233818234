// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");

function result(data) {
  var user = function (userData) {
    var name = Json_decode.field("name", Json_decode.string, userData);
    var status = Context_User$YourProjectName.statusFromJs(Json_decode.field("status", Json_decode.string, userData));
    if (status !== undefined) {
      return {
              name: name,
              status: status
            };
    }
    throw {
          RE_EXN_ID: Json_decode.DecodeError,
          _1: "status unmatched",
          Error: new Error()
        };
  };
  return {
          user: Json_decode.field("user", user, data),
          token: Json_decode.field("token", Json_decode.string, data),
          first_time: Json_decode.field("first_time", Json_decode.bool, data)
        };
}

exports.result = result;
/* Context_User-YourProjectName Not a pure module */
