// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Login$YourProjectName = require("../Login/Login.bs.js");
var Logout$YourProjectName = require("./Logout.bs.js");
var NotFound$YourProjectName = require("../NotFound/NotFound.bs.js");
var Onboarding$YourProjectName = require("../Onboarding/Onboarding.bs.js");
var Router_Wrapper$YourProjectName = require("./Router_Wrapper.bs.js");

var dashboardRouter = React.lazy(function (param) {
      return import("./DashboardRouter.bs");
    });

var nativeRouter = React.lazy(function (param) {
      return import("./NativeRouter.bs");
    });

function renderByPath(param) {
  if (param) {
    if (param.hd === "order") {
      var match = param.tl;
      if (match) {
        switch (match.hd) {
          case "dashboard" :
              return React.createElement(dashboardRouter, {});
          case "login" :
              if (!match.tl) {
                return React.createElement(Login$YourProjectName.make, {});
              }
              break;
          case "logout" :
              if (!match.tl) {
                return React.createElement(Logout$YourProjectName.make, {});
              }
              break;
          case "native" :
              return React.createElement(nativeRouter, {});
          case "onboarding" :
              if (!match.tl) {
                return React.createElement(Onboarding$YourProjectName.make, {});
              }
              break;
          default:
            
        }
      } else {
        RescriptReactRouter.replace("/order/login");
        return null;
      }
    }
    
  } else {
    RescriptReactRouter.replace("/order/login");
    return null;
  }
  return React.createElement(NotFound$YourProjectName.make, {});
}

function Router(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  return React.createElement("div", {
              id: "tsaitung-content"
            }, React.createElement(Router_Wrapper$YourProjectName.make, {
                  children: renderByPath(url.path)
                }));
}

var Wrapper;

var $$Option;

var make = Router;

exports.Wrapper = Wrapper;
exports.$$Option = $$Option;
exports.dashboardRouter = dashboardRouter;
exports.nativeRouter = nativeRouter;
exports.renderByPath = renderByPath;
exports.make = make;
/* dashboardRouter Not a pure module */
