// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var MaterialUi_Typography = require("rescript-material-ui/src/MaterialUi_Typography.bs.js");
var Home = require("@material-ui/icons/Home").default;
var Styles = require("@material-ui/core/styles");
var Language$YourProjectName = require("../../language/Language.bs.js");
var Dehaze = require("@material-ui/icons/Dehaze").default;
var ReactUtil$YourProjectName = require("../../utils/ReactUtil.bs.js");
var Message = require("@material-ui/icons/Message").default;
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Inbox_Badge$YourProjectName = require("../Inbox/Inbox_Badge.bs.js");
var ExitToApp = require("@material-ui/icons/ExitToApp").default;

require('./ExtraMenu.css')
;

var DehazeIcon = {};

var Home$1 = {};

var ExitToApp$1 = {};

var Back = {};

var Message$1 = {};

var useStyles = Styles.makeStyles(function (theme) {
      return {
              extraIcon: {
                padding: "0px"
              },
              sideDrawer: {
                paddingTop: "0px"
              },
              toolbar: theme.mixins.toolbar
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

var $$location = window.location;

function ExtraMenu$Nav(Props) {
  var by = Props.by;
  var icon = Props.icon;
  var label = Props.label;
  var match;
  match = by.TAG === /* ByClick */0 ? [
      by._0,
      undefined
    ] : [
      undefined,
      by._0
    ];
  var link = match[1];
  var tmp = {
    button: true,
    children: null,
    classes: {
      root: "drawerButton"
    },
    divider: true
  };
  var tmp$1 = match[0];
  if (tmp$1 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(tmp$1);
  }
  var render = React.createElement(Core.ListItem, tmp, Curry._1(icon, {
            paddingTop: "1px"
          }), React.createElement(Core.Typography, {
            children: label,
            component: MaterialUi_Typography.Component.string("div"),
            variant: "body2"
          }));
  if (link !== undefined) {
    return React.createElement("a", {
                href: link,
                target: "_blank"
              }, render);
  } else {
    return render;
  }
}

var Nav = {
  make: ExtraMenu$Nav
};

function ExtraMenu$LangSwitcher(Props) {
  var handleSwithchLang = Props.handleSwithchLang;
  return React.createElement("div", {
              className: "switcher"
            }, React.createElement("p", {
                  className: "fieldset"
                }, React.createElement("input", {
                      id: "zh-tw",
                      checked: Language$YourProjectName.userLang === "zh-tw",
                      name: "duration-1",
                      type: "radio",
                      value: "zh-tw",
                      onChange: (function (e) {
                          return Curry._1(handleSwithchLang, e.target.value);
                        })
                    }), React.createElement("label", {
                      htmlFor: "zh-tw"
                    }, "中文"), React.createElement("input", {
                      id: "en-us",
                      checked: Language$YourProjectName.userLang === "en-us",
                      name: "duration-1",
                      type: "radio",
                      value: "en-us",
                      onChange: (function (e) {
                          return Curry._1(handleSwithchLang, e.target.value);
                        })
                    }), React.createElement("label", {
                      htmlFor: "en-us"
                    }, "EN"), React.createElement("span", {
                      className: "switch"
                    })));
}

var LangSwitcher = {
  make: ExtraMenu$LangSwitcher
};

function ExtraMenu(Props) {
  var userName = Props.userName;
  var match = ReactUtil$YourProjectName.useState(function (param) {
        return false;
      });
  var setOpened = match[1];
  var clickToHome = function (param) {
    RescriptReactRouter.push("/order/dashboard/");
    return Curry._1(setOpened, (function (param) {
                  return false;
                }));
  };
  var clickTologout = function (param) {
    RescriptReactRouter.replace("/order/logout");
    return Curry._1(setOpened, (function (param) {
                  return false;
                }));
  };
  var clickToNotification = function (param) {
    RescriptReactRouter.push("/order/dashboard/inbox");
    return Curry._1(setOpened, (function (param) {
                  return false;
                }));
  };
  var handleSwithchLang = function (lang) {
    Language$YourProjectName.switchLang(lang);
    $$location.reload();
    
  };
  var styles = Curry._1(useStyles, undefined);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.IconButton, {
                  onClick: (function (param) {
                      return Curry._1(setOpened, (function (prim) {
                                    return !prim;
                                  }));
                    }),
                  children: React.createElement(Dehaze, {
                        style: {
                          color: "white"
                        }
                      }),
                  classes: {
                    root: styles.extraIcon
                  },
                  disableFocusRipple: true,
                  disableRipple: true
                }), React.createElement(Core.Drawer, {
                  anchor: "left",
                  children: React.createElement(Core.List, {
                        children: null,
                        classes: {
                          root: styles.sideDrawer + " sideDrawer"
                        }
                      }, React.createElement(Core.ListItem, {
                            children: React.createElement(Core.Typography, {
                                  children: userName,
                                  variant: "subtitle1"
                                }),
                            classes: {
                              root: "drawerHeader"
                            },
                            divider: true
                          }), React.createElement(ExtraMenu$Nav, {
                            by: {
                              TAG: /* ByClick */0,
                              _0: clickToHome
                            },
                            icon: (function (style) {
                                return React.createElement(Home, {
                                            style: style
                                          });
                              }),
                            label: React.createElement(LangString$YourProjectName.make, {
                                  strKey: "mainScreen"
                                })
                          }), React.createElement(ExtraMenu$Nav, {
                            by: {
                              TAG: /* ByClick */0,
                              _0: clickToNotification
                            },
                            icon: (function (style) {
                                return React.createElement(Message, {
                                            style: style
                                          });
                              }),
                            label: React.createElement(Inbox_Badge$YourProjectName.Badge.make, {
                                  children: React.createElement(LangString$YourProjectName.make, {
                                        strKey: "notification"
                                      }),
                                  readOnClick: true
                                })
                          }), React.createElement(ExtraMenu$Nav, {
                            by: {
                              TAG: /* ByClick */0,
                              _0: clickTologout
                            },
                            icon: (function (style) {
                                return React.createElement(ExitToApp, {
                                            style: style
                                          });
                              }),
                            label: React.createElement(LangString$YourProjectName.make, {
                                  strKey: "logout"
                                })
                          }), React.createElement(ExtraMenu$LangSwitcher, {
                            handleSwithchLang: handleSwithchLang
                          })),
                  onClose: (function (param) {
                      return Curry._1(setOpened, (function (param) {
                                    return false;
                                  }));
                    }),
                  open: match[0]
                }));
}

var $$Location;

var make = ExtraMenu;

exports.DehazeIcon = DehazeIcon;
exports.Home = Home$1;
exports.ExitToApp = ExitToApp$1;
exports.Back = Back;
exports.Message = Message$1;
exports.Styles = Styles$1;
exports.$$Location = $$Location;
exports.$$location = $$location;
exports.Nav = Nav;
exports.LangSwitcher = LangSwitcher;
exports.make = make;
/*  Not a pure module */
