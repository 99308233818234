// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");
var Logger$YourProjectName = require("../../utils/Logger.bs.js");
var Language$YourProjectName = require("../../language/Language.bs.js");
var ApiCaller$YourProjectName = require("../../utils/ApiCaller.bs.js");
var ReactUtil$YourProjectName = require("../../utils/ReactUtil.bs.js");
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");
var Login_Decode$YourProjectName = require("./Login_Decode.bs.js");
var Custom_Dialog$YourProjectName = require("../../utils/Custom/Custom_Dialog.bs.js");
var Landing_Template$YourProjectName = require("../Landing/Landing_Template.bs.js");
var Custom__InputFilled$YourProjectName = require("../../utils/Custom/Custom__InputFilled.bs.js");
var Custom__FormValidateWrapper$YourProjectName = require("../../utils/Custom/Custom__FormValidateWrapper.bs.js");

require('./Login.css')
;

function convertValidity(_id, vs) {
  var match = vs.valueMissing;
  if (match) {
    return {
            TAG: /* Left */0,
            _0: "帳號密碼不得為空"
          };
  } else if (vs.valid) {
    return {
            TAG: /* Right */1,
            _0: ""
          };
  } else {
    return {
            TAG: /* Left */0,
            _0: ""
          };
  }
}

var Rules = {
  convertValidity: convertValidity
};

var InputFilled = Custom__InputFilled$YourProjectName.M(Rules);

var ApiCallers = ApiCaller$YourProjectName.M({
      post: Util$YourProjectName.TsaitungApi.post,
      get: Util$YourProjectName.TsaitungApi.get,
      put: Util$YourProjectName.TsaitungApi.put,
      ResponseError: Util$YourProjectName.TsaitungApi.ResponseError,
      makeResErr: Util$YourProjectName.TsaitungApi.makeResErr
    });

function Login(Props) {
  var match = ReactUtil$YourProjectName.useState(function (param) {
        return false;
      });
  var setWaitForLogin = match[1];
  var waitForLogin = match[0];
  var match$1 = React.useContext(Context_User$YourProjectName.Methods.context);
  var setUser = match$1.set;
  var match$2 = ReactUtil$YourProjectName.useState(function (param) {
        
      });
  var setFail = match$2[1];
  var fail = match$2[0];
  var match$3 = ReactUtil$YourProjectName.useState(function (param) {
        return "";
      });
  var setAccount = match$3[1];
  var account = match$3[0];
  var match$4 = ReactUtil$YourProjectName.useState(function (param) {
        return "";
      });
  var setPassword = match$4[1];
  var password = match$4[0];
  var handleLogin = function (param) {
    Curry._1(setWaitForLogin, (function (param) {
            return true;
          }));
    Curry._1(setFail, (function (param) {
            
          }));
    var formData = new FormData();
    formData.append("account", account);
    formData.append("password", password);
    var handler400 = function (res) {
      var match = Util$YourProjectName.ReasonPromise.status(res);
      if (match !== 400) {
        if (match !== 403) {
          return ;
        } else {
          return Caml_option.some(Curry._1(Util$YourProjectName.ReasonPromise.resolved, {
                          TAG: /* Error */1,
                          _0: {
                            TAG: /* Message */1,
                            _0: Language$YourProjectName.useLangString("err_locked")
                          }
                        }));
        }
      } else {
        return Caml_option.some(Curry._1(Util$YourProjectName.ReasonPromise.resolved, {
                        TAG: /* Error */1,
                        _0: {
                          TAG: /* Message */1,
                          _0: Language$YourProjectName.useLangString("err_account")
                        }
                      }));
      }
    };
    return Curry._2(Util$YourProjectName.ReasonPromise.getError, Curry._2(Util$YourProjectName.ReasonPromise.tapOk, Curry._6(ApiCallers.postWithHandler, {
                        hd: handler400,
                        tl: /* [] */0
                      }, "/auth/login", undefined, formData, /* FormData */1, undefined), (function (result) {
                      Curry._1(setWaitForLogin, (function (param) {
                              return false;
                            }));
                      var result$1 = Login_Decode$YourProjectName.result(result);
                      Curry._1(setUser, result$1);
                      return RescriptReactRouter.replace("/order/dashboard");
                    })), (function (error) {
                  Curry._1(setWaitForLogin, (function (param) {
                          return false;
                        }));
                  switch (error.TAG | 0) {
                    case /* JsPromiseError */0 :
                        Curry._1(setFail, (function (param) {
                                return "未知的錯誤";
                              }));
                        return Logger$YourProjectName.error2("/auth/login", error._0);
                    case /* Message */1 :
                        var s = error._0;
                        return Curry._1(setFail, (function (param) {
                                      return s;
                                    }));
                    case /* DecodeError */2 :
                        var s$1 = error._0;
                        return Curry._1(setFail, (function (param) {
                                      return "系統資料錯誤" + s$1;
                                    }));
                    
                  }
                }));
  };
  return React.createElement("div", {
              id: "login"
            }, React.createElement(Custom_Dialog$YourProjectName.WarningSimple.make, {
                  color: /* Secondary */2,
                  onClose: (function (param, param$1) {
                      return Curry._1(setFail, (function (param) {
                                    
                                  }));
                    }),
                  open_: Rebase.$$Option.isSome(fail),
                  title: "錯誤",
                  content: (function (param) {
                      return Rebase.$$Option.getOr("", fail);
                    }),
                  onExit: (function (param) {
                      return Curry._1(setFail, (function (param) {
                                    
                                  }));
                    }),
                  className: "failDialog"
                }), React.createElement(Landing_Template$YourProjectName.make, {
                  children: null
                }, React.createElement(Core.Typography, {
                      align: "center",
                      children: "好新鮮・好安心・好便宜",
                      classes: {
                        root: "boldTitle"
                      },
                      variant: "h5"
                    }), React.createElement(Core.Typography, {
                      align: "center",
                      children: React.createElement(LangString$YourProjectName.make, {
                            strKey: "login"
                          }),
                      classes: {
                        root: "subTitle boldTitle"
                      },
                      variant: "subtitle1"
                    }), React.createElement(Custom__FormValidateWrapper$YourProjectName.make, {
                      toNext: handleLogin,
                      children: (function (param) {
                          var toNextWithGuard = param.toNextWithGuard;
                          var hasSubmit = param.hasSubmit;
                          return React.createElement("form", undefined, React.createElement("div", {
                                          className: "loginFills"
                                        }, React.createElement(InputFilled.make, {
                                              label: "loginaccount",
                                              value: account,
                                              onChange: (function (param) {
                                                  return Curry._1(setAccount, (function (param$1) {
                                                                return param;
                                                              }));
                                                }),
                                              id_: "login-accounr",
                                              required: true,
                                              supressError: !hasSubmit
                                            }), React.createElement(InputFilled.make, {
                                              label: "password",
                                              value: password,
                                              onChange: (function (param) {
                                                  return Curry._1(setPassword, (function (param$1) {
                                                                return param;
                                                              }));
                                                }),
                                              id_: "login-password",
                                              required: true,
                                              type_: "password",
                                              supressError: !hasSubmit
                                            }), React.createElement(Core.Typography, {
                                              align: "center",
                                              children: React.createElement(LangString$YourProjectName.make, {
                                                    strKey: "contactClientService"
                                                  }),
                                              classes: {
                                                root: "bottomLink"
                                              },
                                              color: "textPrimary",
                                              variant: "body2"
                                            })), React.createElement("div", {
                                          className: "loginBottom"
                                        }, React.createElement(Core.Button, {
                                              onClick: (function (param) {
                                                  return Curry._1(toNextWithGuard, undefined);
                                                }),
                                              children: React.createElement(LangString$YourProjectName.make, {
                                                    strKey: "nextStep"
                                                  }),
                                              classes: {
                                                root: "nextButton"
                                              },
                                              color: "secondary",
                                              disabled: waitForLogin,
                                              fullWidth: true,
                                              variant: "contained"
                                            })));
                        })
                    })));
}

var FormValidateWrapper;

var make = Login;

exports.FormValidateWrapper = FormValidateWrapper;
exports.Rules = Rules;
exports.InputFilled = InputFilled;
exports.ApiCallers = ApiCallers;
exports.make = make;
/*  Not a pure module */
