// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Spread(Props) {
  return React.cloneElement(Props.children, Props.props);
}

var make = Spread;

exports.make = make;
/* react Not a pure module */
