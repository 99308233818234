// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Util$YourProjectName = require("../../../utils/Util.bs.js");
var Collection_Decode$YourProjectName = require("../../../utils/Collection_Decode.bs.js");
var Collection_Encode$YourProjectName = require("../../../utils/Collection_Encode.bs.js");

function putStoreItem(token, param) {
  var jsonString = Collection_Encode$YourProjectName.storeItems(param[1]);
  return Util$YourProjectName.TsaitungApi.put("/stores/" + param[0] + "/regular_supply/", token, jsonString, undefined);
}

function getStoreItem(token, id) {
  return Util$YourProjectName.TsaitungApi.get("/stores/" + id + "/regular_supply/", token, undefined).then(function (res) {
                if (res.ok) {
                  return res.json();
                } else {
                  return Util$YourProjectName.TsaitungApi.makeResErr(res);
                }
              }).then(function (res) {
              return Promise.resolve(Json_decode.list(Collection_Decode$YourProjectName.storeItem, res));
            });
}

function getStores(token) {
  return Util$YourProjectName.TsaitungApi.get("/stores/", token, undefined).then(function (res) {
                if (res.ok) {
                  return res.json();
                } else {
                  return Util$YourProjectName.TsaitungApi.makeResErr(res);
                }
              }).then(function (responseJson) {
              return Promise.resolve(Collection_Decode$YourProjectName.Stores.stores(responseJson));
            });
}

function getItemPrices(token, date, id) {
  return Util$YourProjectName.TsaitungApi.get("/stores/" + id + "/regular_supply/?price_date=" + date, token, undefined).then(function (res) {
                if (res.ok) {
                  return res.json();
                } else {
                  return Util$YourProjectName.TsaitungApi.makeResErr(res);
                }
              }).then(function (res) {
              return Promise.resolve([
                          Json_decode.list(Collection_Decode$YourProjectName.storeItem, res),
                          Collection_Decode$YourProjectName.storeItemPrices(res)
                        ]);
            });
}

var Api;

exports.Api = Api;
exports.putStoreItem = putStoreItem;
exports.getStoreItem = getStoreItem;
exports.getStores = getStores;
exports.getItemPrices = getItemPrices;
/* Util-YourProjectName Not a pure module */
