// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Collections$YourProjectName = require("./Collections.bs.js");

var _map = {"Number":"個數","Pack":"包裝","Weight":"重量","Tentative":"暫定"};

var _revMap = {"個數":"Number","包裝":"Pack","重量":"Weight","暫定":"Tentative"};

function unitTypeToJs(param) {
  return _map[param];
}

function unitTypeFromJs(param) {
  return _revMap[param];
}

function unit(data) {
  var unitType = unitTypeFromJs(Json_decode.field("unit_type", Json_decode.string, data));
  var conversion = unitType !== undefined ? (
      unitType === "Tentative" ? undefined : Json_decode.optional((function (param) {
                return Json_decode.field("conversion", Json_decode.$$float, param);
              }), data)
    ) : Json_decode.optional((function (param) {
            return Json_decode.field("conversion", Json_decode.$$float, param);
          }), data);
  return {
          name: Json_decode.field("name", Json_decode.string, data),
          id: Json_decode.field("id", Json_decode.string, data),
          conversion: conversion,
          is_weighted: Json_decode.field("is_weighted", Json_decode.bool, data)
        };
}

function min_order(data) {
  return {
          amount: Json_decode.field("amount", Json_decode.$$float, data),
          unit_id: Json_decode.field("unit_id", Json_decode.string, data)
        };
}

function price(json) {
  var price$1 = Rebase.$$Option.map((function (value) {
          return {
                  value: value,
                  unit: Json_decode.field("pricing_unit", unit, json)
                };
        }), Caml_option.null_to_opt(Json_decode.field("price", (function (param) {
                  return Json_decode.nullable(Json_decode.$$float, param);
                }), json)));
  var is_current_price = Json_decode.field("is_current_price", Json_decode.bool, json);
  if (is_current_price) {
    if (price$1 !== undefined) {
      return {
              TAG: /* CurrentPrice */0,
              _0: price$1
            };
    } else {
      return /* Current */0;
    }
  } else if (price$1 !== undefined) {
    return {
            TAG: /* Price */1,
            _0: price$1
          };
  } else {
    return ;
  }
}

function item(data) {
  return {
          id: Json_decode.field("id", Json_decode.string, data),
          name: Json_decode.field("name", Json_decode.string, data),
          photo_url: Json_decode.optional((function (param) {
                  return Json_decode.field("photo_url", Json_decode.string, param);
                }), data),
          grade: Collections$YourProjectName.SupplyItem.itemGradeFromJs_(Json_decode.field("grade", Json_decode.string, data)),
          available_units: Json_decode.field("units", (function (param) {
                  return Json_decode.array(unit, param);
                }), data),
          price: price(data),
          is_active: Json_decode.field("is_active", Json_decode.bool, data),
          is_regular: Json_decode.field("is_regular", Json_decode.bool, data) ? /* Regular */0 : /* NonRegular */1,
          is_visible: Json_decode.field("is_visible", Json_decode.bool, data),
          min_order: Caml_option.null_to_opt(Json_decode.field("min_order", (function (param) {
                      return Json_decode.nullable(min_order, param);
                    }), data)),
          min_order_quantity: Caml_option.null_to_opt(Json_decode.field("min_order_quantity", (function (param) {
                      return Json_decode.nullable(min_order, param);
                    }), data)),
          include_tax: Json_decode.field("include_tax", Json_decode.bool, data)
        };
}

var Supply = {
  min_order: min_order,
  price: price,
  item: item
};

function budgetPerUnit(data) {
  return {
          budget: Json_decode.field("budget", Json_decode.$$int, data),
          unit: Json_decode.field("unit", Json_decode.string, data)
        };
}

function storeItem(data) {
  var min_order = function (minOrderData) {
    return {
            amount: Json_decode.field("amount", Json_decode.$$float, minOrderData),
            unit_name: Json_decode.field("unit_name", Json_decode.string, minOrderData),
            unit_id: Json_decode.field("unit_id", Json_decode.string, minOrderData)
          };
  };
  var photo_url = Json_decode.optional((function (param) {
          return Json_decode.field("photo_url", Json_decode.string, param);
        }), data);
  var item = function (itemData) {
    return {
            id: Json_decode.field("id", Json_decode.string, itemData),
            name: Json_decode.field("name", Json_decode.string, itemData),
            photo_url: photo_url,
            is_active: Json_decode.field("is_active", Json_decode.bool, itemData),
            is_visible: Json_decode.field("is_visible", Json_decode.bool, itemData),
            is_regular: Json_decode.field("is_regular", Json_decode.bool, itemData) ? /* Regular */0 : /* NonRegular */1,
            grade: Collections$YourProjectName.SupplyItem.itemGradeFromJs_(Json_decode.field("grade", Json_decode.string, itemData)),
            min_order: Caml_option.null_to_opt(Json_decode.field("min_order", (function (param) {
                        return Json_decode.nullable(min_order, param);
                      }), itemData)),
            min_order_quantity: Caml_option.null_to_opt(Json_decode.field("min_order_quantity", (function (param) {
                        return Json_decode.nullable(min_order, param);
                      }), itemData)),
            include_tax: Json_decode.field("include_tax", Json_decode.bool, itemData)
          };
  };
  return {
          item: Json_decode.field("item", item, data),
          budget: Json_decode.optional((function (param) {
                  return Json_decode.field("budget", Json_decode.$$int, param);
                }), data),
          budgetPerUnit: Json_decode.optional((function (param) {
                  return Json_decode.field("budget_per_unit", budgetPerUnit, param);
                }), data),
          notes: Caml_option.null_to_opt(Json_decode.field("notes", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), data))
        };
}

function storeItemPrice(json) {
  var key_item_id = Json_decode.field("item", (function (param) {
          return Json_decode.field("id", Json_decode.string, param);
        }), json);
  var key_grade = Collections$YourProjectName.SupplyItem.itemGradeFromJs_(Json_decode.field("item", (function (param) {
              return Json_decode.field("grade", Json_decode.string, param);
            }), json));
  var key = {
    item_id: key_item_id,
    grade: key_grade
  };
  var is_current_price = Json_decode.field("is_current_price", Json_decode.bool, json);
  var price = Json_decode.field("price", (function (param) {
          return Json_decode.nullable((function (priceJson) {
                        return {
                                value: Json_decode.field("price", Json_decode.$$float, priceJson),
                                unit: Json_decode.field("unit", Json_decode.string, priceJson)
                              };
                      }), param);
        }), json);
  var value = is_current_price ? (
      price !== null ? ({
            TAG: /* CurrentPrice */0,
            _0: price
          }) : /* Current */0
    ) : (
      price !== null ? ({
            TAG: /* Price */1,
            _0: price
          }) : /* NoPrice */1
    );
  return [
          key,
          value
        ];
}

function storeItemPrices(param) {
  return Json_decode.array(storeItemPrice, param);
}

function aw(awData) {
  return {
          kg: Caml_option.null_to_opt(Json_decode.field("kg", (function (param) {
                      return Json_decode.nullable(Json_decode.$$float, param);
                    }), awData)),
          weight: Caml_option.null_to_opt(Json_decode.field("weight", (function (param) {
                      return Json_decode.nullable(Json_decode.$$float, param);
                    }), awData)),
          quantity: Caml_option.null_to_opt(Json_decode.field("quantity", (function (param) {
                      return Json_decode.nullable(Json_decode.$$float, param);
                    }), awData))
        };
}

function orderItem(data) {
  return {
          item: {
            id: Json_decode.field("id", Json_decode.string, data),
            name: Json_decode.field("name", Json_decode.string, data),
            grade: Collections$YourProjectName.SupplyItem.itemGradeFromJs_(Json_decode.field("grade", Json_decode.string, data)),
            locked: Caml_option.null_to_opt(Json_decode.field("locked", (function (param) {
                        return Json_decode.nullable(Json_decode.bool, param);
                      }), data)),
            regular: {
              notes: Caml_option.null_to_opt(Json_decode.field("notes", (function (param) {
                          return Json_decode.nullable(Json_decode.string, param);
                        }), data)),
              budget: Json_decode.optional((function (param) {
                      return Json_decode.field("budget", Json_decode.$$int, param);
                    }), data),
              budgetPerUnit: Json_decode.optional((function (param) {
                      return Json_decode.field("budget_per_unit", budgetPerUnit, param);
                    }), data)
            },
            unit: Json_decode.field("unit", unit, data),
            replenish_info: {
              clientAdmit: Rebase.$$Option.getOr(undefined, Json_decode.optional((function (param) {
                          return Json_decode.field("replenish_info", (function (param) {
                                        return Json_decode.optional((function (param) {
                                                      return Json_decode.field("clientAdmit", Json_decode.$$float, param);
                                                    }), param);
                                      }), param);
                        }), data))
            },
            quantity: Json_decode.field("quantity", Json_decode.$$float, data),
            aw: Json_decode.field("aw", aw, data)
          },
          money: {
            price: Json_decode.field("price", Json_decode.$$float, data),
            total: Json_decode.field("total", Json_decode.$$float, data)
          }
        };
}

function last_modified_by(d) {
  var f = function (data) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, data),
            account: Json_decode.field("account", Json_decode.string, data),
            name: Json_decode.field("name", Json_decode.string, data)
          };
  };
  return Caml_option.null_to_opt(Json_decode.nullable(f, d));
}

function return_info(data) {
  return {
          notes: Json_decode.field("notes", Json_decode.string, data)
        };
}

function adjustment(data) {
  return {
          name: Json_decode.field("name", Json_decode.string, data),
          amount: Json_decode.field("amount", Json_decode.$$float, data)
        };
}

function returnItem(json) {
  return {
          item_name: Json_decode.field("item_name", Json_decode.string, json),
          quantity: Json_decode.field("quantity", Json_decode.$$int, json)
        };
}

function logistics(data) {
  return {
          address: Caml_option.null_to_opt(Json_decode.field("delivery_address", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), data)),
          arrival_time: Rebase.$$Option.flatMap((function (start) {
                  return Rebase.$$Option.map((function (end_) {
                                var can_leave_package = Rebase.$$Option.getOr(false, Caml_option.null_to_opt(Json_decode.field("can_leave_package", (function (param) {
                                                return Json_decode.nullable(Json_decode.bool, param);
                                              }), data)));
                                return {
                                        start: start,
                                        end_: end_,
                                        can_leave_package: can_leave_package
                                      };
                              }), Caml_option.null_to_opt(Json_decode.field("arrival_time_end", (function (param) {
                                        return Json_decode.nullable(Json_decode.string, param);
                                      }), data)));
                }), Caml_option.null_to_opt(Json_decode.field("arrival_time_start", (function (param) {
                          return Json_decode.nullable(Json_decode.string, param);
                        }), data))),
          return_items: Json_decode.field("return_items", (function (param) {
                  return Json_decode.array(returnItem, param);
                }), data)
        };
}

function preferredUnit(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

function order(data) {
  return {
          id: Json_decode.field("id", Json_decode.string, data),
          date: Json_decode.field("date", Json_decode.string, data),
          supply_items: Json_decode.field("supply_items", (function (param) {
                  return Json_decode.array(orderItem, param);
                }), data),
          total: {
            TAG: /* Accurate */1,
            _0: Json_decode.field("total", Json_decode.$$float, data)
          },
          last_modified_by: Json_decode.field("last_modified_by", last_modified_by, data),
          return_info: Json_decode.field("return_info", return_info, data),
          logistics: Rebase.$$Option.getOr(Collections$YourProjectName.Order.logistics_default, Json_decode.optional((function (param) {
                      return Json_decode.field("logistics", logistics, param);
                    }), data)),
          logistics_notes: Json_decode.optional((function (param) {
                  return Json_decode.field("logistics_notes", Json_decode.string, param);
                }), data),
          adjustments: Json_decode.field("adjustments", (function (param) {
                  return Json_decode.array(adjustment, param);
                }), data),
          replenish_items: Json_decode.field("replenish_items", (function (param) {
                  return Json_decode.array(orderItem, param);
                }), data),
          items_total: {
            TAG: /* Accurate */1,
            _0: Json_decode.field("items_total", Json_decode.$$float, data)
          },
          orderType: Json_decode.field("is_additional", Json_decode.bool, data) ? "Additional" : "Normal",
          preferred_unit: Json_decode.optional((function (param) {
                  return Json_decode.field("preferred_unit", preferredUnit, param);
                }), data)
        };
}

function order_simple(data) {
  return {
          id: Json_decode.field("id", Json_decode.string, data),
          date: Json_decode.field("date", Json_decode.string, data),
          orderType: Json_decode.field("is_additional", Json_decode.bool, data) ? "Additional" : "Normal"
        };
}

function orderInfo(data) {
  return {
          id: Json_decode.field("id", Json_decode.string, data),
          last_modified_by: Json_decode.field("last_modified_by", last_modified_by, data),
          orderType: Json_decode.field("is_additional", Json_decode.bool, data) ? "Additional" : "Normal"
        };
}

function orders(param) {
  return Json_decode.array(order, param);
}

function store(data) {
  return {
          id: Json_decode.field("id", Json_decode.string, data),
          name: Json_decode.field("name", Json_decode.string, data),
          min_amount: Json_decode.field("min_amount", Json_decode.$$float, data)
        };
}

function stores(param) {
  return Json_decode.array(store, param);
}

var Stores = {
  store: store,
  stores: stores
};

function responseFail(data) {
  return {
          result: Json_decode.field("result", Json_decode.bool, data),
          message: Json_decode.field("message", Json_decode.string, data)
        };
}

var ResponseFail = {
  responseFail: responseFail
};

exports.unitTypeToJs = unitTypeToJs;
exports.unitTypeFromJs = unitTypeFromJs;
exports.unit = unit;
exports.Supply = Supply;
exports.budgetPerUnit = budgetPerUnit;
exports.storeItem = storeItem;
exports.storeItemPrice = storeItemPrice;
exports.storeItemPrices = storeItemPrices;
exports.orderItem = orderItem;
exports.last_modified_by = last_modified_by;
exports.return_info = return_info;
exports.adjustment = adjustment;
exports.returnItem = returnItem;
exports.logistics = logistics;
exports.order = order;
exports.order_simple = order_simple;
exports.orderInfo = orderInfo;
exports.orders = orders;
exports.Stores = Stores;
exports.ResponseFail = ResponseFail;
/* Collections-YourProjectName Not a pure module */
