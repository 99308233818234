// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var MaterialUi_TextField = require("rescript-material-ui/src/MaterialUi_TextField.bs.js");
var Language$YourProjectName = require("../../language/Language.bs.js");
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Custom__FormValidateWrapper$YourProjectName = require("./Custom__FormValidateWrapper.bs.js");

require('./Custom__InputFilled.css')
;

function M(Rules) {
  var handlePattern = function (p) {
    if (p.TAG === /* Str */0) {
      return p._0;
    } else {
      return p._0.source;
    }
  };
  var Custom__InputFilled$M = function (Props) {
    var classNameOpt = Props.className;
    var label = Props.label;
    var value = Props.value;
    var onChange = Props.onChange;
    var id_ = Props.id_;
    var requiredOpt = Props.required;
    var autoCompleteOpt = Props.autoComplete;
    var type_Opt = Props.type_;
    var placeholderOpt = Props.placeholder;
    var pattern = Props.pattern;
    var error = Props.error;
    var supressErrorOpt = Props.supressError;
    var onValid = Props.onValid;
    var disabledOpt = Props.disabled;
    var useSelectOpt = Props.useSelect;
    var children = Props.children;
    var endAdornment = Props.endAdornment;
    var className = classNameOpt !== undefined ? classNameOpt : "";
    var required = requiredOpt !== undefined ? requiredOpt : false;
    var autoComplete = autoCompleteOpt !== undefined ? autoCompleteOpt : false;
    var type_ = type_Opt !== undefined ? type_Opt : "text";
    var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
    var supressError = supressErrorOpt !== undefined ? supressErrorOpt : false;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var useSelect = useSelectOpt !== undefined ? useSelectOpt : false;
    var handleChange = function (e) {
      return Curry._1(onChange, e.target.value);
    };
    var requiredStr = Language$YourProjectName.useLangString("require");
    var inputRef = React.useRef(null);
    var match = React.useState(function () {
          if (required) {
            return {
                    TAG: /* Left */0,
                    _0: requiredStr
                  };
          } else {
            return {
                    TAG: /* Right */1,
                    _0: ""
                  };
          }
        });
    var setValidState_ = match[1];
    var validState = match[0];
    var setValidState = React.useCallback((function (f) {
            if (error !== undefined) {
              return Curry._1(setValidState_, (function (param) {
                            return {
                                    TAG: /* Left */0,
                                    _0: error
                                  };
                          }));
            } else {
              return Curry._1(setValidState_, f);
            }
          }), [error]);
    var match$1 = React.useContext(Custom__FormValidateWrapper$YourProjectName.Context.context);
    var onValid$p = {
      contents: Rebase.$$Option.getOr(match$1.onValid, onValid)
    };
    React.useEffect((function () {
            Curry._2(onValid$p.contents, id_, validState);
            return (function (param) {
                      return Curry._2(onValid$p.contents, id_, {
                                  TAG: /* Right */1,
                                  _0: ""
                                });
                    });
          }), [
          validState,
          id_
        ]);
    var match$2;
    match$2 = validState.TAG === /* Left */0 ? [
        validState._0,
        true
      ] : [
        validState._0,
        false
      ];
    var validate = React.useCallback((function (param) {
            var elm = inputRef.current;
            if (elm == null) {
              return ;
            }
            var validateResult = Curry._2(Rules.convertValidity, id_, elm.validity);
            return Curry._1(setValidState, (function (param) {
                          return validateResult;
                        }));
          }), [
          id_,
          setValidState,
          inputRef
        ]);
    React.useEffect((function () {
            if (useSelect) {
              if (value === "" && required) {
                Curry._1(setValidState, (function (param) {
                        return {
                                TAG: /* Left */0,
                                _0: "必選"
                              };
                      }));
              } else {
                Curry._1(setValidState, (function (param) {
                        return {
                                TAG: /* Right */1,
                                _0: ""
                              };
                      }));
              }
            }
            
          }), [
          value,
          useSelect
        ]);
    var match$3 = React.useState(function () {
          return false;
        });
    var setIsFocus = match$3[1];
    var isFocus = match$3[0];
    var onFocus = function (param) {
      return Curry._1(setIsFocus, (function (param) {
                    return true;
                  }));
    };
    var onBlur = function (param) {
      Curry._1(validate, undefined);
      return Curry._1(setIsFocus, (function (param) {
                    return false;
                  }));
    };
    React.useEffect((function () {
            if (!isFocus) {
              Curry._1(validate, undefined);
            }
            
          }), [
          validate,
          isFocus,
          value
        ]);
    var supressError$p = supressError || isFocus;
    var tmp = {
      autoComplete: autoComplete ? "on" : "off",
      children: useSelect ? Rebase.$$Option.getOr(null, children) : null,
      classes: {
        root: "inputFilled " + (className + ((
              required ? " required" : ""
            ) + (
              match$2[1] && !supressError$p ? " hasError" : ""
            )))
      },
      disabled: disabled,
      FormHelperTextProps: {
        className: "helperText"
      },
      helperText: supressError$p ? "" : match$2[0],
      inputProps: {
        id: id_,
        name: id_,
        ref: inputRef,
        required: required,
        pattern: Rebase.$$Option.map(handlePattern, pattern),
        onFocus: onFocus,
        onBlur: onBlur
      },
      label: React.createElement("label", {
            className: "requiredStar",
            htmlFor: id_
          }, React.createElement(LangString$YourProjectName.make, {
                strKey: label
              })),
      onChange: handleChange,
      placeholder: placeholder,
      select: useSelect,
      SelectProps: {
        native: true
      },
      type: type_,
      value: MaterialUi_TextField.Value.string(value),
      variant: "outlined"
    };
    var tmp$1 = Rebase.$$Option.map((function (ed) {
            return {
                    endAdornment: ed
                  };
          }), endAdornment);
    if (tmp$1 !== undefined) {
      tmp.InputProps = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement(Core.TextField, tmp);
  };
  return {
          handlePattern: handlePattern,
          make: Custom__InputFilled$M
        };
}

function convertValidity(_id, vs) {
  var match = vs.valueMissing;
  var match$1 = vs.patternMismatch;
  var match$2 = vs.typeMismatch;
  if (match) {
    return {
            TAG: /* Left */0,
            _0: Language$YourProjectName.userLang === "zh-tw" ? "必填" : "Required"
          };
  } else if (match$1) {
    return {
            TAG: /* Left */0,
            _0: Language$YourProjectName.userLang === "zh-tw" ? "格式錯誤" : "Format Error"
          };
  } else if (match$2) {
    return {
            TAG: /* Left */0,
            _0: Language$YourProjectName.userLang === "zh-tw" ? "格式錯誤" : "Format Error"
          };
  } else if (vs.valid) {
    return {
            TAG: /* Right */1,
            _0: ""
          };
  } else {
    return {
            TAG: /* Left */0,
            _0: ""
          };
  }
}

var DefaultRules = {
  convertValidity: convertValidity
};

exports.M = M;
exports.DefaultRules = DefaultRules;
/*  Not a pure module */
