// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Styles$YourProjectName = require("../../styles/Styles.bs.js");

require('./Custom__Stepper.css')
;

function createStepper($$int) {
  var l = $$int < 1 ? 1 : $$int;
  return /* Stepper */{
          _0: 1,
          _1: l
        };
}

function toNextStep($$int, stepper) {
  var totalSteps = stepper._1;
  var at_ = stepper._0;
  if ((at_ + $$int | 0) > totalSteps) {
    return /* Stepper */{
            _0: totalSteps,
            _1: totalSteps
          };
  } else {
    return /* Stepper */{
            _0: at_ + $$int | 0,
            _1: totalSteps
          };
  }
}

function toLastStep($$int, stepper) {
  var totalSteps = stepper._1;
  var at_ = stepper._0;
  if ((at_ - $$int | 0) <= 0 || $$int < 0) {
    return /* Stepper */{
            _0: 1,
            _1: totalSteps
          };
  } else {
    return /* Stepper */{
            _0: at_ - $$int | 0,
            _1: totalSteps
          };
  }
}

function toNthStep(n, param) {
  var total = param._1;
  if (n > total) {
    return /* Stepper */{
            _0: total,
            _1: total
          };
  } else if (n < 1) {
    return /* Stepper */{
            _0: 1,
            _1: total
          };
  } else {
    return /* Stepper */{
            _0: n,
            _1: total
          };
  }
}

function get(stepper) {
  return stepper._0;
}

function Custom__Stepper$Step(Props) {
  var className = Props.className;
  return React.createElement("li", {
              className: className
            }, React.createElement("div", {
                  className: "line"
                }), React.createElement("div", {
                  className: "circle"
                }));
}

function render(stepper, className) {
  var length = stepper._1;
  var active = stepper._0;
  var isActive = length <= active;
  if (length === 0) {
    return /* [] */0;
  }
  var elm = React.createElement(Custom__Stepper$Step, {
        className: (
          length === 1 ? "stepFirst" : "step"
        ) + (" " + Curry._1(className, isActive)),
        key: String(length)
      });
  return {
          hd: elm,
          tl: render(/* Stepper */{
                _0: isActive ? active - 1 | 0 : active,
                _1: length - 1 | 0
              }, className)
        };
}

function Custom__Stepper(Props) {
  var state = Props.state;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "GreenMain";
  return React.createElement("ul", {
              className: "stepper"
            }, $$Array.of_list(render(state, (function (param) {
                        return Styles$YourProjectName.sizeToJs(size) + (" " + (Styles$YourProjectName.colorToJs(color) + (
                                    param ? " active" : ""
                                  )));
                      }))));
}

function Step_makeProps(prim0, prim1, prim2) {
  var tmp = {
    className: prim0
  };
  if (prim1 !== undefined) {
    tmp.key = prim1;
  }
  return tmp;
}

var Step = {
  makeProps: Step_makeProps,
  make: Custom__Stepper$Step
};

function makeProps(prim0, prim1, prim2, prim3, prim4) {
  var tmp = {
    state: prim0
  };
  if (prim1 !== undefined) {
    tmp.size = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.color = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.key = prim3;
  }
  return tmp;
}

var make = Custom__Stepper;

exports.createStepper = createStepper;
exports.toNextStep = toNextStep;
exports.toLastStep = toLastStep;
exports.toNthStep = toNthStep;
exports.get = get;
exports.Step = Step;
exports.makeProps = makeProps;
exports.make = make;
/*  Not a pure module */
