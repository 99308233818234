// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var _map = {"GreenMain":"greenMain","OrangeMain":"orangeMain"};

var _revMap = {"greenMain":"GreenMain","orangeMain":"OrangeMain"};

function colorToJs(param) {
  return _map[param];
}

function colorFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"Large":"large","Medium":"medium","Small":"small"};

var _revMap$1 = {"large":"Large","medium":"Medium","small":"Small"};

function sizeToJs(param) {
  return _map$1[param];
}

function sizeFromJs(param) {
  return _revMap$1[param];
}

exports.colorToJs = colorToJs;
exports.colorFromJs = colorFromJs;
exports.sizeToJs = sizeToJs;
exports.sizeFromJs = sizeFromJs;
/* No side effect */
