// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var forceReload = (function () {
      if(process.env.NODE_ENV === 'production') {
        var r = new XMLHttpRequest();
        r.onload = function () {
          var t = r.responseText;
          var versionStart = t.indexOf('"/precache-manifest.') + 20;
          var versionEnd = t.indexOf('.js"', versionStart);
          if (versionEnd - versionStart === 32) {
            var ls = localStorage;
            var oldPrecacheManifestVersion = ls.getItem('pmv');
            var newPrecacheManifestVersion = t.substring(versionStart, versionEnd);

            if (newPrecacheManifestVersion !== oldPrecacheManifestVersion) {
              ls.setItem('pmv', newPrecacheManifestVersion);
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/service-worker.js').then(reg => {
                  reg.waiting.skipWaiting();
                  reg.update();
                }).catch(err => console.error(err));
              }
              return window.location.reload(true);
            }
          }
        };
        r.open('GET', '/service-worker.js?c=' + new Date().getTime());
        r.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
        r.send();
      }
    });

exports.forceReload = forceReload;
/* No side effect */
