// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Styles = require("@material-ui/core/styles");

var useStyles = Styles.makeStyles(function (param) {
      return {
              headerAnchor1: {
                display: "flex",
                alignItems: "center"
              },
              leaveButton: {
                color: "white",
                fontWeight: "normal",
                left: "-10px",
                padding: "0px",
                position: "relative"
              },
              iconButton: {
                padding: "0px"
              },
              menuPop: {
                zIndex: "0",
                borderRadius: "4px",
                boxShadow: "1px 1px 1px lightgrey"
              },
              name: {
                display: "flex",
                position: "relative",
                alignItems: "center"
              },
              triangle: {
                color: "white"
              },
              input: {
                paddingRight: "24px"
              },
              hidden: {
                display: "none"
              },
              headerInner: {
                display: "flex",
                flex: "1",
                justifyContent: "space-between"
              },
              header: {
                boxShadow: "unset",
                flexDirection: "row"
              }
            };
    });

var useStyles$1 = Styles.makeStyles(function (theme) {
      return {
              badge: {
                backgroundColor: theme.palette.secondary.main,
                bottom: "-10px",
                color: "white",
                fontSize: "10px",
                padding: "2px 8px",
                position: "absolute",
                right: "-7px",
                textDecoration: "underline",
                zIndex: "1100",
                wordBreak: "keep-all"
              },
              container: {
                position: "relative"
              }
            };
    });

var Badge = {
  useStyles: useStyles$1
};

var A;

exports.useStyles = useStyles;
exports.A = A;
exports.Badge = Badge;
/* useStyles Not a pure module */
