// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Dayjs = require("dayjs");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Logger$YourProjectName = require("../../utils/Logger.bs.js");
var Styles = require("@material-ui/core/styles");
var Inbox_Api$YourProjectName = require("./Inbox_Api.bs.js");
var ReactUtil$YourProjectName = require("../../utils/ReactUtil.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");
var ContextProvider$YourProjectName = require("../Contexts/ContextProvider.bs.js");
var ReactNativeCommunicate$YourProjectName = require("../../reactNative/ReactNativeCommunicate.bs.js");

var useStyles = Styles.makeStyles(function (param) {
      return {
              container: {
                display: "flex",
                alignItems: "center"
              },
              badge: {
                height: "12px",
                position: "absolute",
                top: "2px",
                width: "12px",
                borderRadius: "50%"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

function urlCmp(_u1, _u2) {
  while(true) {
    var u2 = _u2;
    var u1 = _u1;
    var lengthCmp = u1.length === u2.length;
    if (!lengthCmp) {
      return false;
    }
    var match = Belt_Array.get(u1, 0);
    var match$1 = Belt_Array.get(u2, 0);
    if (match === undefined) {
      return match$1 === undefined;
    }
    if (match$1 === undefined) {
      return false;
    }
    if (!Caml_obj.caml_equal(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1))) {
      return false;
    }
    _u2 = Belt_Array.sliceToEnd(u2, 1);
    _u1 = Belt_Array.sliceToEnd(u1, 1);
    continue ;
  };
}

function useCount(urls) {
  var match = ReactUtil$YourProjectName.useState(function (param) {
        return [
                Date.now(),
                false
              ];
      });
  var setNotZero = match[1];
  var notZero = match[0];
  var setNotZero$1 = function (b) {
    return Curry._1(setNotZero, (function (param) {
                  return [
                          Date.now(),
                          b
                        ];
                }));
  };
  var user = React.useContext(Context_User$YourProjectName.context);
  var reCount = React.useCallback((function (param) {
          if (user !== undefined) {
            Inbox_Api$YourProjectName.getMessageCount(user.token).then(function (param) {
                    return Promise.resolve(setNotZero$1(param[1] > 0));
                  }).catch(function (e) {
                  return Promise.resolve(Logger$YourProjectName.error2("inbox count", e));
                });
            return ;
          }
          
        }), [user]);
  React.useEffect((function () {
          if (user !== undefined) {
            Curry._1(reCount, undefined);
          }
          
        }), [user]);
  React.useEffect((function () {
          var iid = setInterval(reCount, 60000);
          return (function (param) {
                    clearInterval(iid);
                    
                  });
        }), [
        reCount,
        notZero
      ]);
  React.useEffect((function () {
          return Curry._1(ReactNativeCommunicate$YourProjectName.Events.Inbox.onMessage, reCount);
        }), [reCount]);
  React.useEffect((function () {
          var handler = function (url) {
            var urls$1 = urls.map(function (url) {
                  return url.split("/").filter(function (v) {
                              return v !== "";
                            });
                });
            var path = Belt_List.toArray(url.path);
            var matchSomeUrl = Belt_Array.some(Belt_Array.map(urls$1, (function (param) {
                        return urlCmp(path, param);
                      })), (function (v) {
                    return v;
                  }));
            if (matchSomeUrl) {
              return Curry._1(reCount, undefined);
            }
            
          };
          var t = RescriptReactRouter.watchUrl(handler);
          return (function (param) {
                    return RescriptReactRouter.unwatchUrl(t);
                  });
        }), [
        reCount,
        urls
      ]);
  return [
          notZero[1],
          reCount
        ];
}

var match = ReactNativeCommunicate$YourProjectName.isInWebview(undefined);

var urls = match !== undefined ? [
    "/order/dashboard/session",
    "/order/dashboard/records"
  ] : ["/order/dashboard/"];

var context = React.createContext([
      false,
      (function (param) {
          throw {
                RE_EXN_ID: ContextProvider$YourProjectName.UninitContext,
                Error: new Error()
              };
        })
    ]);

var Provider = ContextProvider$YourProjectName.M({
      contextPtr: context
    });

function useOnRead(param) {
  var user = React.useContext(Context_User$YourProjectName.context);
  return function (param) {
    if (user === undefined) {
      return Promise.resolve(undefined);
    }
    var timestamp = Dayjs(new Date(Date.now())).tz("Asia/Taipei").valueOf();
    return Inbox_Api$YourProjectName.postRead(user.token, timestamp).then(function (param) {
                  return Promise.resolve(undefined);
                }).catch(function (e) {
                return Promise.resolve(Logger$YourProjectName.error2("inbox post read", e));
              });
  };
}

function Inbox_Badge$Badge(Props) {
  var children = Props.children;
  var readOnClick = Props.readOnClick;
  var styles = Curry._1(useStyles, undefined);
  var match = React.useContext(context);
  var reCount = match[1];
  var onRead = useOnRead(undefined);
  var runRead = function (param) {
    Curry._1(onRead, undefined).then(function (param) {
          return Promise.resolve(Curry._1(reCount, undefined));
        });
    
  };
  return React.createElement("div", {
              className: styles.container,
              onClick: readOnClick ? runRead : (function (param) {
                    
                  })
            }, React.createElement(Core.Badge, {
                  children: children,
                  classes: {
                    badge: styles.badge
                  },
                  color: "secondary",
                  invisible: !match[0],
                  variant: "dot"
                }));
}

var Badge = {
  make: Inbox_Badge$Badge
};

function Inbox_Badge(Props) {
  var children = Props.children;
  var value = useCount(urls);
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, value, children, undefined));
}

var RNC;

var IApi;

var make = Inbox_Badge;

exports.RNC = RNC;
exports.IApi = IApi;
exports.Styles = Styles$1;
exports.urlCmp = urlCmp;
exports.useCount = useCount;
exports.urls = urls;
exports.context = context;
exports.Provider = Provider;
exports.useOnRead = useOnRead;
exports.Badge = Badge;
exports.make = make;
/* useStyles Not a pure module */
