// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");

function useState(f) {
  var match = React.useState(function () {
        return Curry._1(f, undefined);
      });
  var setState_ = match[1];
  var mount = {
    contents: true
  };
  React.useEffect((function () {
          return (function (param) {
                    mount.contents = false;
                    
                  });
        }), []);
  var setState = React.useCallback((function (setF) {
          if (mount.contents) {
            return Curry._1(setState_, setF);
          }
          
        }), []);
  return [
          match[0],
          setState
        ];
}

function useMemEffect2(init, cb, deps) {
  var match = useState(function (param) {
        return init;
      });
  var setState = match[1];
  React.useEffect((function () {
          Curry._1(setState, (function (param) {
                  return Curry._1(cb, undefined);
                }));
          
        }), deps);
  return match[0];
}

function asRef(b) {
  var r = React.useRef(b);
  r.current = b;
  return r;
}

exports.useState = useState;
exports.useMemEffect2 = useMemEffect2;
exports.asRef = asRef;
/* react Not a pure module */
