// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function phone(str) {
  return str._0;
}

function email(str) {
  return str._0;
}

function line_id(str) {
  return str._0;
}

function address(str) {
  return str._0;
}

var _map = {"_0":"0","_1":"1","_2":"2","_3":"3","_4":"4","_5":"5","_6":"6","_7":"7","_8":"8","_9":"9"};

var _revMap = {"0":"_0","1":"_1","2":"_2","3":"_3","4":"_4","5":"_5","6":"_6","7":"_7","8":"_8","9":"_9"};

function digitToJs(param) {
  return _map[param];
}

function digitFromJs(param) {
  return _revMap[param];
}

exports.phone = phone;
exports.email = email;
exports.line_id = line_id;
exports.address = address;
exports.digitToJs = digitToJs;
exports.digitFromJs = digitFromJs;
/* No side effect */
