// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Let$YourProjectName = require("../../utils/Let.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Header$YourProjectName = require("../Header/Header.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");

function pageTypeFromPath(param) {
  if (!param) {
    return /* Intermediate */2;
  }
  if (param.hd !== "order") {
    return /* Intermediate */2;
  }
  var match = param.tl;
  if (!match) {
    return /* Intermediate */2;
  }
  switch (match.hd) {
    case "dashboard" :
        return /* Private */0;
    case "logout" :
        return /* Intermediate */2;
    case "login" :
    case "onboarding" :
        return /* Public */1;
    default:
      return /* Intermediate */2;
  }
}

function grabUserFromLocalStorage(param) {
  var accountStatus = Belt_Option.getWithDefault(Belt_Option.flatMap(Dom_storage.getItem("tsaitungAccountStatus", localStorage), Context_User$YourProjectName.statusFromJs), "Verified");
  return Curry._2(Let$YourProjectName.$$Option.let_, Dom_storage.getItem("tasitungOrderToken", localStorage), (function (tokenInStorage) {
                return Curry._2(Let$YourProjectName.$$Option.let_, Dom_storage.getItem("tasitungOrderUser", localStorage), (function (userInStorage) {
                              return Let$YourProjectName.$$Option.$$return({
                                          user: {
                                            name: userInStorage,
                                            status: accountStatus
                                          },
                                          token: tokenInStorage,
                                          first_time: false
                                        });
                            }));
              }));
}

function useUserCheck(pageType) {
  var match = grabUserFromLocalStorage(undefined);
  switch (pageType) {
    case /* Private */0 :
        if (match !== undefined) {
          return [
                  true,
                  {
                    TAG: /* SetUserObj */0,
                    _0: match
                  }
                ];
        } else {
          return [
                  false,
                  /* ToLogout */1
                ];
        }
    case /* Public */1 :
        if (match !== undefined) {
          return [
                  true,
                  {
                    TAG: /* ToLogin */1,
                    _0: match
                  }
                ];
        } else {
          return [
                  true,
                  /* Nothing */0
                ];
        }
    case /* Intermediate */2 :
        return [
                true,
                /* Nothing */0
              ];
    
  }
}

function Router_Wrapper$Fallback(Props) {
  return React.createElement(Header$YourProjectName.make, {
              isPrivatePath: false
            });
}

var Fallback = {
  make: Router_Wrapper$Fallback
};

function Router_Wrapper(Props) {
  var children = Props.children;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = useUserCheck(pageTypeFromPath(url.path));
  var action = match[1];
  var user = React.useContext(Context_User$YourProjectName.context);
  var match$1 = React.useContext(Context_User$YourProjectName.Methods.context);
  var setUser = match$1.set;
  var reLogin = function (userObj) {
    if (Belt_Option.mapWithDefault(user, true, (function (userObj) {
              return Caml_obj.caml_notequal(userObj, userObj);
            }))) {
      Curry._1(setUser, userObj);
    }
    return RescriptReactRouter.replace("/order/dashboard");
  };
  var reLoginRef = React.useRef(reLogin);
  reLoginRef.current = reLogin;
  React.useEffect((function () {
          if (typeof action === "number") {
            if (action !== /* Nothing */0) {
              RescriptReactRouter.replace("/order/logout");
            }
            
          } else if (action.TAG === /* SetUserObj */0) {
            Curry._1(setUser, action._0);
          } else {
            Curry._1(reLoginRef.current, action._0);
          }
          
        }), [action]);
  return React.createElement(React.Suspense, {
              children: match[0] ? children : null,
              fallback: React.createElement(Router_Wrapper$Fallback, {})
            });
}

var $$Option;

var Rr;

var make = Router_Wrapper;

exports.$$Option = $$Option;
exports.Rr = Rr;
exports.pageTypeFromPath = pageTypeFromPath;
exports.grabUserFromLocalStorage = grabUserFromLocalStorage;
exports.useUserCheck = useUserCheck;
exports.Fallback = Fallback;
exports.make = make;
/* react Not a pure module */
