// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Browser = require("@sentry/browser");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");
var Logger$YourProjectName = require("../../utils/Logger.bs.js");
var ApiCaller$YourProjectName = require("../../utils/ApiCaller.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");

function M(UtilApi) {
  var ApiCallers = ApiCaller$YourProjectName.M(UtilApi);
  var decode = function (data) {
    var name = Json_decode.field("name", Json_decode.string, data);
    var status = Context_User$YourProjectName.statusFromJs(Json_decode.field("status", Json_decode.string, data));
    if (status !== undefined) {
      return [
              name,
              status
            ];
    }
    throw {
          RE_EXN_ID: Json_decode.DecodeError,
          _1: "decode /account/status: user status lost",
          Error: new Error()
        };
  };
  var getUser = function (token, setter) {
    return Curry._2(Util$YourProjectName.ReasonPromise.getError, Curry._2(Util$YourProjectName.ReasonPromise.mapOk, Curry._3(ApiCallers.get, "/account/status/", token, undefined), (function (jsonString) {
                      var val;
                      try {
                        val = decode(jsonString);
                      }
                      catch (raw_str){
                        var str = Caml_js_exceptions.internalToOCamlException(raw_str);
                        if (str.RE_EXN_ID === Json_decode.DecodeError) {
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    TAG: /* DecodeError */2,
                                    _0: str._1
                                  }
                                };
                        }
                        throw str;
                      }
                      Curry._2(setter, val[0], val[1]);
                      return {
                              TAG: /* Ok */0,
                              _0: ""
                            };
                    })), (function (error) {
                  switch (error.TAG | 0) {
                    case /* JsPromiseError */0 :
                        Browser.captureException(error._0);
                        return ;
                    case /* Message */1 :
                        return ;
                    case /* DecodeError */2 :
                        var str = "decode /account/status error:" + error._0;
                        Browser.captureMessage(str);
                        return Logger$YourProjectName.error(str);
                    
                  }
                }));
  };
  var useGet = function (user, setter) {
    var match = RescriptReactRouter.useUrl(undefined, undefined);
    var path = match.path;
    var mount = {
      contents: true
    };
    React.useEffect((function () {
            if (!path) {
              return ;
            }
            if (path.hd !== "order") {
              return ;
            }
            var match = path.tl;
            if (match && match.hd === "dashboard" && !(match.tl || user === undefined)) {
              getUser(user.token, mount.contents ? (function (name, accountStatus) {
                        return Curry._1(setter, {
                                    user: {
                                      name: name,
                                      status: accountStatus
                                    },
                                    token: user.token,
                                    first_time: user.first_time
                                  });
                      }) : (function (param, param$1) {
                        
                      }));
              return (function (param) {
                        mount.contents = false;
                        
                      });
            }
            
          }), [
          path,
          user
        ]);
    
  };
  return {
          ApiCallers: ApiCallers,
          decode: decode,
          getUser: getUser,
          useGet: useGet
        };
}

exports.M = M;
/* react Not a pure module */
