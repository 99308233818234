// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/src/Json.bs.js");
var List = require("rescript/lib/js/list.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Collections$YourProjectName = require("./Collections.bs.js");

var EncodingError = /* @__PURE__ */Caml_exceptions.create("Collection_Encode-YourProjectName.EncodingError");

function budgetPerUnit(b) {
  return Json_encode.object_({
              hd: [
                "unit",
                b.unit
              ],
              tl: {
                hd: [
                  "budget",
                  b.budget
                ],
                tl: /* [] */0
              }
            });
}

function storeItems(items) {
  return Json.stringify(Json_encode.list((function (item) {
                    var obj_0 = [
                      "notes",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), item.notes)
                    ];
                    var obj_1 = {
                      hd: [
                        "id",
                        item.item.id
                      ],
                      tl: {
                        hd: [
                          "grade",
                          Collections$YourProjectName.itemGradeToJs(item.item.grade)
                        ],
                        tl: /* [] */0
                      }
                    };
                    var obj = {
                      hd: obj_0,
                      tl: obj_1
                    };
                    var obj$1 = Belt_Option.mapWithDefault(item.budget, obj, (function (b) {
                            return List.cons([
                                        "budget",
                                        b
                                      ], obj);
                          }));
                    return Json_encode.object_(Belt_Option.mapWithDefault(item.budgetPerUnit, obj$1, (function (b) {
                                      return List.cons([
                                                  "budget_per_unit",
                                                  budgetPerUnit(b)
                                                ], obj$1);
                                    })));
                  }), items));
}

function unit(item) {
  var match = item.unit;
  if (match !== undefined) {
    return match.id;
  }
  throw {
        RE_EXN_ID: EncodingError,
        _1: "item with undefined unit, id:" + (item.id + (",name:" + item.name)),
        Error: new Error()
      };
}

function orderItems(items, logistics) {
  var itemsToObj = function (item) {
    return Json_encode.object_(List.concat({
                    hd: {
                      hd: [
                        "id",
                        item.id
                      ],
                      tl: {
                        hd: [
                          "grade",
                          Collections$YourProjectName.itemGradeToJs(item.grade)
                        ],
                        tl: {
                          hd: [
                            "quantity",
                            item.quantity
                          ],
                          tl: {
                            hd: [
                              "locked",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), item.locked)
                            ],
                            tl: {
                              hd: [
                                "unit_id",
                                unit(item)
                              ],
                              tl: {
                                hd: [
                                  "notes",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), item.regular.notes)
                                ],
                                tl: {
                                  hd: [
                                    "type",
                                    "supply"
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    tl: {
                      hd: Belt_Option.mapWithDefault(item.regular.budget, /* [] */0, (function (b) {
                              return {
                                      hd: [
                                        "budget",
                                        b
                                      ],
                                      tl: /* [] */0
                                    };
                            })),
                      tl: {
                        hd: Belt_Option.mapWithDefault(item.regular.budgetPerUnit, /* [] */0, (function (b) {
                                return {
                                        hd: [
                                          "budget_per_unit",
                                          budgetPerUnit(b)
                                        ],
                                        tl: /* [] */0
                                      };
                              })),
                        tl: /* [] */0
                      }
                    }
                  }));
  };
  var returnItems_ = function (items) {
    if (items.length === 0) {
      return [];
    }
    var hd = Caml_array.get(items, 0);
    var tl = Belt_Array.sliceToEnd(items, 1);
    return Belt_Array.concat([returnItem(hd)], returnItems_(tl));
  };
  var returnItem = function (item) {
    return Json_encode.object_({
                hd: [
                  "item_name",
                  item.item_name
                ],
                tl: {
                  hd: [
                    "quantity",
                    item.quantity
                  ],
                  tl: /* [] */0
                }
              });
  };
  var returnItems = function (items) {
    var itemJsons = returnItems_(items);
    if (itemJsons.length !== 0) {
      return itemJsons;
    } else {
      return null;
    }
  };
  var entryToObj = function (entry) {
    return Json_encode.object_({
                hd: [
                  "id",
                  ""
                ],
                tl: {
                  hd: [
                    "grade",
                    ""
                  ],
                  tl: {
                    hd: [
                      "quantity",
                      0
                    ],
                    tl: {
                      hd: [
                        "unit_id",
                        ""
                      ],
                      tl: {
                        hd: [
                          "address",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), entry.address)
                        ],
                        tl: {
                          hd: [
                            "arrival_time_start",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), Belt_Option.map(entry.arrival_time, (function (t) {
                                        return t.start;
                                      })))
                          ],
                          tl: {
                            hd: [
                              "arrival_time_end",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), Belt_Option.map(entry.arrival_time, (function (t) {
                                          return t.end_;
                                        })))
                            ],
                            tl: {
                              hd: [
                                "return_items",
                                returnItems(entry.return_items)
                              ],
                              tl: {
                                hd: [
                                  "can_leave_package",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), Belt_Option.map(entry.arrival_time, (function (t) {
                                              return t.can_leave_package;
                                            })))
                                ],
                                tl: {
                                  hd: [
                                    "type",
                                    "logistics_notes"
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  };
  var itemsObjArray = items.map(itemsToObj);
  var entries = logistics.map(entryToObj);
  return JSON.stringify(entries.concat(itemsObjArray));
}

exports.EncodingError = EncodingError;
exports.budgetPerUnit = budgetPerUnit;
exports.storeItems = storeItems;
exports.unit = unit;
exports.orderItems = orderItems;
/* Collections-YourProjectName Not a pure module */
