// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Core = require("@material-ui/core");
var MaterialUi_Popover = require("rescript-material-ui/src/MaterialUi_Popover.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var MaterialUi_Typography = require("rescript-material-ui/src/MaterialUi_Typography.bs.js");
var ReactUtil$YourProjectName = require("../../utils/ReactUtil.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");
var Header_Styles$YourProjectName = require("./Header_Styles.bs.js");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown").default;
var Context_Items_Map$YourProjectName = require("../Contexts/Items/Context_Items_Map.bs.js");
var UserSelection_PseudoInput$YourProjectName = require("./UserSelection_PseudoInput.bs.js");

require('./UserSelection.css')
;

var ArrowDropDown$1 = {};

function UserSelection$Menu(Props) {
  var menuAnchor = Props.menuAnchor;
  var open_ = Props.open_;
  var setOpen = Props.setOpen;
  var stores = Props.stores;
  var onSelectStore = Props.onSelectStore;
  var handleClose = function (param) {
    return Curry._1(setOpen, (function (param) {
                  return false;
                }));
  };
  var styles = Curry._1(Header_Styles$YourProjectName.useStyles, undefined);
  return React.createElement(Core.Popover, {
              anchorEl: MaterialUi_Popover.AnchorEl.anchorEl_func(menuAnchor.current),
              anchorOrigin: {
                horizontal: MaterialUi_Popover.Horizontal.$$enum(MaterialUi_Popover.Horizontal_enum.center),
                vertical: MaterialUi_Popover.Vertical.$$int(30)
              },
              children: React.createElement(Core.ClickAwayListener, {
                    children: React.createElement("div", undefined, React.createElement(Core.List, {
                              children: $$Array.mapi((function (index, store) {
                                      return React.createElement(Core.ListItem, {
                                                  children: store.name,
                                                  divider: true,
                                                  onClick: (function (param) {
                                                      Curry._1(onSelectStore, store.id);
                                                      return Curry._1(setOpen, (function (param) {
                                                                    return false;
                                                                  }));
                                                    }),
                                                  key: String(index)
                                                });
                                    }), stores)
                            })),
                    onClickAway: handleClose
                  }),
              classes: {
                paper: styles.menuPop
              },
              open: open_,
              transformOrigin: {
                horizontal: MaterialUi_Popover.Horizontal.$$enum(MaterialUi_Popover.Horizontal_enum.center),
                vertical: MaterialUi_Popover.Vertical.$$enum(MaterialUi_Popover.Vertical_enum.top)
              }
            });
}

var Menu = {
  make: UserSelection$Menu
};

function UserSelection(Props) {
  var isWeb = Props.isWeb;
  var user = React.useContext(Context_User$YourProjectName.context);
  var userStatus = Rebase.$$Option.map((function (u) {
          return u.user.status;
        }), user);
  var match = React.useContext(Context_Items_Map$YourProjectName.$$Map.context);
  var store = match[2];
  var storeItemList = match[0];
  var stores = React.useMemo((function () {
          return $$Array.of_list(List.map((function (prim) {
                            return prim[0];
                          }), storeItemList));
        }), [storeItemList]);
  var notMultiStore = stores.length <= 1;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var disabled = React.useMemo((function () {
          var match = url.path;
          if (!match) {
            return true;
          }
          if (match.hd !== "order") {
            return true;
          }
          var match$1 = match.tl;
          if (!match$1) {
            return true;
          }
          if (match$1.hd !== "dashboard") {
            return true;
          }
          var match$2 = match$1.tl;
          if (!match$2) {
            return false;
          }
          switch (match$2.hd) {
            case "records" :
                return false;
            case "session" :
                if (match$2.tl) {
                  return true;
                } else {
                  return false;
                }
            default:
              return true;
          }
        }), [url.path]);
  var menuAnchor = React.useRef(null);
  var match$1 = ReactUtil$YourProjectName.useState(function (param) {
        return false;
      });
  var setMenuOpened = match$1[1];
  var setMenuOpened$1 = React.useCallback((function (f) {
          if (disabled) {
            return ;
          } else {
            return Curry._1(setMenuOpened, f);
          }
        }), [disabled]);
  var onMouseUp = function (param) {
    if (isWeb) {
      if (userStatus !== undefined) {
        if (userStatus === "Pending") {
          return RescriptReactRouter.push("/order/dashboard/account/improvement/complete");
        } else if (userStatus === "Unverified") {
          return RescriptReactRouter.push("/order/dashboard/account/improvement/0");
        } else {
          return Curry._1(setMenuOpened$1, (function (o) {
                        return !o;
                      }));
        }
      } else {
        return ;
      }
    } else {
      return Curry._1(setMenuOpened$1, (function (o) {
                    return !o;
                  }));
    }
  };
  var styles = Curry._1(Header_Styles$YourProjectName.useStyles, undefined);
  var isNotVerified = Caml_obj.caml_notequal(userStatus, "Verified") && isWeb;
  var hideTriangle = isNotVerified || notMultiStore || disabled;
  var match$2 = UserSelection_PseudoInput$YourProjectName.use(undefined);
  var v = React.useRef(false);
  var onClickWorkaround = function (param) {
    v.current = !v.current;
    
  };
  return React.createElement("div", {
              id: "user-select"
            }, match$2[1], store !== undefined ? React.createElement("div", {
                    ref: menuAnchor,
                    id: "user-select-label",
                    onClick: onClickWorkaround,
                    onMouseUp: onMouseUp
                  }, React.createElement(Core.Typography, {
                        children: null,
                        classes: {
                          root: styles.name
                        },
                        color: "textSecondary",
                        component: MaterialUi_Typography.Component.string("div"),
                        variant: "subtitle1"
                      }, isWeb ? React.createElement("span", {
                              style: {
                                transform: "scale(" + 1 + ")"
                              }
                            }, store.name) : store.name, hideTriangle ? null : React.createElement(ArrowDropDown, {
                              className: "triangleIcon"
                            }))) : React.createElement("div", undefined), React.createElement(UserSelection$Menu, {
                  menuAnchor: menuAnchor,
                  open_: match$1[0],
                  setOpen: setMenuOpened$1,
                  stores: stores,
                  onSelectStore: match$2[0]
                }));
}

var List_;

var PseudoInput;

var make = UserSelection;

exports.List_ = List_;
exports.PseudoInput = PseudoInput;
exports.ArrowDropDown = ArrowDropDown$1;
exports.Menu = Menu;
exports.make = make;
/*  Not a pure module */
