
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';


import './index.css';
const { make: App } = require('./components/App.bs');

if(process.env.NODE_ENV === "production") {
   Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
  });
};
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});
