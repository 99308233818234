// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

require('./Landing_Template.css')
;

function Landing_Template(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "landing"
            }, React.createElement("div", {
                  className: "landingGhostTitle"
                }), React.createElement("div", {
                  className: "landingContent"
                }, children));
}

var make = Landing_Template;

exports.make = make;
/*  Not a pure module */
