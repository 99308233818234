// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core = require("@material-ui/core");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Webapi__Dom__Element = require("bs-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js");
var Spread$YourProjectName = require("../../utils/Spread.bs.js");
var Home = require("@material-ui/icons/Home").default;
var ExtraMenu$YourProjectName = require("./ExtraMenu.bs.js");
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Inbox_Badge$YourProjectName = require("../Inbox/Inbox_Badge.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");
var Header_Styles$YourProjectName = require("./Header_Styles.bs.js");
var UserSelection$YourProjectName = require("./UserSelection.bs.js");
var ArrowBackIos = require("@material-ui/icons/ArrowBackIos").default;
var ReactNativeCommunicate$YourProjectName = require("../../reactNative/ReactNativeCommunicate.bs.js");

function Header$Message(Props) {
  var props = Props.props;
  return React.createElement(Spread$YourProjectName.make, {
              props: props,
              children: React.createElement("svg", {
                    height: "22px",
                    width: "22px",
                    version: "1.1",
                    viewBox: "0 0 18 18",
                    xmlns: "http://www.w3.org/2000/svg",
                    xmlnsXlink: "http://www.w3.org/1999/xlink"
                  }, React.createElement("title", undefined, "Combined Shape"), React.createElement("g", {
                        id: "Page-2",
                        fill: "none",
                        fillRule: "evenodd",
                        stroke: "none",
                        strokeWidth: "1"
                      }, React.createElement("path", {
                            id: "Combined-Shape",
                            d: "M8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 C6.55068094,15 5.20423666,14.5595407 4.08722393,13.805179 L2.22466737,14.5260676 C2.09013083,14.5781317 1.94133987,14.5799806 1.80555112,14.5312757 C1.52482897,14.4305858 1.36540625,14.1422409 1.41788772,13.8581938 L1.44335413,13.763934 L2.13790127,11.8271459 C1.41834988,10.7272403 1,9.41247559 1,8 C1,4.13400675 4.13400675,1 8,1 Z M7.5,9 L4.5,9 C3.67157288,9 3,9.67157288 3,10.5 C3,11.3284271 3.67157288,12 4.5,12 L4.5,12 L7.5,12 C8.32842712,12 9,11.3284271 9,10.5 C9,9.67157288 8.32842712,9 7.5,9 L7.5,9 Z M11.5,5 L4.5,5 C3.67157288,5 3,5.67157288 3,6.5 C3,7.27969612 3.59488808,7.92044868 4.35553999,7.99313342 L4.5,8 L11.5,8 C12.3284271,8 13,7.32842712 13,6.5 C13,5.67157288 12.3284271,5 11.5,5 Z",
                            fill: "#FFFFFF",
                            stroke: "#FFFFFF",
                            strokeWidth: "1.3"
                          })))
            });
}

var Message = {
  make: Header$Message
};

function Header$SessionBackToHome(Props) {
  return React.createElement(Core.ButtonBase, {
              children: null,
              onClick: (function (param) {
                  return RescriptReactRouter.push("/order/dashboard/");
                })
            }, React.createElement(ArrowBackIos, {
                  style: {
                    color: "white",
                    fontSize: "16px"
                  }
                }), React.createElement(Home, {
                  style: {
                    color: "white",
                    fontSize: "20px"
                  }
                }));
}

var SessionBackToHome = {
  make: Header$SessionBackToHome
};

function Header$Inbox(Props) {
  var styles = Props.styles;
  return React.createElement(Core.IconButton, {
              onClick: (function (param) {
                  return ReactNativeCommunicate$YourProjectName.Route.push("/order/dashboard/inbox");
                }),
              children: React.createElement(Header$Message, {}),
              classes: {
                root: styles.iconButton
              }
            });
}

var Inbox = {
  make: Header$Inbox
};

function needNarrowHeader(param) {
  if (!param) {
    return false;
  }
  if (param.hd !== "order") {
    return false;
  }
  var match = param.tl;
  if (!match) {
    return false;
  }
  if (match.hd !== "dashboard") {
    return false;
  }
  var match$1 = match.tl;
  if (!match$1) {
    return false;
  }
  if (match$1.hd !== "session") {
    return false;
  }
  var match$2 = match$1.tl;
  if (!match$2) {
    return false;
  }
  var match$3 = match$2.tl;
  if (match$3 && match$3.hd === "placeorder") {
    return true;
  } else {
    return false;
  }
}

function inSession(param) {
  if (!param) {
    return /* False */2;
  }
  if (param.hd !== "order") {
    return /* False */2;
  }
  var match = param.tl;
  if (!match) {
    return /* False */2;
  }
  if (match.hd !== "dashboard") {
    return /* False */2;
  }
  var match$1 = match.tl;
  if (match$1 && match$1.hd === "session") {
    if (match$1.tl) {
      return /* Sub */1;
    } else {
      return /* Main */0;
    }
  } else {
    return /* False */2;
  }
}

var triggerMouseUp = (function(elm) {
    var evt = document.createEvent("MouseEvents");
    evt.initEvent("mouseup", true, true);
    elm.dispatchEvent(evt);
  });

function Header$UserStatusBadge(Props) {
  var children = Props.children;
  var user = React.useContext(Context_User$YourProjectName.context);
  var showBadge = Belt_Option.mapWithDefault(user, false, (function (u) {
          return u.user.status === "Unverified";
        }));
  var styles = Curry._1(Header_Styles$YourProjectName.Badge.useStyles, undefined);
  var onClick = function (param) {
    return Belt_Option.forEach(Belt_Option.flatMap(Caml_option.nullable_to_opt(document.getElementById("user-select-label")), Webapi__Dom__Element.asHtmlElement), triggerMouseUp);
  };
  return React.createElement("div", {
              className: styles.container
            }, showBadge ? React.createElement("div", {
                    className: styles.badge,
                    htmlFor: "user-select-input",
                    onClick: onClick
                  }, React.createElement(LangString$YourProjectName.make, {
                        strKey: "completeInformation"
                      })) : null, children);
}

var UserStatusBadge = {
  triggerMouseUp: triggerMouseUp,
  make: Header$UserStatusBadge
};

function Header$Inner(Props) {
  var name = Props.name;
  var isPrivatePath = Props.isPrivatePath;
  var isWeb = Props.isWeb;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var narrowHeader = needNarrowHeader(url.path);
  var styles = Curry._1(Header_Styles$YourProjectName.useStyles, undefined);
  var match = inSession(url.path);
  var tmp;
  if (isWeb) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(ExtraMenu$YourProjectName.make, {
              userName: name
            }), isPrivatePath ? React.createElement(Header$UserStatusBadge, {
                children: React.createElement(UserSelection$YourProjectName.make, {
                      isWeb: isWeb
                    })
              }) : null);
  } else {
    switch (match) {
      case /* Main */0 :
          tmp = React.createElement(React.Fragment, undefined, isPrivatePath ? React.createElement(UserSelection$YourProjectName.make, {
                      isWeb: isWeb
                    }) : React.createElement("div", undefined), React.createElement(Inbox_Badge$YourProjectName.Badge.make, {
                    children: React.createElement(Header$Inbox, {
                          styles: styles
                        }),
                    readOnClick: true
                  }));
          break;
      case /* Sub */1 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(Header$SessionBackToHome, {}), isPrivatePath ? React.createElement(UserSelection$YourProjectName.make, {
                      isWeb: isWeb
                    }) : null);
          break;
      case /* False */2 :
          tmp = isPrivatePath ? React.createElement(UserSelection$YourProjectName.make, {
                  isWeb: isWeb
                }) : null;
          break;
      
    }
  }
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Core.AppBar, {
                  children: React.createElement("div", {
                        id: "header-anchor"
                      }),
                  classes: {
                    root: styles.header + " headerBeneath"
                  },
                  position: "static"
                }), React.createElement(Core.AppBar, {
                  children: React.createElement("div", {
                        className: styles.headerInner
                      }, React.createElement(Inbox_Badge$YourProjectName.make, {
                            children: tmp
                          })),
                  classes: {
                    root: styles.header + (" header" + (
                        narrowHeader ? " headerNarrowLeft headerNarrowRight" : ""
                      ))
                  },
                  position: "fixed"
                }));
}

var Inner = {
  make: Header$Inner
};

function Header(Props) {
  var nameOpt = Props.name;
  var isPrivatePath = Props.isPrivatePath;
  var name = nameOpt !== undefined ? nameOpt : "";
  var match = ReactNativeCommunicate$YourProjectName.isInWebview(undefined);
  if (match !== undefined) {
    return React.createElement(Header$Inner, {
                name: name,
                isPrivatePath: isPrivatePath,
                isWeb: false
              });
  } else {
    return React.createElement(Header$Inner, {
                name: name,
                isPrivatePath: isPrivatePath,
                isWeb: true
              });
  }
}

var Styles;

var Rr;

var headerAnchor1 = "header-info-1";

var headerAnchor2 = "header-info-2";

var headerAnchor3 = "header-info-3";

var make = Header;

exports.Styles = Styles;
exports.Rr = Rr;
exports.Message = Message;
exports.headerAnchor1 = headerAnchor1;
exports.headerAnchor2 = headerAnchor2;
exports.headerAnchor3 = headerAnchor3;
exports.SessionBackToHome = SessionBackToHome;
exports.Inbox = Inbox;
exports.needNarrowHeader = needNarrowHeader;
exports.inSession = inSession;
exports.UserStatusBadge = UserStatusBadge;
exports.Inner = Inner;
exports.make = make;
/* react Not a pure module */
