// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function let_(p, f) {
  return p.then(Curry.__1(f));
}

function $$return(prim) {
  return Promise.resolve(prim);
}

var JsPromise = {
  let_: let_,
  $$return: $$return
};

function $$return$1(a) {
  return Caml_option.some(a);
}

var $$Option = {
  let_: Belt_Option.flatMap,
  $$return: $$return$1
};

var $$Promise$1 = {
  let_: $$Promise.flatMap,
  $$return: $$Promise.resolved
};

exports.JsPromise = JsPromise;
exports.$$Option = $$Option;
exports.$$Promise = $$Promise$1;
/* Promise Not a pure module */
