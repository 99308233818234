// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");
var Logger$YourProjectName = require("../../utils/Logger.bs.js");
var ReactUtil$YourProjectName = require("../../utils/ReactUtil.bs.js");
var Context_User$YourProjectName = require("./Context_User.bs.js");
var AccountStatus$YourProjectName = require("../Account/AccountStatus.bs.js");
var ServiceWorker$YourProjectName = require("../../utils/ServiceWorker.bs.js");

var AccountStatus = AccountStatus$YourProjectName.M({
      post: Util$YourProjectName.TsaitungApi.post,
      get: Util$YourProjectName.TsaitungApi.get,
      put: Util$YourProjectName.TsaitungApi.put,
      ResponseError: Util$YourProjectName.TsaitungApi.ResponseError,
      makeResErr: Util$YourProjectName.TsaitungApi.makeResErr
    });

function setUser(user, setUser_, userObj) {
  Dom_storage.setItem("tasitungOrderToken", userObj.token, localStorage);
  Dom_storage.setItem("tasitungOrderUser", userObj.user.name, localStorage);
  Dom_storage.setItem("tasitungAccount", userObj.user.name, localStorage);
  Dom_storage.setItem("tsaitungAccountStatus", Context_User$YourProjectName.statusToJs(userObj.user.status), localStorage);
  if (user !== undefined) {
    if (Caml_obj.caml_notequal(user, userObj)) {
      return Curry._1(setUser_, (function (param) {
                    return userObj;
                  }));
    } else {
      return ;
    }
  } else {
    return Curry._1(setUser_, (function (param) {
                  return userObj;
                }));
  }
}

function clearUser(user, setUser_, param) {
  Belt_Option.mapWithDefault(user, undefined, (function (u) {
          Util$YourProjectName.TsaitungApi.post("/auth/logout", "", u.token, /* JsonType */0, undefined).then(function (param) {
                  return Promise.resolve(undefined);
                }).catch(function (err) {
                return Promise.resolve(Logger$YourProjectName.error2("logout error", err));
              });
          
        }));
  Dom_storage.removeItem("tasitungOrderToken", localStorage);
  Dom_storage.removeItem("tasitungOrderUser", localStorage);
  Dom_storage.removeItem("tsaitungAccountStatus", localStorage);
  Dom_storage.removeItem("placeorderInfoIsAcknowledged", localStorage);
  Dom_storage.removeItem("tasitungAccount", localStorage);
  return Curry._1(setUser_, (function (param) {
                
              }));
}

function refreshStatus(user, setUser_, param) {
  if (user !== undefined) {
    return new Promise((function (resolve, param) {
                  return Curry._2(AccountStatus.getUser, user.token, (function (name, accountStatus) {
                                var user_user = {
                                  name: name,
                                  status: accountStatus
                                };
                                if (Caml_obj.caml_notequal(user_user, user.user)) {
                                  Curry._1(setUser_, (function (param) {
                                          return {
                                                  user: user_user,
                                                  token: user.token,
                                                  first_time: user.first_time
                                                };
                                        }));
                                }
                                return resolve(user_user);
                              }));
                }));
  } else {
    return Promise.resolve(undefined);
  }
}

function GlobalContexts(Props) {
  var children = Props.children;
  var match = ReactUtil$YourProjectName.useState(function (param) {
        
      });
  var setUser_ = match[1];
  var user = match[0];
  var setUser$1 = React.useCallback((function (param) {
          return setUser(user, setUser_, param);
        }), [user]);
  var clearUser$1 = React.useCallback((function (param) {
          return clearUser(user, setUser_, param);
        }), [user]);
  Curry._2(AccountStatus.useGet, user, (function (result) {
          if (result !== undefined) {
            return Curry._1(setUser$1, result);
          } else {
            return Curry._1(clearUser$1, undefined);
          }
        }));
  var refreshStatus$1 = React.useCallback((function (param) {
          return refreshStatus(user, setUser_, param);
        }), [user]);
  React.useEffect((function () {
          var serviceWorkerCheck = function (param) {
            return ServiceWorker$YourProjectName.forceReload(undefined);
          };
          window.addEventListener("focus", serviceWorkerCheck);
          return (function (param) {
                    window.removeEventListener("focus", serviceWorkerCheck);
                    
                  });
        }), []);
  return React.createElement(Context_User$YourProjectName.Methods.Provider.make, Curry._3(Context_User$YourProjectName.Methods.Provider.makeProps, {
                  set: setUser$1,
                  clear: clearUser$1,
                  refreshStatus: refreshStatus$1
                }, React.createElement(Context_User$YourProjectName.Provider.make, Curry._3(Context_User$YourProjectName.Provider.makeProps, user, children, undefined)), undefined));
}

var make = GlobalContexts;

exports.AccountStatus = AccountStatus;
exports.setUser = setUser;
exports.clearUser = clearUser;
exports.refreshStatus = refreshStatus;
exports.make = make;
/* AccountStatus Not a pure module */
