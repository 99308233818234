// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Core = require("@material-ui/core");
var Language$YourProjectName = require("../../language/Language.bs.js");
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Onboarding__Utils$YourProjectName = require("./Onboarding__Utils.bs.js");
var Custom__InputFilled$YourProjectName = require("../../utils/Custom/Custom__InputFilled.bs.js");

function convertValidity(_id, vs) {
  var match = vs.valueMissing;
  if (match) {
    return {
            TAG: /* Left */0,
            _0: Language$YourProjectName.userLang === "zh-tw" ? "必填" : "Required"
          };
  }
  switch (_id) {
    case "onboarding-account" :
        if (vs.typeMismatch || vs.patternMismatch) {
          return {
                  TAG: /* Left */0,
                  _0: Language$YourProjectName.userLang === "zh-tw" ? "格式錯誤" : "Format Error"
                };
        } else {
          return {
                  TAG: /* Right */1,
                  _0: ""
                };
        }
    case "onboarding-confirm-password" :
        if (vs.patternMismatch) {
          return {
                  TAG: /* Left */0,
                  _0: Language$YourProjectName.userLang === "zh-tw" ? "密碼不一致" : "Password doesn't match"
                };
        } else {
          return {
                  TAG: /* Right */1,
                  _0: ""
                };
        }
    default:
      if (vs.valid) {
        return {
                TAG: /* Right */1,
                _0: ""
              };
      } else {
        return {
                TAG: /* Left */0,
                _0: ""
              };
      }
  }
}

var Rules = {
  convertValidity: convertValidity
};

var InputFilled = Custom__InputFilled$YourProjectName.M(Rules);

function Onboarding__Page1(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var toNext = Props.toNext;
  return React.createElement(Onboarding__Utils$YourProjectName.PageContainer.make, {
              toNext: toNext,
              title: "createAccount",
              children: (function (param) {
                  var toNextWithGuard = param.toNextWithGuard;
                  var supressError = !param.hasSubmit;
                  var confirmedPasswordPattern = new RegExp("^" + (state.password + "$"));
                  return React.createElement("form", {
                              name: "createAccount"
                            }, React.createElement("div", {
                                  className: "loginFills"
                                }, React.createElement(InputFilled.make, {
                                      label: "mobile",
                                      value: state.account,
                                      onChange: (function (v) {
                                          return Curry._1(setState, (function (st) {
                                                        return {
                                                                account: v,
                                                                password: st.password,
                                                                confirmedPassword: st.confirmedPassword,
                                                                storeName: st.storeName,
                                                                contact: st.contact,
                                                                jobTitle: st.jobTitle
                                                              };
                                                      }));
                                        }),
                                      id_: "onboarding-account",
                                      required: true,
                                      type_: "tel",
                                      placeholder: "ex: 0912345678",
                                      pattern: {
                                        TAG: /* Re */1,
                                        _0: /[0-9]+/
                                      },
                                      supressError: supressError
                                    }), React.createElement(InputFilled.make, {
                                      label: "setpassword",
                                      value: state.password,
                                      onChange: (function (v) {
                                          return Curry._1(setState, (function (st) {
                                                        return {
                                                                account: st.account,
                                                                password: v,
                                                                confirmedPassword: st.confirmedPassword,
                                                                storeName: st.storeName,
                                                                contact: st.contact,
                                                                jobTitle: st.jobTitle
                                                              };
                                                      }));
                                        }),
                                      id_: "onboarding-password",
                                      required: true,
                                      type_: "password",
                                      supressError: supressError
                                    }), React.createElement(InputFilled.make, {
                                      label: "confirmpassword",
                                      value: Rebase.$$Option.getOr("", state.confirmedPassword),
                                      onChange: (function (v) {
                                          return Curry._1(setState, (function (st) {
                                                        return {
                                                                account: st.account,
                                                                password: st.password,
                                                                confirmedPassword: v,
                                                                storeName: st.storeName,
                                                                contact: st.contact,
                                                                jobTitle: st.jobTitle
                                                              };
                                                      }));
                                        }),
                                      id_: "onboarding-confirm-password",
                                      required: true,
                                      type_: "password",
                                      pattern: {
                                        TAG: /* Re */1,
                                        _0: confirmedPasswordPattern
                                      },
                                      supressError: supressError
                                    })), React.createElement("div", {
                                  className: "loginBottom"
                                }, React.createElement(Core.Typography, {
                                      align: "center",
                                      children: React.createElement("a", {
                                            href: "/order/login"
                                          }, React.createElement("u", undefined, React.createElement(LangString$YourProjectName.make, {
                                                    strKey: "login"
                                                  }))),
                                      classes: {
                                        root: "bottomLink"
                                      },
                                      color: "primary",
                                      variant: "body2"
                                    }), React.createElement(Core.Button, {
                                      onClick: (function (param) {
                                          return Curry._1(toNextWithGuard, undefined);
                                        }),
                                      children: React.createElement(LangString$YourProjectName.make, {
                                            strKey: "nextStep"
                                          }),
                                      classes: {
                                        root: "nextButton"
                                      },
                                      color: "secondary",
                                      fullWidth: true,
                                      variant: "contained"
                                    })));
                })
            });
}

var Utils;

var make = Onboarding__Page1;

exports.Utils = Utils;
exports.Rules = Rules;
exports.InputFilled = InputFilled;
exports.make = make;
/* InputFilled Not a pure module */
