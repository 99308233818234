// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Core = require("@material-ui/core");
var Util$YourProjectName = require("../Util.bs.js");
var Styles = require("@material-ui/core/styles");
var Custom__FormValidateWrapper$YourProjectName = require("./Custom__FormValidateWrapper.bs.js");

require('./Custom__CheckBlock.css')
;

var useStyles = Styles.makeStyles({
      formRoot: {
        marginLeft: "0px"
      }
    });

var Styles$1 = {
  useStyles: useStyles
};

function Custom__CheckBlock$Elm(Props) {
  var onChecked = Props.onChecked;
  var t = Props.t;
  var id = t.id;
  var checked = t.checked;
  var onChange = function (e) {
    var c = e.target.checked;
    return Curry._2(onChecked, id, c);
  };
  var styles = Curry._1(useStyles, undefined);
  return React.createElement("li", {
              key: id
            }, React.createElement(Core.FormControlLabel, {
                  classes: {
                    root: styles.formRoot
                  },
                  control: React.createElement(Core.Checkbox, {
                        checked: checked,
                        checkedIcon: React.createElement("img", {
                              src: "/checkbox-checked.svg"
                            }),
                        classes: {
                          root: "checkBlockInput"
                        },
                        icon: React.createElement("img", {
                              src: "/checkbox-normal.svg"
                            }),
                        id: id,
                        onChange: onChange
                      }),
                  label: React.createElement("label", {
                        className: "checkBlockInputLabel",
                        checked: checked,
                        htmlFor: id,
                        name: id
                      }, t.label)
                }));
}

var Elm = {
  make: Custom__CheckBlock$Elm
};

function Custom__CheckBlock(Props) {
  var checklist = Props.checklist;
  var onChecked = Props.onChecked;
  var required = Props.required;
  var error = Props.error;
  var title = Props.title;
  var classNameOpt = Props.className;
  var onValid = Props.onValid;
  var id_ = Props.id_;
  var supressErrorOpt = Props.supressError;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var supressError = supressErrorOpt !== undefined ? supressErrorOpt : false;
  var checkedCount = React.useMemo((function () {
          return List.fold_left((function (acc, t) {
                        if (t.checked) {
                          return acc + 1 | 0;
                        } else {
                          return acc;
                        }
                      }), 0, checklist);
        }), [checklist]);
  var match = React.useContext(Custom__FormValidateWrapper$YourProjectName.Context.context);
  var onValid$p = {
    contents: Rebase.$$Option.getOr(match.onValid, onValid)
  };
  var match$1 = React.useState(function () {
        
      });
  var setInnerError = match$1[1];
  var innerError = match$1[0];
  var match$2 = React.useState(function () {
        return {
                TAG: /* Right */1,
                _0: ""
              };
      });
  var setDisplayError = match$2[1];
  var displayError = match$2[0];
  React.useEffect((function () {
          if (error !== undefined) {
            var e = {
              TAG: /* Left */0,
              _0: error
            };
            Curry._1(setDisplayError, (function (param) {
                    return e;
                  }));
            Curry._2(onValid$p.contents, id_, e);
          } else if (innerError !== undefined) {
            var e$1 = {
              TAG: /* Left */0,
              _0: innerError
            };
            Curry._1(setDisplayError, (function (param) {
                    return e$1;
                  }));
            Curry._2(onValid$p.contents, id_, e$1);
          } else {
            var e$2 = {
              TAG: /* Right */1,
              _0: ""
            };
            Curry._1(setDisplayError, (function (param) {
                    return e$2;
                  }));
            Curry._2(onValid$p.contents, id_, e$2);
          }
          return (function (param) {
                    return Curry._2(onValid$p.contents, id_, {
                                TAG: /* Right */1,
                                _0: ""
                              });
                  });
        }), [
        innerError,
        error
      ]);
  React.useEffect((function () {
          if (required !== undefined) {
            var errorMsg = required[1];
            if (required[0] > checkedCount) {
              Curry._1(setInnerError, (function (param) {
                      return errorMsg;
                    }));
            } else {
              Curry._1(setInnerError, (function (param) {
                      
                    }));
            }
          }
          
        }), [
        checkedCount,
        required
      ]);
  var tmp;
  tmp = displayError.TAG === /* Left */0 ? React.createElement("legend", undefined, displayError._0) : null;
  return React.createElement("ul", {
              className: "checkBlock " + (className + (
                  !Util$YourProjectName.Either.isRight(displayError) && !supressError ? " error" : ""
                ))
            }, React.createElement("fieldset", undefined, tmp), Rebase.$$Option.getOr(null, title), $$Array.of_list(List.mapi((function (index, t) {
                        return React.createElement(Custom__CheckBlock$Elm, {
                                    onChecked: onChecked,
                                    t: t,
                                    key: String(index)
                                  });
                      }), checklist)));
}

var List_;

var Either;

var make = Custom__CheckBlock;

exports.List_ = List_;
exports.Either = Either;
exports.Styles = Styles$1;
exports.Elm = Elm;
exports.make = make;
/*  Not a pure module */
