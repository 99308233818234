// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Eventemitter3 = require("eventemitter3");
var Util$YourProjectName = require("../utils/Util.bs.js");
var EventEmitter3$YourProjectName = require("../utils/EventEmitter/EventEmitter3.bs.js");

var ee = new Eventemitter3.EventEmitter();

var langFetchedEvent = "lang-fetched-event";

function emitLangFetchedEvent(param) {
  ee.emit(langFetchedEvent);
  
}

function listenLangFetchedEvent(handler) {
  EventEmitter3$YourProjectName.on(ee, langFetchedEvent, handler);
  return function (param) {
    ee.off(langFetchedEvent, handler);
    
  };
}

var $$Event = {
  emitLangFetchedEvent: emitLangFetchedEvent,
  listenLangFetchedEvent: listenLangFetchedEvent
};

function decodeManifestKeys(json) {
  return Js_dict.values(Json_decode.field("files", (function (param) {
                      return Json_decode.dict((function (a) {
                                    return a;
                                  }), param);
                    }), json)).map(function (v) {
              return Belt_Option.getWithDefault(Js_json.decodeString(v), "");
            });
}

function decode(v) {
  return Belt_Option.map(Js_json.decodeObject(v), (function (d) {
                return Js_dict.map((function (v) {
                              return Belt_Option.getWithDefault(Js_json.decodeString(v), "");
                            }), d);
              }));
}

function get(lang, cb) {
  var getLangPath = function (param) {
    return fetch("/asset-manifest.json", Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (param) {
                return Util$YourProjectName.Fp.$great$great((function (param) {
                              return Util$YourProjectName.Fp.$great$great((function (prim) {
                                            return prim.json();
                                          }), (function (param) {
                                            return param.then(function (json) {
                                                        var arr = decodeManifestKeys(json);
                                                        var partial_arg = "language/" + lang;
                                                        var index = arr.findIndex(function (param) {
                                                              return param.includes(partial_arg);
                                                            });
                                                        return Promise.resolve(index < 0 ? undefined : Util$YourProjectName.$$Array.get(index, arr));
                                                      });
                                          }), param);
                            }), (function (param) {
                              return param.catch(function (param) {
                                          return Promise.resolve(undefined);
                                        });
                            }), param);
              });
  };
  var getLangJson = function (url) {
    return fetch(Belt_Option.getWithDefault(url, "/language/" + lang + ".json"), Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                    return prim.json();
                  }).then(function (param) {
                  return Util$YourProjectName.Fp.$great$great((function (param) {
                                return Util$YourProjectName.Fp.$great$great(decode, cb, param);
                              }), (function (prim) {
                                return Promise.resolve(prim);
                              }), param);
                }).catch(function (param) {
                return Util$YourProjectName.Fp.$great$great((function (prim) {
                              console.error(prim);
                              
                            }), (function (prim) {
                              return Promise.resolve(prim);
                            }), param);
              });
  };
  getLangPath(undefined).then(getLangJson);
  
}

var Data = {
  decodeManifestKeys: decodeManifestKeys,
  decode: decode,
  get: get
};

var lang = Dom_storage.getItem("tsaitung-lang", localStorage);

var userLang;

if (lang !== undefined) {
  userLang = lang;
} else {
  var lang$1 = "zh-tw";
  Dom_storage.setItem("tsaitung-lang", lang$1, localStorage);
  userLang = lang$1;
}

var data = {
  contents: {}
};

function switchLang(lang) {
  return Dom_storage.setItem("tsaitung-lang", lang, localStorage);
}

get(userLang, (function (newData) {
        if (newData !== undefined) {
          data.contents = Caml_option.valFromOption(newData);
          return emitLangFetchedEvent(undefined);
        }
        
      }));

function useLangString(strKey) {
  return Belt_Option.getWithDefault(Js_dict.get(data.contents, strKey), "");
}

exports.$$Event = $$Event;
exports.Data = Data;
exports.userLang = userLang;
exports.data = data;
exports.switchLang = switchLang;
exports.useLangString = useLangString;
/* ee Not a pure module */
