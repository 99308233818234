// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Language$YourProjectName = require("./Language.bs.js");

function LangString(Props) {
  var strKey = Props.strKey;
  var match = React.useState(function () {
        return "";
      });
  var setV = match[1];
  React.useEffect((function () {
          var handler = function (param) {
            var v = Belt_Option.getWithDefault(Js_dict.get(Language$YourProjectName.data.contents, strKey), "");
            return Curry._1(setV, (function (param) {
                          return v;
                        }));
          };
          handler(undefined);
          return Language$YourProjectName.$$Event.listenLangFetchedEvent(handler);
        }), [strKey]);
  return match[0];
}

var make = LangString;

exports.make = make;
/* react Not a pure module */
