// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Terms = require("./Terms").default;
var Caml_array = require("rescript/lib/js/caml_array.js");
var MaterialUi_Types = require("rescript-material-ui/src/MaterialUi_Types.bs.js");
var Core = require("@material-ui/core");
var Styles = require("@material-ui/core/styles");
var ReactUtil$YourProjectName = require("../../utils/ReactUtil.bs.js");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft").default;
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight").default;

function component(prim) {
  return Terms();
}

var Terms$1 = {
  component: component
};

var useStyles = Styles.makeStyles({
      button: {
        border: "unset",
        color: "white"
      }
    });

var Styles$1 = {
  useStyles: useStyles
};

var KeyboardArrowLeft$1 = {};

var KeyboardArrowRight$1 = {};

function UserAgreeTerms(Props) {
  var open_ = Props.open_;
  var onClickNext = Props.onClickNext;
  var match = ReactUtil$YourProjectName.useState(function (param) {
        return 0;
      });
  var setActiveStep = match[1];
  var activeStep = match[0];
  var handleNext = function (param) {
    return Curry._1(setActiveStep, (function (prevActiveStep) {
                  return prevActiveStep + 1 | 0;
                }));
  };
  var handleBack = function (param) {
    return Curry._1(setActiveStep, (function (prevActiveStep) {
                  return prevActiveStep - 1 | 0;
                }));
  };
  var styles = Curry._1(useStyles, undefined);
  return React.createElement(Core.Slide, {
              children: React.createElement("div", {
                    id: "login-agreement"
                  }, React.createElement(Core.Dialog, {
                        children: null,
                        classes: {
                          paper: "agreementPaper",
                          paperFullScreen: "agreementPaper"
                        },
                        disableBackdropClick: true,
                        disableEscapeKeyDown: true,
                        fullScreen: true,
                        open: open_
                      }, React.createElement(Core.DialogTitle, {
                            children: React.createElement(Core.Typography, {
                                  children: React.createElement("b", {
                                        className: "agreementTitle"
                                      }, "同意條款"),
                                  variant: "h5"
                                }),
                            disableTypography: true
                          }), React.createElement("div", {
                            className: "agreementContent"
                          }, React.createElement("section", {
                                className: "agreementContentText"
                              }, Caml_array.get(Terms(), activeStep))), React.createElement(Core.DialogActions, {
                            children: null,
                            classes: {
                              root: "agreementAction"
                            }
                          }, React.createElement(Core.MobileStepper, {
                                activeStep: MaterialUi_Types.$$Number.$$int(activeStep),
                                backButton: React.createElement(Core.Button, {
                                      onClick: handleBack,
                                      children: null,
                                      disabled: activeStep <= 0,
                                      size: "small"
                                    }, React.createElement(KeyboardArrowLeft, {}), "上一頁"),
                                classes: {
                                  root: "stepper"
                                },
                                nextButton: React.createElement(Core.Button, {
                                      onClick: handleNext,
                                      children: null,
                                      disabled: activeStep >= 5,
                                      size: "small"
                                    }, "下一頁", React.createElement(KeyboardArrowRight, {})),
                                position: "static",
                                steps: MaterialUi_Types.$$Number.$$int(6),
                                variant: "dots"
                              }), React.createElement(Core.Button, {
                                onClick: onClickNext,
                                children: "關閉",
                                classes: {
                                  root: styles.button
                                },
                                color: "primary",
                                fullWidth: true,
                                variant: "contained"
                              })))),
              direction: "up",
              in: open_
            });
}

var make = UserAgreeTerms;

exports.Terms = Terms$1;
exports.Styles = Styles$1;
exports.KeyboardArrowLeft = KeyboardArrowLeft$1;
exports.KeyboardArrowRight = KeyboardArrowRight$1;
exports.make = make;
/* useStyles Not a pure module */
