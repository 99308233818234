// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Set = require("rescript/lib/js/set.js");
var List = require("rescript/lib/js/list.js");
var Sort = require("rescript/lib/js/sort.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var MomentTz = require("bs-moment-timezone/src/MomentTz.bs.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Browser = require("@sentry/browser");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var MomentTimezone = require("moment-timezone");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Logger$YourProjectName = require("./Logger.bs.js");

var lang = Dom_storage.getItem("tsaitung-lang", localStorage);

var lang$1 = lang !== undefined ? lang : "zh-tw";

var ResponseError = /* @__PURE__ */Caml_exceptions.create("Util-YourProjectName.TsaitungApi.ResponseError");

function post(url, body, withToken, contentType, param) {
  return fetch(process.env.REACT_APP_API_URL + url, Fetch.RequestInit.make(/* Post */2, Caml_option.some(Belt_Option.mapWithDefault(withToken, {}, (function (token) {
                                return Js_dict.fromArray($$Array.append([
                                                [
                                                  "Authorization",
                                                  "Bearer " + (process.env.REACT_APP_BEAR_PREFIX + ("-" + token))
                                                ],
                                                [
                                                  "Accept-Language",
                                                  lang$1
                                                ]
                                              ], contentType === /* JsonType */0 ? [[
                                                    "Content-Type",
                                                    "application/json"
                                                  ]] : []));
                              }))), Caml_option.some(body), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (res) {
              if (Belt_Option.isNone(withToken) || res.status !== 401) {
                return Promise.resolve(res);
              } else {
                RescriptReactRouter.replace("/order/logout");
                return Promise.resolve(res);
              }
            });
}

function get(url, token, param) {
  return fetch(process.env.REACT_APP_API_URL + url, Fetch.RequestInit.make(/* Get */0, {
                      Authorization: "Bearer " + (process.env.REACT_APP_BEAR_PREFIX + ("-" + token)),
                      "Accept-Language": lang$1
                    }, undefined, undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (res) {
              if (res.status !== 401) {
                return Promise.resolve(res);
              } else {
                RescriptReactRouter.replace("/order/logout");
                return Promise.resolve(res);
              }
            });
}

function put(url, token, body, param) {
  return fetch(process.env.REACT_APP_API_URL + url, Fetch.RequestInit.make(/* Put */3, {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + (process.env.REACT_APP_BEAR_PREFIX + ("-" + token))
                    }, Caml_option.some(body), undefined, undefined, /* CORS */3, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (res) {
              if (res.status !== 401) {
                return Promise.resolve(res);
              } else {
                RescriptReactRouter.replace("/order/logout");
                return Promise.resolve(res);
              }
            });
}

function makeResErr(res) {
  return res.json().then(function (jsonObj) {
                  return Promise.resolve(JSON.stringify(jsonObj));
                }).catch(function (param) {
                return Promise.resolve("{}");
              }).then(function (jsonString) {
              try {
                throw {
                      RE_EXN_ID: ResponseError,
                      _1: String(res.status) + (" " + (res.type + (" " + (res.statusText + (": \n" + jsonString))))),
                      Error: new Error()
                    };
              }
              catch (raw_exn){
                return Promise.reject(Caml_js_exceptions.internalToOCamlException(raw_exn));
              }
            });
}

var TsaitungApi = {
  lang: lang$1,
  ResponseError: ResponseError,
  post: post,
  get: get,
  put: put,
  makeResErr: makeResErr
};

var Result = Belt_Result;

function isCancelled(je) {
  switch (je.TAG | 0) {
    case /* JsPromiseError */0 :
        return Caml_obj.caml_equal(je._0.message, "cancelled");
    case /* Message */1 :
    case /* DecodeError */2 :
        return false;
    
  }
}

function toMessage(str) {
  return {
          TAG: /* Error */1,
          _0: {
            TAG: /* Message */1,
            _0: str
          }
        };
}

function toReasonPromise(pRes) {
  return $$Promise.mapError($$Promise.Js.toResult($$Promise.Js.fromBsPromise(pRes)), (function (e) {
                return {
                        TAG: /* JsPromiseError */0,
                        _0: e
                      };
              }));
}

function ok(prim) {
  return prim.ok;
}

function status(prim) {
  return prim.status;
}

function json(res) {
  return $$Promise.mapError($$Promise.Js.toResult($$Promise.Js.fromBsPromise(res.json())), (function (e) {
                return {
                        TAG: /* JsPromiseError */0,
                        _0: e
                      };
              }));
}

function timeDelay(limitOpt, param) {
  var limit = limitOpt !== undefined ? limitOpt : 500;
  var match = $$Promise.pending(undefined);
  var resolve = match[1];
  setTimeout((function (param) {
          return Curry._1(resolve, /* Timeout */0);
        }), limit);
  return match[0];
}

function timeoutWrapper(limitOpt, p) {
  var limit = limitOpt !== undefined ? limitOpt : 500;
  return $$Promise.race({
              hd: timeDelay(limit, undefined),
              tl: {
                hd: $$Promise.map(p, (function (r) {
                        return /* Resolved */{
                                _0: r
                              };
                      })),
                tl: /* [] */0
              }
            });
}

function handleErrorBySentry(p) {
  switch (p.TAG | 0) {
    case /* JsPromiseError */0 :
        Browser.captureException(p._0);
        return ;
    case /* Message */1 :
    case /* DecodeError */2 :
        Browser.captureMessage(p._0);
        return ;
    
  }
}

var ReasonPromise = {
  F: undefined,
  JsPromise: undefined,
  GlobalJs: undefined,
  pending: $$Promise.pending,
  resolved: $$Promise.resolved,
  exec: $$Promise.exec,
  get: $$Promise.get,
  tap: $$Promise.tap,
  map: $$Promise.map,
  flatMap: $$Promise.flatMap,
  getOk: $$Promise.getOk,
  tapOk: $$Promise.tapOk,
  mapOk: $$Promise.mapOk,
  flatMapOk: $$Promise.flatMapOk,
  getError: $$Promise.getError,
  tapError: $$Promise.tapError,
  mapError: $$Promise.mapError,
  flatMapError: $$Promise.flatMapError,
  Operators: $$Promise.Operators,
  getSome: $$Promise.getSome,
  tapSome: $$Promise.tapSome,
  mapSome: $$Promise.mapSome,
  flatMapSome: $$Promise.flatMapSome,
  race: $$Promise.race,
  all: $$Promise.all,
  allArray: $$Promise.allArray,
  all2: $$Promise.all2,
  all3: $$Promise.all3,
  all4: $$Promise.all4,
  all5: $$Promise.all5,
  all6: $$Promise.all6,
  allOk: $$Promise.allOk,
  allOkArray: $$Promise.allOkArray,
  allOk2: $$Promise.allOk2,
  allOk3: $$Promise.allOk3,
  allOk4: $$Promise.allOk4,
  allOk5: $$Promise.allOk5,
  allOk6: $$Promise.allOk6,
  Js: $$Promise.Js,
  PipeFirst: $$Promise.PipeFirst,
  onUnhandledException: $$Promise.onUnhandledException,
  Result: Result,
  isCancelled: isCancelled,
  toMessage: toMessage,
  toReasonPromise: toReasonPromise,
  ok: ok,
  status: status,
  json: json,
  timeDelay: timeDelay,
  timeoutWrapper: timeoutWrapper,
  handleErrorBySentry: handleErrorBySentry
};

function $great$great$eq(a, f) {
  if (a !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(a));
  }
  
}

function $$return(a) {
  return Caml_option.some(a);
}

function mapTuple(a, b) {
  if (a !== undefined && b !== undefined) {
    return [
            Caml_option.valFromOption(a),
            Caml_option.valFromOption(b)
          ];
  }
  
}

function flatMapTwo(a, b, f) {
  if (a !== undefined && b !== undefined) {
    return Curry._2(f, Caml_option.valFromOption(a), Caml_option.valFromOption(b));
  }
  
}

var $$Option = {
  $great$great$eq: $great$great$eq,
  $$return: $$return,
  mapTuple: mapTuple,
  flatMapTwo: flatMapTwo,
  let_: Belt_Option.flatMap
};

function has(findResult) {
  try {
    Curry._1(findResult, undefined);
    return true;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      return false;
    }
    throw exn;
  }
}

var StringSet = $$Set.Make({
      compare: $$String.compare
    });

var $$Set$1 = {
  has: has,
  StringSet: StringSet
};

function compress(cmp, list) {
  var sortedXs = Sort.list(cmp, list);
  var f = function (_xs) {
    while(true) {
      var xs = _xs;
      if (!xs) {
        return /* [] */0;
      }
      var xss = xs.tl;
      var s = xs.hd;
      if (!xss) {
        return {
                hd: s,
                tl: /* [] */0
              };
      }
      if (xss.tl) {
        if (!Caml_obj.caml_equal(s, List.hd(xss))) {
          return {
                  hd: s,
                  tl: f(xss)
                };
        }
        _xs = xss;
        continue ;
      }
      var s2 = xss.hd;
      if (Caml_obj.caml_equal(s, s2)) {
        return {
                hd: s2,
                tl: /* [] */0
              };
      } else {
        return {
                hd: s,
                tl: {
                  hd: s2,
                  tl: /* [] */0
                }
              };
      }
    };
  };
  return f(sortedXs);
}

function pullAt(indexes, list) {
  var indexSorted = {
    contents: compress((function (i1, i2) {
            return i1 <= i2;
          }), indexes)
  };
  var result = {
    contents: /* [] */0
  };
  var removed = {
    contents: /* [] */0
  };
  List.iteri((function (i, a) {
          if (List.length(indexSorted.contents) > 0 && i === List.hd(indexSorted.contents)) {
            indexSorted.contents = List.tl(indexSorted.contents);
            removed.contents = {
              hd: a,
              tl: removed.contents
            };
          } else {
            result.contents = {
              hd: a,
              tl: result.contents
            };
          }
          
        }), list);
  return [
          List.rev(result.contents),
          List.rev(removed.contents)
        ];
}

function hd(xs) {
  var x;
  try {
    x = List.hd(xs);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Failure") {
      return ;
    }
    throw exn;
  }
  return Caml_option.some(x);
}

function find(f, list) {
  var x;
  try {
    x = List.find(f, list);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      return ;
    }
    throw exn;
  }
  return Caml_option.some(x);
}

function assoc(key, list) {
  var x;
  try {
    x = List.assoc(key, list);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Not_found") {
      return ;
    }
    throw exn;
  }
  return Caml_option.some(x);
}

function last(_list) {
  while(true) {
    var list = _list;
    if (!list) {
      return ;
    }
    var xs = list.tl;
    if (!xs) {
      return Caml_option.some(list.hd);
    }
    _list = xs;
    continue ;
  };
}

function updateNth(f, index, list) {
  var match = index === 0;
  if (list) {
    var vs = list.tl;
    var v = list.hd;
    if (match) {
      return {
              hd: Curry._1(f, v),
              tl: vs
            };
    } else {
      return {
              hd: v,
              tl: updateNth(f, index - 1 | 0, vs)
            };
    }
  }
  throw {
        RE_EXN_ID: "Invalid_argument",
        _1: "index out of bounds",
        Error: new Error()
      };
}

var List$1 = {
  compress: compress,
  pullAt: pullAt,
  hd: hd,
  find: find,
  assoc: assoc,
  last: last,
  updateNth: updateNth
};

var Invalid = /* @__PURE__ */Caml_exceptions.create("Util-YourProjectName.LinkedList.Invalid");

function next(t) {
  return t.next;
}

function value(t) {
  return t.value;
}

function forEach(f, _param) {
  while(true) {
    var param = _param;
    var t = param.next;
    if (t === undefined) {
      return Curry._1(f, param.value);
    }
    Curry._1(f, param.value);
    _param = t;
    continue ;
  };
}

function make(v) {
  return {
          next: undefined,
          value: v
        };
}

function push(v, t) {
  return {
          next: t,
          value: v
        };
}

function append(v, t) {
  var n = t.next;
  return {
          next: n !== undefined ? append(v, n) : ({
                next: undefined,
                value: v
              }),
          value: t.value
        };
}

function fromArray(xs) {
  if (xs.length !== 0) {
    var l = xs.length;
    var result = {
      next: undefined,
      value: Caml_array.get(xs, 0)
    };
    for(var i = l - 1 | 0; i >= 1; --i){
      result = {
        next: result,
        value: Caml_array.get(xs, i)
      };
    }
    return result;
  }
  throw {
        RE_EXN_ID: Invalid,
        _1: "cannot init with empty array",
        Error: new Error()
      };
}

function toArray(ts) {
  var result = [];
  var f = function (_t) {
    while(true) {
      var t = _t;
      result.push(t.value);
      var next = t.next;
      if (next === undefined) {
        return ;
      }
      _t = next;
      continue ;
    };
  };
  f(ts);
  return result;
}

var LinkedList = {
  next: next,
  value: value,
  forEach: forEach,
  make: make,
  push: push,
  append: append,
  fromArray: fromArray,
  toArray: toArray
};

var back = (function (dispatch) {
      window.history.back()
      if(dispatch) {
        window.dispatchEvent(new Event('popstate'))
      }
    });

function listPathToString(ls) {
  return "/" + $$Array.of_list(ls).join("/");
}

var Route = {
  back: back,
  listPathToString: listPathToString
};

function getTimeNow(param) {
  var func = MomentTz.Moment.add;
  var arg = MomentTimezone.tz(MomentTimezone().format(), "Asia/Taipei");
  var param$1 = MomentTimezone.duration(12, "hours");
  return Curry._2(func, param$1, arg);
}

var Time = {
  getTimeNow: getTimeNow
};

var isInteger = (function(str) {
    return Number.isInteger(parseFloat(str))
  });

var $$parseInt = (function(str) {
      let f = parseFloat(str);
      return Number.isInteger(f) ? f : undefined
  });

function $$parseFloat(str) {
  var parsed = Number(str);
  if (Number.isNaN(parsed)) {
    return ;
  } else {
    return parsed;
  }
}

var $$Number$1 = {
  isInteger: isInteger,
  $$parseInt: $$parseInt,
  $$parseFloat: $$parseFloat
};

var Wrong_date_format = /* @__PURE__ */Caml_exceptions.create("Util-YourProjectName.Date.Wrong_date_format");

var fullDateStringFormat = /\d\d\d\d-(\d\d)-(\d\d)/;

function isFullDateStringFormat(str) {
  var arr = str.match(fullDateStringFormat);
  if (arr !== null) {
    return arr;
  }
  throw {
        RE_EXN_ID: Wrong_date_format,
        _1: str,
        Error: new Error()
      };
}

function isFullDateStringFormat_(str) {
  var val;
  try {
    val = isFullDateStringFormat(str);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Wrong_date_format) {
      return ;
    }
    throw exn;
  }
  return str;
}

function toPartial(str) {
  var arr = isFullDateStringFormat(str);
  var get = function (a, ass) {
    try {
      return Caml_array.get(a, ass);
    }
    catch (exn){
      return "";
    }
  };
  var arr1 = get(arr, 1);
  var arr2 = get(arr, 2);
  return arr1 + ("/" + arr2);
}

function getEndOfNextMonth(dateString) {
  isFullDateStringFormat(dateString);
  var endDate = MomentTz.Moment.add(MomentTimezone.duration(1, "months"), MomentTimezone(dateString, "YYYY-MM-DD"));
  endDate.endOf("month");
  return endDate.format("YYYY-MM-DD");
}

function toFullDateString(d) {
  return MomentTimezone(d).format("YYYY-MM-DD");
}

function diffDays(format, d1, d2) {
  return MomentTimezone(d2, format).diff(MomentTimezone(d1, format), "days");
}

function getTomorrow(toDay) {
  return MomentTz.Moment.add(MomentTimezone.duration(1, "days"), MomentTimezone(toDay, "YYYY-MM-DD")).format("YYYY-MM-DD");
}

function getYesterDay(toDay) {
  return MomentTz.Moment.add(MomentTimezone.duration(-1, "days"), MomentTimezone(toDay, "YYYY-MM-DD")).format("YYYY-MM-DD");
}

function isYesterday(dateString, toDay) {
  return getTomorrow(dateString) === toDay;
}

function getFstDayOfMonth(dateString) {
  return MomentTz.Moment.startOf("month", MomentTimezone(dateString, "YYYY-MM-DD")).format("YYYY-MM-DD");
}

var $$Date = {
  Wrong_date_format: Wrong_date_format,
  fullDateStringFormat: fullDateStringFormat,
  isFullDateStringFormat: isFullDateStringFormat,
  isFullDateStringFormat_: isFullDateStringFormat_,
  toPartial: toPartial,
  getEndOfNextMonth: getEndOfNextMonth,
  toFullDateString: toFullDateString,
  diffDays: diffDays,
  getTomorrow: getTomorrow,
  getYesterDay: getYesterDay,
  isYesterday: isYesterday,
  getFstDayOfMonth: getFstDayOfMonth
};

function get$1(index, array) {
  var x;
  try {
    x = Caml_array.get(array, index);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Invalid_argument") {
      return ;
    }
    throw exn;
  }
  return Caml_option.some(x);
}

function splitHd(arr) {
  return [
          Caml_array.get(arr, 0),
          arr.slice(1)
        ];
}

function removeAllWith(f, _arr) {
  while(true) {
    var arr = _arr;
    if (arr.length === 0) {
      return [];
    }
    var match = splitHd(arr);
    var tail = match[1];
    var head = match[0];
    if (!Curry._1(f, head)) {
      return $$Array.concat({
                  hd: [head],
                  tl: {
                    hd: removeAllWith(f, tail),
                    tl: /* [] */0
                  }
                });
    }
    _arr = tail;
    continue ;
  };
}

function hd$1(arr) {
  return get$1(0, arr);
}

function tl(arr) {
  return arr.slice(1);
}

function shallowCopy(arr) {
  return arr.map(function (a) {
              return a;
            });
}

function findIndex(f, arr) {
  var i = arr.findIndex(Curry.__1(f));
  if (i !== -1 && i < arr.length) {
    return i;
  }
  
}

function toggle(v, arr) {
  if (arr.length === 0) {
    return [v];
  }
  var tl = arr.slice(1);
  if (Caml_obj.caml_equal(Caml_array.get(arr, 0), v)) {
    return tl;
  } else {
    return [Caml_array.get(arr, 0)].concat(toggle(v, tl));
  }
}

function shallow(arr) {
  return arr.map(function (a) {
              return a;
            });
}

function update(i, f, arr) {
  var arr$1 = arr.map(function (a) {
        return a;
      });
  Caml_array.set(arr$1, i, Curry._1(f, Caml_array.get(arr$1, i)));
  return arr$1;
}

function updateWith(condition, f, xs) {
  if (xs.length === 0) {
    return [];
  }
  var match = splitHd(xs);
  var hd = match[0];
  return [Curry._1(condition, hd) ? Curry._1(f, hd) : hd].concat(updateWith(condition, f, match[1]));
}

var $$Array$1 = {
  get: get$1,
  splitHd: splitHd,
  removeAllWith: removeAllWith,
  hd: hd$1,
  tl: tl,
  shallowCopy: shallowCopy,
  findIndex: findIndex,
  toggle: toggle,
  shallow: shallow,
  update: update,
  updateWith: updateWith
};

function $great$great(f, g, a) {
  return Curry._1(g, Curry._1(f, a));
}

function compare(t1, t2, _f) {
  while(true) {
    var f = _f;
    if (f.TAG === /* Done */0) {
      return f._0;
    }
    _f = Curry._2(f._0, t1, t2);
    continue ;
  };
}

function $question$question(setState, value) {
  return Curry._1(setState, (function (param) {
                return value;
              }));
}

function returnCompare(t) {
  if (t.TAG === /* Done */0) {
    return t._0;
  } else {
    return 0;
  }
}

var Fp = {
  $great$great: $great$great,
  compare: compare,
  $question$question: $question$question,
  returnCompare: returnCompare
};

function compare2By(f, next, t1, t2) {
  var i = Curry._2(f, t1, t2);
  if (i !== 0) {
    return {
            TAG: /* Done */0,
            _0: i
          };
  } else if (next !== undefined) {
    return Curry._2(next, t1, t2);
  } else {
    return {
            TAG: /* Done */0,
            _0: 0
          };
  }
}

function compare2(fnFst, fnSnd, tuple1, tuple2) {
  var x = Curry._2(fnFst, tuple1[0], tuple2[0]);
  if (x !== 0) {
    return x;
  } else {
    return Curry._2(fnSnd, tuple1[1], tuple2[1]);
  }
}

function compare3(fnFst, fnSnd, fnTrd, tuple1, tuple2) {
  var x = Curry._2(fnFst, tuple1[0], tuple2[0]);
  if (x !== 0) {
    return x;
  }
  var y = Curry._2(fnSnd, tuple1[1], tuple2[1]);
  if (y !== 0) {
    return y;
  } else {
    return Curry._2(fnTrd, tuple1[2], tuple2[2]);
  }
}

function map(f, param) {
  return Curry._2(f, param[0], param[1]);
}

function mapTo(f, param) {
  return [
          Curry._1(f, param[0]),
          Curry._1(f, param[1])
        ];
}

function fst(param) {
  return param[0];
}

function snd(param) {
  return param[1];
}

function trd(param) {
  return param[2];
}

var Tripler = {
  fst: fst,
  snd: snd,
  trd: trd
};

var Tuple = {
  compare2By: compare2By,
  compare2: compare2,
  compare3: compare3,
  map: map,
  mapTo: mapTo,
  Tripler: Tripler
};

function tryWithHandle(cb, a) {
  try {
    return a;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    var eMsg = Rebase.$$Option.getOr("unknown error", Rebase.$$Option.flatMap((function (prim) {
                return prim.message;
              }), Caml_js_exceptions.caml_as_js_exn(exn)));
    Rebase.$$Option.mapOr((function (cb$p) {
            return Curry._1(cb$p, exn);
          }), Logger$YourProjectName.error(eMsg), cb);
    Browser.captureException(Caml_js_exceptions.caml_as_js_exn(exn));
    return ;
  }
}

var Exp = {
  tryWithHandle: tryWithHandle
};

function $$process(f, arr) {
  var _xs = arr;
  while(true) {
    var xs = _xs;
    var x = get$1(0, xs);
    if (x === undefined) {
      return ;
    }
    Curry._1(f, Caml_option.valFromOption(x));
    _xs = xs.slice(1);
    continue ;
  };
}

function useWorker(cb) {
  var match = React.useState(function () {
        return [];
      });
  var setChannel = match[1];
  var push = React.useCallback((function (a) {
          return Curry._1(setChannel, (function (arr) {
                        return arr.concat([a]);
                      }));
        }), []);
  React.useEffect((function () {
          Curry._1(setChannel, (function (ch) {
                  if (ch.length !== 0) {
                    $$process(cb, ch);
                    return [];
                  } else {
                    return ch;
                  }
                }));
          
        }), [match[0]]);
  return push;
}

var Concurrency = {
  $$process: $$process,
  useWorker: useWorker
};

function shallow$1(obj) {
  return Object.assign({}, obj);
}

var $$Object = {
  shallow: shallow$1
};

function reReject(err) {
  throw err;
  return Promise.reject(undefined);
}

function timeDelay$1(intervalOpt, param) {
  var interval = intervalOpt !== undefined ? intervalOpt : 500;
  return new Promise((function (resolve, param) {
                  setTimeout((function (param) {
                          resolve(undefined);
                          
                        }), interval);
                  
                })).then(function (param) {
              return Promise.resolve(undefined);
            });
}

var Promise_Timeout = /* @__PURE__ */Caml_exceptions.create("Util-YourProjectName.Promise.Promise_Timeout");

function timeout(limit) {
  return timeDelay$1(limit, undefined).then(function (param) {
              return Promise.resolve(/* Timeout */0);
            });
}

function timeoutWrapper$1(cb, limit, promise) {
  return Promise.race([
                promise.then(function (a) {
                      return Promise.resolve(/* Resolved */{
                                  _0: a
                                });
                    }),
                timeout(limit)
              ]).then(function (result) {
              if (result) {
                return Promise.resolve(result._0);
              }
              Curry._1(cb, undefined);
              throw {
                    RE_EXN_ID: Promise_Timeout,
                    _1: limit,
                    Error: new Error()
                  };
              return Promise.reject(undefined);
            });
}

function timeoutWrapperNoReturn(cb, limit, promise) {
  return Promise.race([
                promise.then(function (a) {
                      return Promise.resolve(/* Resolved */{
                                  _0: a
                                });
                    }),
                timeout(limit)
              ]).then(function (result) {
              if (result) {
                return Promise.resolve(undefined);
              } else {
                Belt_Option.forEach(cb, (function (cb$p) {
                        return Curry._1(cb$p, undefined);
                      }));
                return Promise.resolve(undefined);
              }
            });
}

function simpleTimeoutWrapper(limit, promise) {
  return Promise.race([
              promise.then(function (a) {
                    return Promise.resolve(/* Resolved */{
                                _0: a
                              });
                  }),
              timeout(limit)
            ]);
}

function map$1(f, ma) {
  return ma.then(function (x) {
              return Promise.resolve(Curry._1(f, x));
            });
}

var $$Promise$1 = {
  reReject: reReject,
  timeDelay: timeDelay$1,
  Promise_Timeout: Promise_Timeout,
  timeout: timeout,
  timeoutWrapper: timeoutWrapper$1,
  timeoutWrapperNoReturn: timeoutWrapperNoReturn,
  simpleTimeoutWrapper: simpleTimeoutWrapper,
  map: map$1
};

function isRight(either) {
  if (either.TAG === /* Left */0) {
    return false;
  } else {
    return true;
  }
}

var Either = {
  isRight: isRight
};

function format(roundOpt, t) {
  var round = roundOpt !== undefined ? roundOpt : false;
  var toString = function (v) {
    return (
              round ? Math.round(v) : v
            ).toString();
  };
  if (t >= 1000000000) {
    var s = toString(t / 1000000000);
    return s + "GB";
  }
  if (t >= 1000000) {
    var s$1 = toString(t / 1000000);
    return s$1 + "MB";
  }
  if (t < 1000) {
    return toString(t) + "bytes";
  }
  var s$2 = toString(t / 1000);
  return s$2 + "KB";
}

var Size = {
  format: format
};

function parse(string) {
  var uri = decodeURI(string);
  return uri.split("&").reduce((function (acc, param) {
                var found = param.match(/(.*)=(.*)/);
                if (found === null) {
                  return acc;
                }
                if (found.length !== 3) {
                  return acc;
                }
                var label = found[1];
                var value = found[2];
                return {
                        hd: [
                          label,
                          value
                        ],
                        tl: acc
                      };
              }), /* [] */0);
}

var QueryString = {
  parse: parse
};

var times = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00"
];

var Times = {
  times: times
};

var JsDate = {};

function isError(v) {
  if (v.TAG === /* Ok */0) {
    return false;
  } else {
    return true;
  }
}

function isOk(v) {
  if (v.TAG === /* Ok */0) {
    return true;
  } else {
    return false;
  }
}

function mapOk(v, f) {
  if (v.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: Curry._1(f, v._0)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: v._0
          };
  }
}

function mapError(v, f) {
  if (v.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: v._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Curry._1(f, v._0)
          };
  }
}

function flatMapOk(v, f) {
  if (v.TAG === /* Ok */0) {
    return Curry._1(f, v._0);
  } else {
    return {
            TAG: /* Error */1,
            _0: v._0
          };
  }
}

function flatMapError(v, f) {
  if (v.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: v._0
          };
  } else {
    return Curry._1(f, v._0);
  }
}

function tapError(v, f) {
  if (v.TAG !== /* Ok */0) {
    Curry._1(f, v._0);
  }
  return v;
}

var NotOk = /* @__PURE__ */Caml_exceptions.create("Util-YourProjectName.Result.NotOk");

function unsafeUnwrapOk(v) {
  if (v.TAG === /* Ok */0) {
    return v._0;
  }
  throw {
        RE_EXN_ID: NotOk,
        Error: new Error()
      };
}

var Result$1 = {
  isError: isError,
  isOk: isOk,
  mapOk: mapOk,
  mapError: mapError,
  flatMapOk: flatMapOk,
  flatMapError: flatMapError,
  tapError: tapError,
  NotOk: NotOk,
  unsafeUnwrapOk: unsafeUnwrapOk
};

exports.TsaitungApi = TsaitungApi;
exports.ReasonPromise = ReasonPromise;
exports.$$Option = $$Option;
exports.$$Set = $$Set$1;
exports.List = List$1;
exports.LinkedList = LinkedList;
exports.Route = Route;
exports.Time = Time;
exports.$$Number = $$Number$1;
exports.$$Date = $$Date;
exports.$$Array = $$Array$1;
exports.Fp = Fp;
exports.Tuple = Tuple;
exports.Exp = Exp;
exports.Concurrency = Concurrency;
exports.$$Object = $$Object;
exports.$$Promise = $$Promise$1;
exports.Either = Either;
exports.Size = Size;
exports.QueryString = QueryString;
exports.Times = Times;
exports.JsDate = JsDate;
exports.Result = Result$1;
/* lang Not a pure module */
