// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

var pushError = (function(str, a) {
    let dL = window.dataLayer;
    if(dL && dL.push) {
      dL.push({"event": "error_logger", "action": str, "obj": a})
    }
  });

var DataLayer = {
  pushError: pushError
};

function f(s) {
  console.error(s);
  
}

var $$typeof = (function(a) {
  return typeof a
});

var replaceErrors = (function (key, value) {
    if (value instanceof Error) {
      var error = {};

      Object.getOwnPropertyNames(value).forEach(function (key) {
          error[key] = value[key];
      });

      return error;
    }

    return value;
  });

function toString(o) {
  try {
    return JSON.stringify(o, replaceErrors, undefined);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Js_exn.$$Error) {
      return o;
    }
    throw exn;
  }
}

function error(a) {
  pushError(a, undefined);
  console.error(a);
  
}

function error2(a1, a2) {
  pushError(a1, toString(a2));
  console.error(a1);
  var s = Belt_Option.mapWithDefault(JSON.stringify(a2), "", (function (s) {
          return s;
        }));
  console.error(s);
  
}

exports.DataLayer = DataLayer;
exports.f = f;
exports.$$typeof = $$typeof;
exports.replaceErrors = replaceErrors;
exports.toString = toString;
exports.error = error;
exports.error2 = error2;
/* No side effect */
