// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Core = require("@material-ui/core");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");
var ReactSwipeableViews = require("react-swipeable-views").default;
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Account_Data$YourProjectName = require("../Account/Account_Data.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");
var Custom__Stepper$YourProjectName = require("../../utils/Custom/Custom__Stepper.bs.js");
var Landing_Template$YourProjectName = require("../Landing/Landing_Template.bs.js");
var Onboarding__Page1$YourProjectName = require("./Onboarding__Page1.bs.js");
var Onboarding__Page2$YourProjectName = require("./Onboarding__Page2.bs.js");
var Onboarding__Utils$YourProjectName = require("./Onboarding__Utils.bs.js");

require('./Onboarding.css')
;

function inputErrorToString(err) {
  if (typeof err === "number") {
    if (err !== 0) {
      return "Required";
    } else {
      return "格式不符";
    }
  } else {
    return err._0;
  }
}

function M(UtilApi) {
  var Api = Onboarding__Utils$YourProjectName.Api(UtilApi);
  var Onboarding$M = function (Props) {
    var match = React.useState(function () {
          return Account_Data$YourProjectName.initBasic;
        });
    var setState = match[1];
    var state = match[0];
    var match$1 = React.useState(function () {
          return Custom__Stepper$YourProjectName.createStepper(2);
        });
    var setStep = match$1[1];
    var step = match$1[0];
    var url = RescriptReactRouter.useUrl(undefined, undefined);
    var match$2 = url.path;
    var visitMemberId;
    if (match$2 && match$2.hd === "order") {
      var match$3 = match$2.tl;
      visitMemberId = match$3 && match$3.hd === "onboarding" && !match$3.tl ? Rebase.$$Option.getOr("", Caml_option.nullable_to_opt(new URLSearchParams(url.search).get("visit_member_id"))) : "";
    } else {
      visitMemberId = "";
    }
    var match$4 = React.useContext(Context_User$YourProjectName.Methods.context);
    var setUser = match$4.set;
    var match$5 = Curry._2(Api.useSubmit, state, visitMemberId);
    var submit = match$5[0];
    var toSubmit = function (param) {
      return Curry._1(submit, (function (tokenJson) {
                    var token = Json_decode.field("token", Json_decode.string, tokenJson);
                    Curry._1(setUser, {
                          user: {
                            name: state.contact,
                            status: "Unverified"
                          },
                          token: token,
                          first_time: true
                        });
                    return RescriptReactRouter.push("/order/dashboard/session/items/supply");
                  }));
    };
    return React.createElement("div", {
                id: "onboarding"
              }, match$5[1], React.createElement(Landing_Template$YourProjectName.make, {
                    children: null
                  }, React.createElement(Core.Typography, {
                        align: "center",
                        children: React.createElement(LangString$YourProjectName.make, {
                              strKey: "welcome"
                            }),
                        classes: {
                          root: "boldTitle"
                        },
                        variant: "h5"
                      }), React.createElement(Custom__Stepper$YourProjectName.make, Custom__Stepper$YourProjectName.makeProps(step, undefined, undefined, undefined, undefined)), React.createElement(ReactSwipeableViews, {
                        cancelable: "true",
                        index: Custom__Stepper$YourProjectName.get(step) - 1 | 0,
                        onChangeIndex: (function (v) {
                            return Curry._1(setStep, (function (st) {
                                          return Custom__Stepper$YourProjectName.toNthStep(v + 1 | 0, st);
                                        }));
                          }),
                        axis: "x",
                        disabled: Custom__Stepper$YourProjectName.get(step) < 2,
                        className: "contentForm",
                        children: null
                      }, React.createElement(Onboarding__Page1$YourProjectName.make, {
                            state: state,
                            setState: setState,
                            toNext: (function (param) {
                                return Curry._1(setStep, (function (param) {
                                              return Custom__Stepper$YourProjectName.toNextStep(1, param);
                                            }));
                              })
                          }), React.createElement(Onboarding__Page2$YourProjectName.make, {
                            state: state,
                            setState: setState,
                            toNext: toSubmit
                          }))));
  };
  return {
          Api: Api,
          make: Onboarding$M
        };
}

var UtilApi_post = Util$YourProjectName.TsaitungApi.post;

var UtilApi_get = Util$YourProjectName.TsaitungApi.get;

var UtilApi_put = Util$YourProjectName.TsaitungApi.put;

var UtilApi_ResponseError = Util$YourProjectName.TsaitungApi.ResponseError;

var UtilApi_makeResErr = Util$YourProjectName.TsaitungApi.makeResErr;

var UtilApi = {
  post: UtilApi_post,
  get: UtilApi_get,
  put: UtilApi_put,
  ResponseError: UtilApi_ResponseError,
  makeResErr: UtilApi_makeResErr
};

var Api = Onboarding__Utils$YourProjectName.Api(UtilApi);

function Onboarding$M(Props) {
  var match = React.useState(function () {
        return Account_Data$YourProjectName.initBasic;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return Custom__Stepper$YourProjectName.createStepper(2);
      });
  var setStep = match$1[1];
  var step = match$1[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$2 = url.path;
  var visitMemberId;
  if (match$2 && match$2.hd === "order") {
    var match$3 = match$2.tl;
    visitMemberId = match$3 && match$3.hd === "onboarding" && !match$3.tl ? Rebase.$$Option.getOr("", Caml_option.nullable_to_opt(new URLSearchParams(url.search).get("visit_member_id"))) : "";
  } else {
    visitMemberId = "";
  }
  var match$4 = React.useContext(Context_User$YourProjectName.Methods.context);
  var setUser = match$4.set;
  var match$5 = Curry._2(Api.useSubmit, state, visitMemberId);
  var submit = match$5[0];
  var toSubmit = function (param) {
    return Curry._1(submit, (function (tokenJson) {
                  var token = Json_decode.field("token", Json_decode.string, tokenJson);
                  Curry._1(setUser, {
                        user: {
                          name: state.contact,
                          status: "Unverified"
                        },
                        token: token,
                        first_time: true
                      });
                  return RescriptReactRouter.push("/order/dashboard/session/items/supply");
                }));
  };
  return React.createElement("div", {
              id: "onboarding"
            }, match$5[1], React.createElement(Landing_Template$YourProjectName.make, {
                  children: null
                }, React.createElement(Core.Typography, {
                      align: "center",
                      children: React.createElement(LangString$YourProjectName.make, {
                            strKey: "welcome"
                          }),
                      classes: {
                        root: "boldTitle"
                      },
                      variant: "h5"
                    }), React.createElement(Custom__Stepper$YourProjectName.make, Custom__Stepper$YourProjectName.makeProps(step, undefined, undefined, undefined, undefined)), React.createElement(ReactSwipeableViews, {
                      cancelable: "true",
                      index: Custom__Stepper$YourProjectName.get(step) - 1 | 0,
                      onChangeIndex: (function (v) {
                          return Curry._1(setStep, (function (st) {
                                        return Custom__Stepper$YourProjectName.toNthStep(v + 1 | 0, st);
                                      }));
                        }),
                      axis: "x",
                      disabled: Custom__Stepper$YourProjectName.get(step) < 2,
                      className: "contentForm",
                      children: null
                    }, React.createElement(Onboarding__Page1$YourProjectName.make, {
                          state: state,
                          setState: setState,
                          toNext: (function (param) {
                              return Curry._1(setStep, (function (param) {
                                            return Custom__Stepper$YourProjectName.toNextStep(1, param);
                                          }));
                            })
                        }), React.createElement(Onboarding__Page2$YourProjectName.make, {
                          state: state,
                          setState: setState,
                          toNext: toSubmit
                        }))));
}

var $$URLSearchParams$1;

var Page1;

var Page2;

var initialState = Account_Data$YourProjectName.initBasic;

var make = Onboarding$M;

exports.$$URLSearchParams = $$URLSearchParams$1;
exports.Page1 = Page1;
exports.Page2 = Page2;
exports.initialState = initialState;
exports.inputErrorToString = inputErrorToString;
exports.M = M;
exports.Api = Api;
exports.make = make;
/*  Not a pure module */
