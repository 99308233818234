// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Browser = require("@sentry/browser");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Logger$YourProjectName = require("../../../utils/Logger.bs.js");
var Language$YourProjectName = require("../../../language/Language.bs.js");
var ReactUtil$YourProjectName = require("../../../utils/ReactUtil.bs.js");
var Context_User$YourProjectName = require("../Context_User.bs.js");
var Context_Error$YourProjectName = require("../Context_Error.bs.js");
var ContextProvider$YourProjectName = require("../ContextProvider.bs.js");
var Context_Items_Api$YourProjectName = require("./Context_Items_Api.bs.js");
var Context_DashboardFocus$YourProjectName = require("../Context_DashboardFocus.bs.js");
var ReactNativeCommunicate$YourProjectName = require("../../../reactNative/ReactNativeCommunicate.bs.js");

var sessionStorageKey = "tsaitungOrderStoreIdInSession";

function findByStore(store, _map) {
  while(true) {
    var map = _map;
    if (!map) {
      return ;
    }
    var hd = map.hd;
    if (hd[0].id === store.id) {
      return hd[1];
    }
    _map = map.tl;
    continue ;
  };
}

function getStoreById(sid, _map) {
  while(true) {
    var map = _map;
    if (!map) {
      return ;
    }
    var hd = map.hd;
    if (hd[0].id === sid) {
      return hd[0];
    }
    _map = map.tl;
    continue ;
  };
}

var context = React.createContext([
      /* [] */0,
      (function (param, param$1, param$2) {
          
        }),
      undefined,
      (function (param) {
          
        }),
      false
    ]);

var include = ContextProvider$YourProjectName.M({
      contextPtr: context
    });

var makeProps = include.makeProps;

var make = include.make;

var Provider = {
  makeProps: makeProps,
  make: make
};

var $$Map = {
  findByStore: findByStore,
  getStoreById: getStoreById,
  context: context,
  Provider: Provider
};

function useHandleFetchError(key) {
  var match = React.useContext(Context_Error$YourProjectName.context);
  var setError = match[1];
  var err_store = Language$YourProjectName.useLangString(key);
  return function (err) {
    Browser.captureException(err);
    Logger$YourProjectName.error2("Error " + err_store, err);
    return Promise.resolve(Curry._1(setError, {
                    hd: {
                      NAME: "LangKey",
                      VAL: key
                    },
                    tl: /* [] */0
                  }));
  };
}

function useGetStores(setStores) {
  var handleFetchError = useHandleFetchError("err_store");
  var user = React.useContext(Context_User$YourProjectName.context);
  var setStoresRef = ReactUtil$YourProjectName.asRef(setStores);
  return React.useCallback((function (param) {
                return (
                            user !== undefined ? Context_Items_Api$YourProjectName.getStores(user.token) : Promise.resolve([])
                          ).then(function (ss) {
                              Curry._1(setStoresRef.current, ss);
                              return Promise.resolve(ss);
                            }).catch(function (e) {
                            return Curry._1(handleFetchError, e).then(function (param) {
                                        return Promise.resolve(undefined);
                                      });
                          });
              }), [user]);
}

function update(store, items, map) {
  if (!map) {
    return /* [] */0;
  }
  var tl = map.tl;
  var hd = map.hd;
  if (hd[0].id === store.id) {
    return {
            hd: [
              store,
              items
            ],
            tl: tl
          };
  } else {
    return {
            hd: hd,
            tl: update(store, items, tl)
          };
  }
}

function use(param) {
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setMap = match[1];
  var map = match[0];
  var updateMany = React.useCallback((function (pairs) {
          var match = $$Promise.pending(undefined);
          var resolveFinal = match[1];
          Curry._1(setMap, (function (param) {
                  var _accOpt;
                  var _xss = pairs;
                  var _t = param;
                  while(true) {
                    var accOpt = _accOpt;
                    var t = _t;
                    var xss = _xss;
                    var acc = accOpt !== undefined ? accOpt : [];
                    var match = Belt_Array.get(xss, 0);
                    if (match !== undefined) {
                      var st = match[0];
                      var tl = Belt_Array.sliceToEnd(xss, 1);
                      var oldItems = Belt_Option.getWithDefault(findByStore(st, t), /* [] */0);
                      var items = Curry._1(match[1], oldItems);
                      var acc$1 = Belt_Array.concat(acc, [[
                              st.id,
                              items
                            ]]);
                      _t = update(st, items, t);
                      _xss = tl;
                      _accOpt = acc$1;
                      continue ;
                    }
                    Curry._1(resolveFinal, acc);
                    return t;
                  };
                }));
          return match[0];
        }), []);
  var setStores = function (stores) {
    if (Caml_obj.caml_notequal(Belt_List.toArray(Belt_List.map(map, (function (prim) {
                      return prim[0];
                    }))), stores)) {
      return Curry._1(setMap, (function (param) {
                    var _accOpt;
                    var _stores = stores;
                    while(true) {
                      var accOpt = _accOpt;
                      var stores$1 = _stores;
                      var acc = accOpt !== undefined ? accOpt : /* [] */0;
                      var st = Belt_Array.get(stores$1, 0);
                      if (st === undefined) {
                        return Belt_List.reverse(acc);
                      }
                      var items = findByStore(st, param);
                      var acc$1 = items !== undefined ? ({
                            hd: [
                              st,
                              items
                            ],
                            tl: acc
                          }) : ({
                            hd: [
                              st,
                              /* [] */0
                            ],
                            tl: acc
                          });
                      _stores = Belt_Array.sliceToEnd(stores$1, 1);
                      _accOpt = acc$1;
                      continue ;
                    };
                  }));
    }
    
  };
  return [
          map,
          updateMany,
          setStores
        ];
}

var ItemMap = {
  use: use
};

function getFromStorage(param) {
  return Dom_storage.getItem(sessionStorageKey, sessionStorage);
}

function updateStorage(id) {
  if (id !== undefined) {
    return Dom_storage.setItem(sessionStorageKey, id, sessionStorage);
  } else {
    return Dom_storage.removeItem(sessionStorageKey, sessionStorage);
  }
}

function use$1(storeItemMap, setStores) {
  var match = React.useContext(Context_Error$YourProjectName.context);
  var setError = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setStore_ = match$1[1];
  var store = match$1[0];
  React.useEffect((function () {
          if (store === undefined) {
            var storeId = Dom_storage.getItem(sessionStorageKey, sessionStorage);
            if (storeId !== undefined) {
              if (storeItemMap) {
                var st = storeItemMap.hd;
                var store$1 = Belt_Option.getWithDefault(getStoreById(storeId, {
                          hd: st,
                          tl: storeItemMap.tl
                        }), st[0]);
                updateStorage(store$1.id);
                Curry._1(setStore_, (function (param) {
                        return store$1;
                      }));
              }
              
            } else if (storeItemMap) {
              var st$1 = storeItemMap.hd;
              Curry._1(setStore_, (function (param) {
                      return st$1[0];
                    }));
            }
            
          }
          
        }), [
        store,
        storeItemMap
      ]);
  var getStores = useGetStores(setStores);
  var setStore = React.useCallback((function (store) {
          var setStoreByStore = function (st) {
            Curry._1(setStore_, (function (param) {
                    return st;
                  }));
            return updateStorage(st.id);
          };
          if (typeof store === "number") {
            Curry._1(setError, {
                  hd: {
                    NAME: "Str",
                    VAL: "沒有店家"
                  },
                  tl: /* [] */0
                });
            Curry._1(setStore_, (function (param) {
                    
                  }));
            return updateStorage(undefined);
          } else if (store.TAG === /* ByStore */0) {
            return setStoreByStore(store._0);
          } else {
            var sid = store._0;
            Curry._1(getStores, undefined).then(function (stores) {
                  var store = Belt_Option.flatMap(stores, (function (param) {
                          return Belt_Array.getBy(param, (function (s) {
                                        return s.id === sid;
                                      }));
                        }));
                  return Promise.resolve(store !== undefined ? setStoreByStore(store) : undefined);
                });
            return ;
          }
        }), [
        storeItemMap,
        getStores
      ]);
  return [
          store,
          setStore
        ];
}

var Store_ = {
  getFromStorage: getFromStorage,
  updateStorage: updateStorage,
  use: use$1
};

function use$2(param) {
  var handleFetchError = useHandleFetchError("err_store_items");
  var user = React.useContext(Context_User$YourProjectName.context);
  var match = ReactUtil$YourProjectName.useState(function (param) {
        
      });
  var setToUpdate = match[1];
  var toUpdate = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  React.useEffect((function () {
          if (user !== undefined) {
            var match = url.path;
            if (match && match.hd === "order") {
              var match$1 = match.tl;
              if (match$1 && match$1.hd === "dashboard") {
                var match$2 = match$1.tl;
                if (match$2 && match$2.hd === "session") {
                  var match$3 = match$2.tl;
                  if (match$3) {
                    var match$4 = match$3.tl;
                    if (match$4 && match$4.hd === "items") {
                      var match$5 = match$4.tl;
                      if (match$5 && match$5.hd === "regular" && !match$5.tl) {
                        var partial_arg = user.token;
                        Promise.all(Belt_Array.map(Belt_MapString.toArray(toUpdate), (function (param) {
                                        return Context_Items_Api$YourProjectName.putStoreItem(partial_arg, param);
                                      }))).then(function (param) {
                                return Promise.resolve(Curry._1(setToUpdate, (function (param) {
                                                  
                                                })));
                              }).catch(Curry.__1(handleFetchError));
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          
        }), [
        url,
        toUpdate
      ]);
  return setToUpdate;
}

var ToUpdate = {
  StrMap: undefined,
  use: use$2
};

function useStoreUpdateItems(store, updateStoreItem, setLoading) {
  var user = React.useContext(Context_User$YourProjectName.context);
  var handleFetchError = useHandleFetchError("err_store_items");
  React.useEffect((function () {
          if (store !== undefined && user !== undefined) {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            Context_Items_Api$YourProjectName.getStoreItem(user.token, store.id).then(function (items) {
                    return $$Promise.Js.toBsPromise($$Promise.Js.relax($$Promise.map(Curry._1(updateStoreItem, [[
                                              store,
                                              (function (param) {
                                                  return items;
                                                })
                                            ]]), (function (param) {
                                          Curry._1(setLoading, (function (param) {
                                                  return false;
                                                }));
                                          
                                        }))));
                  }).catch(Curry.__1(handleFetchError));
          }
          
        }), [
        user,
        store
      ]);
  
}

function useFocuser(param) {
  var match = React.useState(function () {
        return false;
      });
  var setFocusTab = match[1];
  React.useEffect((function () {
          var token = RescriptReactRouter.watchUrl(function (url) {
                var match = url.path;
                if (match && match.hd === "order") {
                  var match$1 = match.tl;
                  if (match$1 && match$1.hd === "dashboard") {
                    var match$2 = match$1.tl;
                    if (match$2) {
                      var exit = 0;
                      switch (match$2.hd) {
                        case "records" :
                        case "session" :
                            exit = 2;
                            break;
                        default:
                          
                      }
                      if (exit === 2 && !match$2.tl) {
                        return Curry._1(setFocusTab, (function (param) {
                                      return true;
                                    }));
                      }
                      
                    }
                    
                  }
                  
                }
                return Curry._1(setFocusTab, (function (param) {
                              return false;
                            }));
              });
          return (function (param) {
                    return RescriptReactRouter.unwatchUrl(token);
                  });
        }), []);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  React.useEffect((function () {
          var handler = function (param) {
            var match = url.path;
            if (match && match.hd === "order") {
              var match$1 = match.tl;
              if (match$1 && match$1.hd === "dashboard") {
                var match$2 = match$1.tl;
                if (match$2) {
                  var exit = 0;
                  switch (match$2.hd) {
                    case "records" :
                    case "session" :
                        exit = 2;
                        break;
                    default:
                      
                  }
                  if (exit === 2 && !match$2.tl) {
                    return Curry._1(setFocusTab, (function (param) {
                                  return true;
                                }));
                  }
                  
                }
                
              }
              
            }
            return Curry._1(setFocusTab, (function (param) {
                          return false;
                        }));
          };
          document.addEventListener("focus", handler);
          return (function (param) {
                    document.removeEventListener("focus", handler);
                    
                  });
        }), [url]);
  var match$1 = React.useContext(Context_DashboardFocus$YourProjectName.context);
  var setFocusDashboard = match$1[1];
  var isInReactNative = React.useRef(Belt_Option.isSome(ReactNativeCommunicate$YourProjectName.isInWebview(undefined)));
  var focus = isInReactNative.current ? match[0] : match$1[0];
  var setFocus = React.useCallback((function (b) {
          if (isInReactNative.current) {
            return Curry._1(setFocusTab, (function (param) {
                          return b;
                        }));
          } else {
            return Curry._1(setFocusDashboard, b);
          }
        }), []);
  return [
          focus,
          setFocus
        ];
}

function useStores(setStores) {
  var getStores = useGetStores(setStores);
  var match = useFocuser(undefined);
  var setFocus = match[1];
  var focus = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setInit = match$1[1];
  var init = match$1[0];
  React.useEffect((function () {
          if (init) {
            if (focus) {
              Curry._1(getStores, undefined).then(function (param) {
                    return Promise.resolve(Curry._1(setFocus, false));
                  });
            }
            
          } else {
            Curry._1(setInit, (function (param) {
                    return true;
                  }));
            Curry._1(getStores, undefined);
          }
          
        }), [
        init,
        getStores,
        focus
      ]);
  
}

var Interop = {
  useStoreUpdateItems: useStoreUpdateItems,
  useFocuser: useFocuser,
  useStores: useStores
};

function Context_Items_Map(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setToUpdate = use$2(undefined);
  var match$1 = use(undefined);
  var setStores = match$1[2];
  var updator = match$1[1];
  var storeItemMap = match$1[0];
  var updateStore = React.useCallback((function (updateRemote) {
          return function (store, f) {
            return $$Promise.get(Curry._1(updator, [[
                              store,
                              f
                            ]]), (function ($$final) {
                          var setter = function (tu) {
                            return Belt_Array.reduce($$final, tu, (function (acc, param) {
                                          return Belt_MapString.set(acc, param[0], param[1]);
                                        }));
                          };
                          if (updateRemote) {
                            return Curry._1(setToUpdate, setter);
                          }
                          
                        }));
          };
        }), []);
  var match$2 = use$1(storeItemMap, setStores);
  var store = match$2[0];
  useStoreUpdateItems(store, updator, match[1]);
  useStores(setStores);
  return React.createElement(make, Curry._3(makeProps, [
                  storeItemMap,
                  updateStore,
                  store,
                  match$2[1],
                  match[0]
                ], children, undefined));
}

var Api;

var make$1 = Context_Items_Map;

var StoreItemMap;

exports.Api = Api;
exports.sessionStorageKey = sessionStorageKey;
exports.$$Map = $$Map;
exports.useHandleFetchError = useHandleFetchError;
exports.useGetStores = useGetStores;
exports.ItemMap = ItemMap;
exports.Store_ = Store_;
exports.ToUpdate = ToUpdate;
exports.Interop = Interop;
exports.make = make$1;
exports.StoreItemMap = StoreItemMap;
/* context Not a pure module */
