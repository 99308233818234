// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Core = require("@material-ui/core");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function NotFound(Props) {
  var handleClick = function (param) {
    return RescriptReactRouter.replace("/order/dashboard");
  };
  return React.createElement(React.Fragment, {
              children: React.createElement("div", undefined, React.createElement("h3", undefined, "未知的連結"), React.createElement(Core.Button, {
                        onClick: handleClick,
                        children: "返回主頁面"
                      }))
            });
}

var make = NotFound;

exports.make = make;
/* react Not a pure module */
