// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var Util$YourProjectName = require("./Util.bs.js");

function M(UtilApi) {
  var iterHandler = function (_handlers, res) {
    while(true) {
      var handlers = _handlers;
      if (!handlers) {
        return Util$YourProjectName.ReasonPromise.toReasonPromise(Curry._1(UtilApi.makeResErr, res));
      }
      var result = Curry._1(handlers.hd, res);
      if (result !== undefined) {
        return Caml_option.valFromOption(result);
      }
      _handlers = handlers.tl;
      continue ;
    };
  };
  var ignore404 = function (res) {
    var match = Util$YourProjectName.ReasonPromise.status(res);
    if (match !== 404) {
      return ;
    } else {
      return Caml_option.some(Curry._1(Util$YourProjectName.ReasonPromise.resolved, {
                      TAG: /* Ok */0,
                      _0: undefined
                    }));
    }
  };
  var ignore400 = function (res) {
    var match = Util$YourProjectName.ReasonPromise.status(res);
    if (match !== 400) {
      return ;
    } else {
      return Caml_option.some(Curry._1(Util$YourProjectName.ReasonPromise.resolved, {
                      TAG: /* Ok */0,
                      _0: undefined
                    }));
    }
  };
  var toReasonPromiseMethod = function (p) {
    return Curry._2(Util$YourProjectName.ReasonPromise.flatMapOk, Util$YourProjectName.ReasonPromise.toReasonPromise(p), (function (res) {
                  if (Util$YourProjectName.ReasonPromise.ok(res)) {
                    return Util$YourProjectName.ReasonPromise.json(res);
                  } else {
                    return Util$YourProjectName.ReasonPromise.toReasonPromise(Curry._1(UtilApi.makeResErr, res));
                  }
                }));
  };
  var get = function (url, token, param) {
    return toReasonPromiseMethod(Curry._3(UtilApi.get, url, token, undefined));
  };
  var getWithHandler = function (handlersOpt, url, token, param) {
    var handlers = handlersOpt !== undefined ? handlersOpt : /* [] */0;
    return Curry._2(Util$YourProjectName.ReasonPromise.flatMapOk, Util$YourProjectName.ReasonPromise.toReasonPromise(Curry._3(UtilApi.get, url, token, undefined)), (function (res) {
                  if (Util$YourProjectName.ReasonPromise.ok(res)) {
                    return Curry._2(Util$YourProjectName.ReasonPromise.mapOk, Util$YourProjectName.ReasonPromise.json(res), (function (j) {
                                  return Caml_option.some(j);
                                }));
                  } else {
                    return iterHandler(handlers, res);
                  }
                }));
  };
  var put = function (url, token, body, param) {
    return toReasonPromiseMethod(Curry._4(UtilApi.put, url, token, body, undefined));
  };
  var post = function (url, withToken, body, contentType, param) {
    return toReasonPromiseMethod(Curry._5(UtilApi.post, url, body, withToken, contentType, undefined));
  };
  var postWithHandler = function (handlersOpt, url, withToken, body, contentType, param) {
    var handlers = handlersOpt !== undefined ? handlersOpt : /* [] */0;
    return Curry._2(Util$YourProjectName.ReasonPromise.flatMapOk, Util$YourProjectName.ReasonPromise.toReasonPromise(Curry._5(UtilApi.post, url, body, withToken, contentType, undefined)), (function (res) {
                  if (Util$YourProjectName.ReasonPromise.ok(res)) {
                    return Util$YourProjectName.ReasonPromise.json(res);
                  } else {
                    return iterHandler(handlers, res);
                  }
                }));
  };
  var getWithDecoder = function (url, token, decoder) {
    return $$Promise.flatMapOk(get(url, token, undefined), (function (json) {
                  var tmp;
                  var exit = 0;
                  var r;
                  try {
                    r = Curry._1(decoder, json);
                    exit = 1;
                  }
                  catch (raw_ms){
                    var ms = Caml_js_exceptions.internalToOCamlException(raw_ms);
                    if (ms.RE_EXN_ID === Json_decode.DecodeError) {
                      tmp = {
                        TAG: /* Error */1,
                        _0: {
                          TAG: /* DecodeError */2,
                          _0: ms._1
                        }
                      };
                    } else {
                      throw ms;
                    }
                  }
                  if (exit === 1) {
                    tmp = {
                      TAG: /* Ok */0,
                      _0: r
                    };
                  }
                  return $$Promise.resolved(tmp);
                }));
  };
  return {
          iterHandler: iterHandler,
          ignore404: ignore404,
          ignore400: ignore400,
          toReasonPromiseMethod: toReasonPromiseMethod,
          get: get,
          getWithHandler: getWithHandler,
          put: put,
          post: post,
          postWithHandler: postWithHandler,
          getWithDecoder: getWithDecoder
        };
}

exports.M = M;
/* Promise Not a pure module */
