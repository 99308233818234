// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");

function message(json) {
  var partial_arg_1 = {
    hd: (function (f) {
        return Json_decode.$$float(f).toString();
      }),
    tl: /* [] */0
  };
  var partial_arg = {
    hd: Json_decode.string,
    tl: partial_arg_1
  };
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json),
          title: Json_decode.field("title", Json_decode.string, json),
          content: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("content", Json_decode.string, param);
                    }), json), ""),
          timestamp: Json_decode.field("timestamp", Json_decode.$$float, json),
          link: Caml_option.null_to_opt(Json_decode.field("link", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json))
        };
}

function messages(param) {
  return Json_decode.field("messages", (function (param) {
                return Json_decode.array(message, param);
              }), param);
}

function messageCount(json) {
  var total = Json_decode.field("total", Json_decode.$$int, json);
  var unread = Json_decode.field("unread", Json_decode.$$int, json);
  return [
          total,
          unread
        ];
}

var Decode = {
  message: message,
  messages: messages,
  messageCount: messageCount
};

function read(timestamp) {
  return Json_encode.object_({
              hd: [
                "start_timestamp",
                timestamp
              ],
              tl: /* [] */0
            });
}

var Encode = {
  read: read
};

function timestampToString(prefix, timestamp) {
  var toString = function (f) {
    if (Number.isNaN(f)) {
      return ;
    } else {
      return f.toString();
    }
  };
  return Belt_Option.mapWithDefault(Belt_Option.flatMap(timestamp, toString), "", (function (t) {
                return prefix + t;
              }));
}

function getMessages(token, timestamp, limitOpt, param) {
  var limit = limitOpt !== undefined ? limitOpt : 10;
  var ts = timestampToString("&start_timestamp=", timestamp);
  return Util$YourProjectName.TsaitungApi.get("/auth/push_messages/?limit=" + String(limit) + ts, token, undefined).then(function (res) {
                if (res.ok) {
                  return res.json();
                } else {
                  return Util$YourProjectName.TsaitungApi.makeResErr(res);
                }
              }).then(function (res) {
              return Promise.resolve(messages(res));
            });
}

function getMessageCount(token) {
  return Util$YourProjectName.TsaitungApi.get("/auth/push_messages/count/", token, undefined).then(function (res) {
                if (res.ok) {
                  return res.json();
                } else {
                  return Util$YourProjectName.TsaitungApi.makeResErr(res);
                }
              }).then(function (res) {
              return Promise.resolve(messageCount(res));
            });
}

function postRead(token, timestamp) {
  var ts = timestampToString("?start_timestamp=", timestamp);
  return Util$YourProjectName.TsaitungApi.post("/auth/push_messages/mark_as_read/" + ts, JSON.stringify(read(timestamp)), token, /* JsonType */0, undefined);
}

var Api;

exports.Api = Api;
exports.Decode = Decode;
exports.Encode = Encode;
exports.timestampToString = timestampToString;
exports.getMessages = getMessages;
exports.getMessageCount = getMessageCount;
exports.postRead = postRead;
/* Util-YourProjectName Not a pure module */
