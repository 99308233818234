// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var GeneralType$YourProjectName = require("../../utils/GeneralType.bs.js");

var BasicInfo = {};

function paymentIsCheck(p) {
  if (p) {
    return true;
  } else {
    return false;
  }
}

var _map = {"B2B":"B2B","B2C":"B2C"};

var _revMap = {"B2B":"B2B","B2C":"B2C"};

function invoice_typeToJs(param) {
  return _map[param];
}

function invoice_typeFromJs(param) {
  return _revMap[param];
}

function rev(m) {
  if (m) {
    return /* Hour */0;
  } else {
    return /* Half */1;
  }
}

function minuteToJs(m) {
  if (m) {
    return "30";
  } else {
    return "00";
  }
}

function minutFromJs(str) {
  switch (str) {
    case "00" :
        return /* Hour */0;
    case "30" :
        return /* Half */1;
    default:
      return ;
  }
}

function hourToJs(h) {
  if (typeof h === "number") {
    return "13:00";
  }
  switch (h.TAG | 0) {
    case /* O9 */0 :
        return "09:" + (
                h._0 ? "30" : "00"
              );
    case /* O10 */1 :
        return "10:" + (
                h._0 ? "30" : "00"
              );
    case /* O11 */2 :
        return "11:" + (
                h._0 ? "30" : "00"
              );
    case /* O12 */3 :
        return "12:" + (
                h._0 ? "30" : "00"
              );
    
  }
}

function hourFromJs(str) {
  var hour = str.slice(0, 2);
  var min = str.slice(3, 5);
  switch (hour) {
    case "09" :
        return Rebase.$$Option.map((function (m) {
                      return {
                              TAG: /* O9 */0,
                              _0: m
                            };
                    }), minutFromJs(min));
    case "10" :
        return Rebase.$$Option.map((function (m) {
                      return {
                              TAG: /* O10 */1,
                              _0: m
                            };
                    }), minutFromJs(min));
    case "11" :
        return Rebase.$$Option.map((function (m) {
                      return {
                              TAG: /* O11 */2,
                              _0: m
                            };
                    }), minutFromJs(min));
    case "12" :
        return Rebase.$$Option.map((function (m) {
                      return {
                              TAG: /* O12 */3,
                              _0: m
                            };
                    }), minutFromJs(min));
    case "13" :
        return Rebase.$$Option.map((function (param) {
                      return /* O13 */0;
                    }), minutFromJs(min));
    default:
      return ;
  }
}

var phone = GeneralType$YourProjectName.phone;

var email = GeneralType$YourProjectName.email;

var line_id = GeneralType$YourProjectName.line_id;

var address = GeneralType$YourProjectName.address;

var digitToJs = GeneralType$YourProjectName.digitToJs;

var digitFromJs = GeneralType$YourProjectName.digitFromJs;

exports.phone = phone;
exports.email = email;
exports.line_id = line_id;
exports.address = address;
exports.digitToJs = digitToJs;
exports.digitFromJs = digitFromJs;
exports.BasicInfo = BasicInfo;
exports.paymentIsCheck = paymentIsCheck;
exports.invoice_typeToJs = invoice_typeToJs;
exports.invoice_typeFromJs = invoice_typeFromJs;
exports.rev = rev;
exports.minuteToJs = minuteToJs;
exports.minutFromJs = minutFromJs;
exports.hourToJs = hourToJs;
exports.hourFromJs = hourFromJs;
/* No side effect */
