// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Rebase = require("@glennsl/rebase/src/Rebase.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Core = require("@material-ui/core");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");
var Logger$YourProjectName = require("../../utils/Logger.bs.js");
var Language$YourProjectName = require("../../language/Language.bs.js");
var ApiCaller$YourProjectName = require("../../utils/ApiCaller.bs.js");
var LangString$YourProjectName = require("../../language/LangString.bs.js");
var Custom_Dialog$YourProjectName = require("../../utils/Custom/Custom_Dialog.bs.js");
var Collection_Decode$YourProjectName = require("../../utils/Collection_Decode.bs.js");
var Custom__FormValidateWrapper$YourProjectName = require("../../utils/Custom/Custom__FormValidateWrapper.bs.js");

function Onboarding__Utils$PageContainer(Props) {
  var toNext = Props.toNext;
  var title = Props.title;
  var children = Props.children;
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Core.Typography, {
                  align: "center",
                  children: React.createElement(LangString$YourProjectName.make, {
                        strKey: title
                      }),
                  classes: {
                    root: "boldTitle"
                  },
                  variant: "subtitle1"
                }), React.createElement(Custom__FormValidateWrapper$YourProjectName.make, {
                  toNext: toNext,
                  children: children
                }));
}

var PageContainer = {
  make: Onboarding__Utils$PageContainer
};

function Api(UtilApi) {
  var ApiCaller = ApiCaller$YourProjectName.M(UtilApi);
  var raiseErrorMessage = function (res) {
    return Curry._2(Util$YourProjectName.ReasonPromise.flatMapOk, Util$YourProjectName.ReasonPromise.json(res), (function (jsonObj) {
                  var tmp;
                  var exit = 0;
                  var val;
                  try {
                    val = Collection_Decode$YourProjectName.ResponseFail.responseFail(jsonObj);
                    exit = 1;
                  }
                  catch (raw_str){
                    var str = Caml_js_exceptions.internalToOCamlException(raw_str);
                    if (str.RE_EXN_ID === Json_decode.DecodeError) {
                      tmp = {
                        TAG: /* Error */1,
                        _0: {
                          TAG: /* DecodeError */2,
                          _0: str._1
                        }
                      };
                    } else {
                      throw str;
                    }
                  }
                  if (exit === 1) {
                    tmp = {
                      TAG: /* Error */1,
                      _0: {
                        TAG: /* Message */1,
                        _0: String(res.status) + (": " + val.message)
                      }
                    };
                  }
                  return Curry._1(Util$YourProjectName.ReasonPromise.resolved, tmp);
                }));
  };
  var useSubmit = function (state, visitMemberId) {
    var match = React.useState(function () {
          
        });
    var setFailDialogOpen_ = match[1];
    var failDialogOpen = match[0];
    var match$1 = React.useState(function () {
          return false;
        });
    var setPosting = match$1[1];
    var posting = match$1[0];
    var formData = new FormData();
    formData.append("account", state.account);
    formData.append("password", state.password);
    formData.append("store_name", state.storeName);
    formData.append("contact_name", state.contact);
    formData.append("job_title", state.jobTitle);
    formData.append("visit_member_id", visitMemberId);
    var submit = function (cb) {
      if (posting) {
        return ;
      }
      var hadler400 = function (res) {
        var match = Util$YourProjectName.ReasonPromise.status(res);
        if (match !== 400) {
          return Rebase.$$Option.some(Util$YourProjectName.ReasonPromise.toReasonPromise(Curry._1(UtilApi.makeResErr, res)));
        } else {
          return Rebase.$$Option.some(raiseErrorMessage(res));
        }
      };
      Curry._1(setPosting, (function (param) {
              return true;
            }));
      return Curry._2(Util$YourProjectName.ReasonPromise.getError, Curry._2(Util$YourProjectName.ReasonPromise.tapOk, Curry._6(ApiCaller.postWithHandler, {
                          hd: hadler400,
                          tl: /* [] */0
                        }, "/account/create/temporary/", undefined, formData, /* FormData */1, undefined), cb), (function (error) {
                    Curry._1(setPosting, (function (param) {
                            return false;
                          }));
                    switch (error.TAG | 0) {
                      case /* JsPromiseError */0 :
                          Logger$YourProjectName.error2(Language$YourProjectName.useLangString("err_creation_account"), error._0);
                          var e = Language$YourProjectName.useLangString("err_creation_account_contact_cs");
                          return Curry._1(setFailDialogOpen_, (function (param) {
                                        return e;
                                      }));
                      case /* Message */1 :
                          var m = error._0;
                          Logger$YourProjectName.error(Language$YourProjectName.useLangString("err_create_account") + m);
                          var e$1 = Language$YourProjectName.useLangString("err_create_account") + (": " + m);
                          return Curry._1(setFailDialogOpen_, (function (param) {
                                        return e$1;
                                      }));
                      case /* DecodeError */2 :
                          var str = error._0;
                          Logger$YourProjectName.error(Language$YourProjectName.useLangString("creation_account") + (" decode error" + str));
                          var e$2 = Language$YourProjectName.useLangString("creation_account") + ("decode error: " + str);
                          return Curry._1(setFailDialogOpen_, (function (param) {
                                        return e$2;
                                      }));
                      
                    }
                  }));
    };
    var failDialog = React.createElement(Custom_Dialog$YourProjectName.WarningSimple.make, {
          color: /* Secondary */2,
          onClose: (function (param, param$1) {
              return Curry._1(setFailDialogOpen_, (function (param) {
                            
                          }));
            }),
          open_: Rebase.$$Option.isSome(failDialogOpen),
          title: React.createElement(LangString$YourProjectName.make, {
                strKey: "error"
              }),
          content: (function (param) {
              return Rebase.$$Option.getOr("", failDialogOpen);
            }),
          onExit: (function (param) {
              return Curry._1(setFailDialogOpen_, (function (param) {
                            
                          }));
            }),
          className: "failDialog"
        });
    return [
            submit,
            failDialog
          ];
  };
  return {
          ApiCaller: ApiCaller,
          raiseErrorMessage: raiseErrorMessage,
          useSubmit: useSubmit
        };
}

exports.PageContainer = PageContainer;
exports.Api = Api;
/* react Not a pure module */
