// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Let$YourProjectName = require("../../utils/Let.bs.js");
var Util$YourProjectName = require("../../utils/Util.bs.js");
var Webapi__Dom__HtmlInputElement = require("bs-webapi/src/Webapi/Dom/Webapi__Dom__HtmlInputElement.bs.js");
var Context_Items_Map$YourProjectName = require("../Contexts/Items/Context_Items_Map.bs.js");
var ReactNativeCommunicate$YourProjectName = require("../../reactNative/ReactNativeCommunicate.bs.js");

var getEventValue = (function(e) {
          if(e && e.target && e.target.value) {
            return e.target.value
          }
        });

function dispatchInput(elmRef, value) {
  return Curry._2(Let$YourProjectName.$$Option.let_, elmRef.current, (function (domElm) {
                return Curry._2(Let$YourProjectName.$$Option.let_, Curry._1(Webapi__Dom__HtmlInputElement.ofElement, domElm), (function (htmlInputElm) {
                              htmlInputElm.setAttribute("value", value);
                              htmlInputElm.dispatchEvent(new Event("input", {
                                        bubbles: true
                                      }));
                              return Let$YourProjectName.$$Option.$$return(undefined);
                            }));
              }));
}

function use(param) {
  var match = React.useContext(Context_Items_Map$YourProjectName.$$Map.context);
  var setStore = match[3];
  var store = match[2];
  var storeItemList = match[0];
  var inputRef = React.useRef(undefined);
  React.useEffect(function () {
        return Curry._2(Let$YourProjectName.$$Option.let_, inputRef.current, (function (domElm) {
                      return Curry._2(Let$YourProjectName.$$Option.let_, Curry._1(Webapi__Dom__HtmlInputElement.ofElement, domElm), (function (inputHtmlElm) {
                                    var handler = function (ev) {
                                      var value = getEventValue(ev);
                                      if (Caml_obj.caml_notequal(value, Belt_Option.map(store, (function (s) {
                                                    return s.id;
                                                  })))) {
                                        var storeId = Belt_Option.getWithDefault(value, "");
                                        var found = Belt_Option.map(List.find_opt((function (param) {
                                                    return param[0].id === storeId;
                                                  }), storeItemList), (function (prim) {
                                                return prim[0];
                                              }));
                                        if (found !== undefined) {
                                          ReactNativeCommunicate$YourProjectName.SelectStore.postMessage(found.id);
                                          return Curry._1(setStore, {
                                                      TAG: /* ByStore */0,
                                                      _0: found
                                                    });
                                        } else {
                                          return Curry._1(setStore, {
                                                      TAG: /* ById */1,
                                                      _0: storeId
                                                    });
                                        }
                                      }
                                      
                                    };
                                    inputHtmlElm.addEventListener("input", handler);
                                    return Let$YourProjectName.$$Option.$$return(function (param) {
                                                inputHtmlElm.removeEventListener("input", handler);
                                                
                                              });
                                  }));
                    }));
      });
  var dispatchInput$1 = function (sid) {
    dispatchInput(inputRef, sid);
    
  };
  return [
          dispatchInput$1,
          React.createElement("input", {
                ref: (function (param) {
                    return Util$YourProjectName.Fp.$great$great((function (prim) {
                                  if (prim == null) {
                                    return ;
                                  } else {
                                    return Caml_option.some(prim);
                                  }
                                }), (function (elm) {
                                  inputRef.current = elm;
                                  
                                }), param);
                  }),
                id: "user-select-input",
                type: "hidden"
              })
        ];
}

var HtmlInputElement;

var O;

exports.HtmlInputElement = HtmlInputElement;
exports.O = O;
exports.getEventValue = getEventValue;
exports.dispatchInput = dispatchInput;
exports.use = use;
/* react Not a pure module */
