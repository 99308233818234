// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Router$YourProjectName = require("./Router/Router.bs.js");
var Styles = require("@material-ui/core/styles");
var MaterialUi_ThemeProvider = require("rescript-material-ui/src/MaterialUi_ThemeProvider.bs.js");
var GlobalContexts$YourProjectName = require("./Contexts/GlobalContexts.bs.js");

require('./App.css')
;

function App(Props) {
  return React.createElement("main", undefined, React.createElement(MaterialUi_ThemeProvider.make, {
                  children: React.createElement(GlobalContexts$YourProjectName.make, {
                        children: React.createElement("div", {
                              id: "tsaitung"
                            }, React.createElement(Router$YourProjectName.make, {}))
                      }),
                  theme: Styles.createMuiTheme({
                        overrides: {
                          muiButton: {
                            contained: {
                              border: "2px solid",
                              borderColor: "unset",
                              color: "white",
                              fontWeight: "normal",
                              margin: "0px 8px",
                              boxShadow: "unset"
                            },
                            containedPrimary: {
                              color: "white"
                            },
                            containedSecondary: {
                              color: "white"
                            }
                          },
                          muiCheckbox: {
                            root: {
                              marginRight: "14px",
                              padding: "0px"
                            }
                          },
                          muiTypography: {
                            subtitle1: {
                              fontSize: "1.1rem"
                            }
                          }
                        },
                        palette: {
                          error: {
                            main: "#F95F62"
                          },
                          primary: {
                            light: "rgba(136, 176, 75, 0.08)",
                            main: "#81b31e"
                          },
                          secondary: {
                            dark: "#CC6707",
                            main: "#F68922"
                          },
                          text: {
                            secondary: "white"
                          }
                        },
                        shape: {
                          borderRadius: 16.0
                        }
                      })
                }));
}

var make = App;

exports.make = make;
/*  Not a pure module */
