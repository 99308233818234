// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function on(prim0, prim1, prim2) {
  return prim0.addListener(prim1, prim2);
}

function on2(prim0, prim1, prim2) {
  return prim0.addListener(prim1, prim2);
}

function on3(prim0, prim1, prim2) {
  return prim0.addListener(prim1, prim2);
}

function removeListener(prim0, prim1, prim2) {
  return prim0.off(prim1, prim2);
}

function removeListener2(prim0, prim1, prim2) {
  return prim0.off(prim1, prim2);
}

function removeListener3(prim0, prim1, prim2) {
  return prim0.off(prim1, prim2);
}

exports.on = on;
exports.on2 = on2;
exports.on3 = on3;
exports.removeListener = removeListener;
exports.removeListener2 = removeListener2;
exports.removeListener3 = removeListener3;
/* No side effect */
