// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ContextProvider$YourProjectName = require("./ContextProvider.bs.js");

var _map = {"Unverified":"unverified","Verified":"verified","Pending":"pending"};

var _revMap = {"unverified":"Unverified","verified":"Verified","pending":"Pending"};

function statusToJs(param) {
  return _map[param];
}

function statusFromJs(param) {
  return _revMap[param];
}

var context = React.createContext(undefined);

var Provider = ContextProvider$YourProjectName.M({
      contextPtr: context
    });

var context$1 = React.createContext({
      set: (function (param) {
          throw {
                RE_EXN_ID: ContextProvider$YourProjectName.UninitContext,
                Error: new Error()
              };
        }),
      clear: (function (param) {
          throw {
                RE_EXN_ID: ContextProvider$YourProjectName.UninitContext,
                Error: new Error()
              };
        }),
      refreshStatus: (function (param) {
          throw {
                RE_EXN_ID: ContextProvider$YourProjectName.UninitContext,
                Error: new Error()
              };
        })
    });

var Provider$1 = ContextProvider$YourProjectName.M({
      contextPtr: context$1
    });

var Methods = {
  context: context$1,
  Provider: Provider$1
};

exports.statusToJs = statusToJs;
exports.statusFromJs = statusFromJs;
exports.context = context;
exports.Provider = Provider;
exports.Methods = Methods;
/* context Not a pure module */
