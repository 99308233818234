// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function fromPerson(x) {
  return x;
}

function fromSkill(x) {
  return x;
}

function fromSite(x) {
  return x;
}

function fromTask(x) {
  return x;
}

function fromItem(x) {
  return x;
}

function fromItemInc(x) {
  return x;
}

function fromOrder(x) {
  return x;
}

function fromCategory(x) {
  return x;
}

function fromEntry(x) {
  return x;
}

function fromSerial(x) {
  return x;
}

function fromDate(x) {
  return x;
}

function fromGrade(x) {
  return x._0;
}

function fromUnit(x) {
  return x;
}

function fromRole(x) {
  return x;
}

exports.fromPerson = fromPerson;
exports.fromSkill = fromSkill;
exports.fromSite = fromSite;
exports.fromTask = fromTask;
exports.fromItem = fromItem;
exports.fromItemInc = fromItemInc;
exports.fromOrder = fromOrder;
exports.fromCategory = fromCategory;
exports.fromEntry = fromEntry;
exports.fromSerial = fromSerial;
exports.fromDate = fromDate;
exports.fromGrade = fromGrade;
exports.fromUnit = fromUnit;
exports.fromRole = fromRole;
/* No side effect */
