// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Context_User$YourProjectName = require("../Contexts/Context_User.bs.js");

function Logout(Props) {
  var user = React.useContext(Context_User$YourProjectName.context);
  var match = React.useContext(Context_User$YourProjectName.Methods.context);
  var clearUser = match.clear;
  React.useEffect((function () {
          if (user !== undefined) {
            
          } else {
            RescriptReactRouter.replace("/order/login");
          }
          
        }), [user]);
  React.useEffect((function () {
          Curry._1(clearUser, undefined);
          
        }), []);
  return null;
}

var make = Logout;

exports.make = make;
/* react Not a pure module */
