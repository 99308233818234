// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var UninitContext = /* @__PURE__ */Caml_exceptions.create("ContextProvider-YourProjectName.UninitContext");

function M(Context) {
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = Context.contextPtr.Provider;
  return {
          makeProps: makeProps,
          make: make
        };
}

exports.UninitContext = UninitContext;
exports.M = M;
/* No side effect */
